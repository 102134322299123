import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import moment from "moment";

const TimeButtons = ({ onClick }) => {
	const { t } = useTranslation();
	return (
		<div className="timebuttons">
			<div className="timebuttons_line">
			<Button
				inverted
				content={t("report.timespans.week")}
				onClick={e =>
					onClick(
						moment()
							.startOf("week")
							.valueOf(),
						moment()
							.startOf("day")
							.valueOf()
					)
				}
				labelPosition="left"
				icon="calendar alternate outline"
			/>
			
			<Button
				inverted
				content={t("report.timespans.month")}
				onClick={e =>
					onClick(
						moment()
							.startOf("month")
							.valueOf(),
						moment()
							.startOf("day")
							.valueOf()
					)
				}
				labelPosition="left"
				icon="calendar alternate outline"
			/>
			<Button
				inverted
				content={t("report.timespans.quarter")}
				onClick={e =>
					onClick(
						moment()
							.startOf("quarter")
							.valueOf(),
						moment()
							.startOf("day")
							.valueOf()
					)
				}
				labelPosition="left"
				icon="calendar alternate outline"
			/>
			<Button
				inverted
				content={t("report.timespans.year")}
				onClick={e =>
					onClick(
						moment()
							.startOf("year")
							.valueOf(),
						moment()
							.startOf("day")
							.valueOf()
					)
				}
				labelPosition="left"
				icon="calendar alternate outline"
			/>
			</div><div className="timebuttons_line">
			<Button
				inverted
				content={t("report.timespans.lastWeek")}
				onClick={e =>
					onClick(
						moment()
							.subtract(1,"week")
							.startOf("week")
							.valueOf(),
						moment()
							.subtract(1,"week")
							.endOf("week")
							.valueOf()
					)
				}
				labelPosition="left"
				icon="calendar alternate outline"
			/>
			<Button
				inverted
				content={t("report.timespans.lastMonth")}
				onClick={e =>
					onClick(
						moment()
							.subtract(1,"month")
							.startOf("month")
							.valueOf(),
						moment()
							.subtract(1,"month")
							.endOf("month")
							.valueOf()
					)
				}
				labelPosition="left"
				icon="calendar alternate outline"
			/>

			<Button
				inverted
				content={t("report.timespans.lastQuarter")}
				onClick={e =>
					onClick(
						moment()
							.subtract(1,"quarter")
							.startOf("quarter")
							.valueOf(),
						moment()
							.subtract(1,"quarter")
							.endOf("quarter")
							.valueOf()
					)
				}
				labelPosition="left"
				icon="calendar alternate outline"
			/>
			
			<Button
				inverted
				content={t("report.timespans.lastYear")}
				onClick={e =>
					onClick(
						moment()
							.subtract(1,"year")
							.startOf("year")
							.valueOf(),
						moment()
							.subtract(1,"year")
							.endOf("year")
							.valueOf()
					)
				}
				labelPosition="left"
				icon="calendar alternate outline"
			/>
			</div>
		</div>
	);
};

export default TimeButtons;
