import React from "react";
import {
  Tab,
  Menu,
  Label,
  Icon,
  Form,
  Button,
  Message,
  Select,
  Feed,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import ConfirmButton from "../calendar/controls/confirmButton";

class Logbuch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      textField: "",
      loading: false,
      notSavedYet: false,
      saveMessage: false,
      type: "comment",
      dragVisible: false
    };
    this.fileInputRef = React.createRef();
    this.fileChange = this.fileChange.bind(this);
    this.changeTextField = this.changeTextField.bind(this);
    this.changeSelect = this.changeSelect.bind(this);
    this.send = this.send.bind(this);
    this.allowDrag = this.allowDrag.bind(this);
    this.drop = this.drop.bind(this);
  }

  serveAttachment(message) {
    axios({
      url: process.env.REACT_APP_SERVER_URL + "/loadFile?v="+process.env.REACT_APP_SERVER_VERSION,
      method: "POST",
      data: {
        messageId: message.id,
      },
      responseType: "blob", // important
      headers: {
        Authorization: "Bearer " + localStorage.getItem("binfra_token"),
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", message.attachment);
      document.body.appendChild(link);
      link.click();
    });
  }

  allowDrag(e) {
    e.dataTransfer.dropEffect = "copy";
    e.preventDefault();
  }

  drop(e) {
    e.preventDefault();
    this.fileInputRef.current.files = e.dataTransfer.files;
    const fname = this.fileInputRef.current.value;

    this.setState({
      dragVisible: false,
      selectedFile: fname ? fname.replace(/.*[/\\]/, "") : null,
      saveMessage: false,
    });
  }

  delete(m) {
    m.delete();
    this.setState({ forceRerender: new Date() });
  }

  fileChange(e) {
    const fname = e.target.value;
    this.setState({
      selectedFile: fname ? fname.replace(/.*[/\\]/, "") : null,
      saveMessage: false,
    });
  }

  changeTextField(e, { value }) {
    this.setState({ textField: value, saveMessage: false });
  }

  changeSelect(e, { value }) {
    this.setState({ type: value, saveMessage: false });
  }

  send() {
    this.setState({ loading: true, saveMessage: false, notSavedYet: false });
    const formData = new FormData();
    const imagefile = this.fileInputRef.current;
    formData.append("resource",this.props.resource.id);
    formData.append("type",this.state.type);
    formData.append("text",this.state.textField);
    formData.append(
      "image",
      imagefile.files.length ? imagefile.files[0] : false
    );
    axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/saveFeed?v="+process.env.REACT_APP_SERVER_VERSION,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("binfra_token"),
          },
        }
      )
      .then((response) => {
        this.setState({
          loading: false,
          notSavedYet: false,
          selectedFile: null,
          textField: "",
          saveMessage: true,
        });
        this.props.save(response.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false, notSavedYet: true, saveMessage: true });
      })
      .finally((x) => {
        setTimeout((x) => this.setState({ saveMessage: false }), 5000);
      });
  }

  render() {
    //console.log(this.state);

    const messages = this.props.resource.messages;

    const attachments = messages.filter(
      (x) => x.hasAttachment && x.type === "attachment"
    );

    const panes = [
      {
        menuItem: { key: "log", icon: "comment", content: "Logbuch" },
        render: () => (
          <Tab.Pane as={Feed} size="large" className="messages">
            {messages.map((m) => {
              let type = {
                comment: {
                  icon: "comment",
                  text: "comment",
                },
                attachment: {
                  icon: "attach",
                  text: "attachment",
                },
                service: {
                  icon: "wrench",
                  text: "service",
                },
              }[m.type];
              if (!type)
                type = {
                  icon: "user",
                  text: "unknown",
                };
              return (
                <Feed.Event key={m.id}>
                  <Feed.Label icon={type.icon} />
                  <Feed.Content>
                    <Feed.Summary>
                      <Feed.User>{m.creator}</Feed.User>{" "}
                      {this.props.t(
                        "report.log.actions." + type.text + ".text"
                      )}
                      <Feed.Date>{moment(m.creationTime).calendar()}</Feed.Date>
                    </Feed.Summary>
                    <Feed.Extra text>{m.message}</Feed.Extra>
                    <Feed.Meta>
                      {m.hasAttachment ? (
                        <Feed.Like onClick={(x) => this.serveAttachment(m)}>
                          <Icon name="attach" />{" "}
                          {this.props.t("report.log.openAttachment")}
                        </Feed.Like>
                      ) : null}
                      <ConfirmButton
                        onClick={(e) => this.delete(m)}
                        trigger={
                          <Feed.Like>
                            <Icon name="trash alternate" />{" "}
                            {this.props.t("report.log.deleteAttachment")}
                          </Feed.Like>
                        }
                      />
                    </Feed.Meta>
                  </Feed.Content>
                </Feed.Event>
              );
            })}
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="attach">
            <Icon name="attach" />
            {this.props.t("report.log.attachments")}
            <Label>{attachments.length}</Label>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane as="div" className="attachments">
            {attachments.map((m) => (
              <Button
                key={m.id}
                content={m.message}
                labelPosition="left"
                icon="file"
                onClick={(x) => this.serveAttachment(m)}
              />
            ))}
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="add" position="right">
            <Button
              content={this.props.t("report.log.add")}
              labelPosition="left"
              icon="plus"
            />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane as={Form} loading={this.state.loading} onDragEnter={() => this.setState({ dragVisible: true })}>
            <Message
              visible={this.state.saveMessage}
              icon={this.state.notSavedYet ? "warning sign" : "checkmark"}
              error={this.state.notSavedYet}
              success={!this.state.notSavedYet}
              header={
                this.state.notSavedYet
                  ? this.props.t("report.log.message.warn.header")
                  : this.props.t("report.log.message.ok.header")
              }
              content={
                this.state.notSavedYet
                  ? this.props.t("report.log.message.warn.text")
                  : this.props.t("report.log.message.ok.text")
              }
            />
            <Form.Field
              control={Select}
              label={this.props.t("report.types.title")}
              value={this.state.type}
              onChange={this.changeSelect}
              options={[
                {
                  key: "comment",
                  text: this.props.t("report.log.actions.comment.name"),
                  value: "comment",
                  icon: "comment",
                },
                {
                  key: "service",
                  text: this.props.t("report.log.actions.service.name"),
                  value: "service",
                  icon: "wrench",
                },
                {
                  key: "attachment",
                  text: this.props.t("report.log.actions.attachment.name"),
                  value: "attachment",
                  icon: "attach",
                },
              ]}
              placeholder={this.props.t("choose")}
            />
            <Form.TextArea
              label={this.props.t("report.log.textarea.title")}
              placeholder={this.props.t("report.log.textarea.placeholder")}
              value={this.state.textField}
              onChange={this.changeTextField}
            />
            <div className="flexgroup">
              <Button
                content={
                  this.state.selectedFile
                    ? this.state.selectedFile
                    : this.props.t("report.log.fileChoose")
                }
                positive={!!this.state.selectedFile}
                labelPosition="left"
                icon="attach"
                onClick={() => this.fileInputRef.current.click()}
              />
              <input
                ref={this.fileInputRef}
                type="file"
                hidden
                onChange={this.fileChange}
              />
              <Button
                content={this.props.t("report.log.send")}
                labelPosition="left"
                icon="send"
                primary
                onClick={this.send}
              />
            </div>
            <div
          style={{ display: this.state.dragVisible ? "flex" : "none" }}
          className="dropZone"
          onDragEnter={this.allowDrag}
          onDragOver={this.allowDrag}
          onDragLeave={() => this.setState({ dragVisible: false })}
          onDrop={this.drop}
        ><Icon name="attach" /></div>
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Tab
        className="logbuch"
        defaultActiveIndex={messages.length ? 0 : 2}
        menu={{ secondary: true, pointing: true, inverted: true }}
        panes={panes}
      />
    );
  }
}

export default withTranslation()(Logbuch);
