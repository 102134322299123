import React from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import {
	Button,
	Menu,
	Modal,
	Icon,
	Dropdown,
	Form,
} from "semantic-ui-react";
import moment from "moment";
import { SingleDatePicker } from "react-dates";

class CajotExport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			kol: "WEEK",
			start: moment().startOf("day"),
		};
	}



	open() {
		this.setState({ open: true, start: moment(this.props.currentDate).startOf("day") });
	}

	close() {
		this.setState({ open: false, filter: "" });
	}

	render() {
		return (
			<Modal
				centered={false}
				className="duravermeerModal"
				trigger={
					<Button icon="file excel outline" onClick={(e) => this.open()} />
				}
				open={this.state.open}
				onClose={(e) => this.close()}
			>
				<React.Fragment>
					<Menu>
						<Menu.Item header>{this.props.t("exports.heading")}</Menu.Item>

						<Menu.Menu position="right">
							<Menu.Item name="close it" onClick={(e) => this.close()}>
								<Icon name="close" />
							</Menu.Item>
						</Menu.Menu>
					</Menu>
					<Modal.Content>
						<Form as="div">
							<Form.Field>
								<label>{this.props.t("exports.start")}</label>
								<SingleDatePicker
									date={this.state.start} // momentPropTypes.momentObj or null,
									id="xdpicker_start" // PropTypes.string.isRequired,
									numberOfMonths={3}
									isOutsideRange={(x) => false}
									onDateChange={(date) =>
										this.setState({
											start: date,
										})
									} // PropTypes.func.isRequired,
									focused={this.state.focused} // PropTypes.bool
									onFocusChange={({ focused }) => this.setState({ focused })}
									hideKeyboardShortcutsPanel
								/>
							</Form.Field>
							<Form.Field><label>Type</label>
							<Dropdown selection placeholder="Alles" options={[{
								key: "week",
								value: "WEEK",
								text: "Semaine"
							},{
								key: "day",
								value: "DAY",
								text: "Jour"
							}]} value={this.state.kol} onChange={(a,{value}) => this.setState({kol: value})} />
							</Form.Field>
						</Form>
						<form
							className="hiddenForm"
							method="post"
							action={""+process.env.REACT_APP_BPO_URL+"/ressourcen/CajotPlan.php"}
							target="_blank"
						>
							<input
								type="hidden"
								name="start"
								value={this.state.start.format("YYYY-MM-DD")}
							/>
							<input type="hidden" name="type" value={this.state.kol} />
						
							<Button positive fluid>
								{this.props.t("exports.get")}
							</Button>
						</form>
					</Modal.Content>
				</React.Fragment>
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(CajotExport)));
