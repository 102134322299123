import React from "react";
import { Form, Loader, Dropdown } from "semantic-ui-react";
import axios from "axios";
import { withTranslation } from "react-i18next";

class MetacomSiteSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: false,
			data: [],
			currentGroup: null,
		};
		this.changeCurrentGroup = this.changeCurrentGroup.bind(this);
		this.select = this.select.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		this.setState({ loading: true, error: false });
		try {
			let request = await axios.post(
				""+process.env.REACT_APP_BPO_URL+"/common/interfaces/InfraMetacom.php",
				{ username: localStorage.getItem("binfra_username") },
				{
					headers: {
						BPOAuth: "2d0a7a8f-106a-4ff6-91a6-c9760a7af2cc",
					},
				}
			);
			let cG = null;
			if (request.data.length === 1) cG = request.data[0].group;
			this.setState({
				loading: false,
				error: false,
				currentGroup: cG,
				data: request.data,
			});
		} catch (e) {
			console.log(e);
			this.setState({
				loading: false,
				error: true,
				data: [],
			});
		}
	}

	changeCurrentGroup(e, { value }) {
		this.setState({ currentGroup: value });
	}

	select(e, { value }) {
		this.props.onSelect(JSON.parse(value));
		this.setState({ currentGroup: null });
	}

	render() {
		let xOpt = [];
		if (this.state.data.length > 0 && this.state.currentGroup) {
			for (let { group, entries } of this.state.data) {
				if (group !== this.state.currentGroup) continue;
				xOpt = entries.map((x) => {
					if ("id" in x) {
						return {
							key: x.id + x.name,
							value: JSON.stringify(x),
							text: x.id + (x.id.length ? ": " : "") + x.name,
						};
					} else if ("bstn" in x) {
						return {
							key: x.bstn + x.name,
							value: JSON.stringify(x),
							text: x.bstn + (x.bstn.length ? ": " : "") + x.name,
						};
					}
					return null;
				});
				break;
			}
			xOpt.sort((a, b) => a.text.localeCompare(b.text));
		}

		return (
			<div className="metacomSelector">
				{this.state.loading ? (
					<Loader active inline="centered" />
				) : this.state.error ? (
					<div className="metacomError">
						{this.props.t("edit.fields.metacom.connectionError")}{" "}
						<span onClick={() => this.load()} className="inlineLink">
							{this.props.t("loader.tryAgain")}.
						</span>
					</div>
				) : !this.state.data.length ? null : (
					<div className="metacomWrapper">
						<div className="metacomHeader">
							{this.props.t("edit.fields.metacom.header")}
						</div>
						<Form.Group widths="equal">
							{this.state.data.length > 1 ? (
								<Form.Field>
									<label>{this.props.t("edit.fields.metacom.area")}</label>
									<Dropdown
										fluid
										lazyLoad={true}
										placeholder={this.props.t("choose.initial")}
										search
										value={this.state.currentGroup}
										onChange={this.changeCurrentGroup}
										selection
										options={this.state.data.map((x) => {
											return { key: x.group, value: x.group, text: x.group };
										})}
									/>
								</Form.Field>
							) : null}
							<Form.Field>
								<label>{this.props.t("edit.fields.metacom.site")}</label>
								<Dropdown
									fluid
									lazyLoad={true}
									placeholder={
										!this.state.currentGroup
											? this.props.t("edit.fields.metacom.chooseArea")
											: this.props.t("choose.initial")
									}
									search
									value={null}
									onChange={this.select}
									selection
									options={xOpt}
								/>
							</Form.Field>
						</Form.Group>
					</div>
				)}
			</div>
		);
	}
}

export default withTranslation()(MetacomSiteSelector);
