import React from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import BaseDataSelect from ".";
import uuid from "uuid";
import {
	Button,
	Menu,
	Modal,
	Input,
	Icon,
	Form,
	Pagination,
	Radio,
	Message,
	Accordion,
	Segment,
} from "semantic-ui-react";
import { arrayFilter } from "../../../helpers/calendar.js";

class MaterialChooser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			activeIndex: 0,
			incompatible: false,
			supplierFilter: "",
			supplierActivePage: 1,
			agfMessage: false,
			allowMultiple: false,
			materialFilter: "",
			materialActivePage: 1,
			material: props.material,
			supplier: props.supplier,
			multiMaterial: [],
			showAutoAdded: !props.app.ui.modules.has("AGF"),
			materialEditor: false,
			materialLabel: props.t("materialChooser.noMaterial"),
			supplierLabel: props.t("materialChooser.noSupplier"),
			meName: "",
			meNumber: "",
			meSupplier: null,
		};
		this.handleClick = this.handleClick.bind(this);
		this.save = this.save.bind(this);
		this.close = this.close.bind(this);
		this.saveMaterialEditor = this.saveMaterialEditor.bind(this);
		this.closeMaterialEditor = this.closeMaterialEditor.bind(this);
	}

	handleClick(e, titleProps) {
		this.setState((state) => {
			const { index } = titleProps;
			const newIndex = state.activeIndex === index ? -1 : index;
			return {
				activeIndex: newIndex,
				supplierActivePage: 1,
				materialActivePage: 1,
				supplierFilter: "",
				materialFilter: "",
			};
		});
	}

	open(allowMultiple = false) {
		if (!this.props.disabled) {
			this.setState((state) => {
				const activeIndex = this.props.supplier ? 1 : 0;
				return {
					open: true,
					allowMultiple,
					supplier: this.props.supplier,
					material: this.props.material,
					materialEditor: false,
					activeIndex,
				};
			});
		}
	}

	save() {
		this.props.onChange(
			this.state.supplier,
			this.state.allowMultiple ? this.state.multiMaterial : this.state.material
		);
		this.setState({
			open: false,
			filter: "",
			allowMultiple: false,
			multiMaterial: [],
			material: null,
			supplier: null,
		});
	}

	close() {
		this.setState({
			open: false,
			filter: "",
			allowMultiple: false,
			multiMaterial: [],
			material: null,
			supplier: null,
		});
	}

	saveMaterialEditor() {
		const id =
			this.state.materialEditor === true
				? uuid.v4()
				: this.state.materialEditor;
		console.log(this.state.meSupplier);
		this.props.app.basedata.updateMaterials([
			{
				id: id,
				_supplier: this.state.meSupplier,
				name: this.state.meName,
				shortname: "",
				number: this.state.meNumber,
				deleted: false,
				autoAdded: true,
				unit: "t",
				updatedAt: new Date(),
			},
		]);
		this.setState({
			materialEditor: false,
			meName: "",
			material: id,
			materialFilter: this.state.meName,
			showAutoAdded: true,
			meNumber: "",
			mePlant: "",
		});
	}

	closeMaterialEditor() {
		this.setState({
			materialEditor: false,
			meName: "",
			meNumber: "",
			mePlant: "",
		});
	}

	componentDidMount() {
		this.getLabels();

		if (this.props.forceOpen) {
			this.open(true);
			this.props.resetForceOpen();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.open && this.state.open) {
			return this.prepare();
		}
		if (
			prevProps.material !== this.props.material ||
			prevProps.supplier !== this.props.supplier
		) {
			return this.getLabels();
		}
		if (!prevState.materialEditor && this.state.materialEditor) {
			return this.setMaterialEditor();
		}
	}

	setMaterialEditor() {
		if (
			this.state.materialEditor === true ||
			!this.props.app.basedata.materials.has(this.state.materialEditor)
		) {
			this.setState({
				meName: "",
				meNumber: "",
				meSupplier: null,
				materialEditor: true,
			});
		} else {
			const mat = this.props.app.basedata.materials.get(
				this.state.materialEditor
			);
			console.log(mat.supplier.id === false ? null : mat.supplier.id);
			this.setState({
				meName: mat.name,
				meNumber: mat.number,
				meSupplier: mat.supplier.id === false ? null : mat.supplier.id,
			});
		}
	}

	getLabelsFromStore(material, supplier, fallbackMaterial, fallbackSupplier) {
		let materialLabel = fallbackMaterial;
		let supplierLabel = fallbackSupplier;
		let supplierId = false;
		let incompatible = false;
		if (supplier && this.props.app.basedata.suppliers.has(supplier)) {
			let sup = this.props.app.basedata.suppliers.get(supplier);
			supplierId = sup.id;
			supplierLabel = sup.name;
		}
		if (Array.isArray(material) && material.length > 0) {
			let matnames = [];
			for (let matx of material){
				if (!this.props.app.basedata.materials.has(matx)) continue;
				const mat = this.props.app.basedata.materials.get(matx);
				incompatible = incompatible || (mat.supplier.id && supplierId !== mat.supplier.id);
				materialLabel = mat.name;
				if (mat.number !== "") materialLabel += " (" + mat.number + ")";
				matnames.push(materialLabel);
			}
			materialLabel = matnames.join(", ");
		} else if (material && this.props.app.basedata.materials.has(material)) {
			const mat = this.props.app.basedata.materials.get(material);
			incompatible = mat.supplier.id && supplierId !== mat.supplier.id;
			materialLabel = mat.name;
			if (mat.number !== "") materialLabel += " (" + mat.number + ")";
		}

		return { materialLabel, supplierLabel, incompatible };
	}

	getLabels() {
		this.setState(
			this.getLabelsFromStore(
				this.props.material,
				this.props.supplier,
				this.props.t("materialChooser.noMaterial"),
				this.props.t("materialChooser.noSupplier")
			)
		);
	}

	prepare() {}

	render() {
		//console.log(this.state, this.props);
		const hasMaterialEditor =
			this.props.app.ui.modules.has("MATERIAL_CREATOR") &&
			this.props.app.ui.rights.has("CREATE_MATERIALS");
		let {
			materialLabel: materialName,
			supplierLabel: supplierName,
			incompatible,
		} = this.getLabelsFromStore(
			this.state.allowMultiple ? this.state.multiMaterial : this.state.material,
			this.state.supplier,
			this.props.t("materialChooser.unknown"),
			this.props.t("materialChooser.unknown")
		);

		const supplierFilter = arrayFilter(this.state.supplierFilter);
		const materialFilter = arrayFilter(this.state.materialFilter);

		const pageItems = 10;
		let supplierMax = 1;
		let suppliers = [];
		let materialMax = 1;
		let materials = [];
		if (this.state.activeIndex === 0) {
			const collection = [];
			for (let supplier of this.props.app.basedata.suppliers.values()) {
				if (supplier.deleted || !supplierFilter([supplier.name])) continue;
				collection.push({ name: supplier.name, id: supplier.id });
			}
			collection.sort((a, b) => {
				const upA = this.props.sortUp.has(a.id);
				const upB = this.props.sortUp.has(b.id);
				if (upA === upB) return a.name.localeCompare(b.name);
				return upA && !upB ? -1 : 1;
			});
			suppliers = collection.slice(
				(this.state.supplierActivePage - 1) * pageItems,
				this.state.supplierActivePage * pageItems
			);
			supplierMax = Math.ceil(collection.length / pageItems);
		} else {
			const collection = [];
			for (let material of this.props.app.basedata.materials.values()) {
				if (
					material.deleted ||
					(material.autoAdded && !this.state.showAutoAdded) ||
					(material.supplier.id &&
						this.state.supplier !== material.supplier.id &&
						(this.state.allowMultiple
							? !this.state.multiMaterial.includes(material.id)
							: this.state.material !== material.id)) ||
					!materialFilter([material.name, material.number])
				)
					continue;
				let materialLabel = material.name;
				if (material.number !== "")
					materialLabel += " (" + material.number + ")";
				collection.push({ name: materialLabel, id: material.id });
			}
			collection.sort((a, b) => a.name.localeCompare(b.name));
			materials = collection.slice(
				(this.state.materialActivePage - 1) * pageItems,
				this.state.materialActivePage * pageItems
			);
			materialMax = Math.ceil(collection.length / pageItems);
		}

		return (
			<Modal
				centered={false}
				className="materialChooserModal"
				trigger={
					<Button
						content={
							<React.Fragment>
								<div className={ "matChooserName " +
									(this.state.materialLabel.length > 40 ? " materialLabelDots" : "")
								}>
									
									{this.state.materialLabel.length > 40 ? 
									(
										<div className="materialLabelContainer">
											<div className="materialLabelInner">
												{this.state.materialLabel} 
											</div>
										</div>
									) : 
									(
										this.state.materialLabel
									)}									
								</div>
								{this.state.incompatible ? (
									<div className="incompatible">
										<Icon name="warning sign" />
									</div>
								) : null}
								{this.props.disabled ? null : (
									<div className="iconcont">
										<Icon name="pencil" />
									</div>
								)}
							</React.Fragment>
						}
						className="specialMatButton"
						onClick={(e) => this.open()}
						label={{
							as: "a",
							//basic: true,
							className: "matChooserLabel",
							pointing: "right",
							content: this.state.supplierLabel,
						}}
						labelPosition="left"
					/>
				}
				open={this.state.open}
				onClose={(e) => this.close()}
			>
				{this.state.materialEditor !== false ? (
					<React.Fragment>
						<Menu>
							<Menu.Item header>
								{this.props.t("materialChooser.editorHeading")}
							</Menu.Item>

							<Menu.Menu position="right">
								<Menu.Item name="close it" onClick={this.closeMaterialEditor}>
									<Icon name="close" /> {this.props.t("cancel")}
								</Menu.Item>

								<Menu.Item name="save it" onClick={this.saveMaterialEditor}>
									<Icon name="check" /> {this.props.t("ok")}
								</Menu.Item>
							</Menu.Menu>
						</Menu>
						<Modal.Content>
							<Form>
								<Form.Group widths="equal">
									<Form.Field>
										<label>
											{this.props.t("materialChooser.editorName.label")}
										</label>
										<Input
											placeholder={this.props.t(
												"materialChooser.editorName.placeholder"
											)}
											type="text"
											value={this.state.meName}
											fluid
											onChange={(e) =>
												this.setState({
													meName: e.target.value,
												})
											}
										/>
									</Form.Field>
									<Form.Field>
										<label>
											{this.props.t("materialChooser.editorNumber.label")}
										</label>
										<Input
											placeholder={this.props.t(
												"materialChooser.editorNumber.placeholder"
											)}
											type="text"
											value={this.state.meNumber}
											fluid
											onChange={(e) =>
												this.setState({
													meNumber: e.target.value,
												})
											}
										/>
									</Form.Field>
								</Form.Group>
								<Form.Field>
									<label>
										{this.props.t("materialChooser.editorSupplier.label")}
									</label>
									<BaseDataSelect
										allowNull
										placeholder={this.props.t(
											"materialChooser.editorSupplier.placeholder"
										)}
										value={this.state.meSupplier}
										onChange={(e, { value }) =>
											this.setState({
												meSupplier: value,
											})
										}
										type="suppliers"
									/>
								</Form.Field>
							</Form>
						</Modal.Content>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Menu>
							<Menu.Item header>
								{this.props.t("materialChooser.heading")}
							</Menu.Item>
							<Menu.Item
								name="showAutoAdded"
								onClick={(e) =>
									this.setState({ showAutoAdded: !this.state.showAutoAdded })
								}
							>
								<Radio
									toggle
									checked={!this.state.showAutoAdded}
									label={this.props.t("materialChooser.showAutoAdded")}
								/>
							</Menu.Item>

							<Menu.Menu position="right">
								<Menu.Item name="close it" onClick={this.close}>
									<Icon name="close" /> {this.props.t("cancel")}
								</Menu.Item>

								<Menu.Item name="save it" onClick={this.save}>
									<Icon name="check" /> {this.props.t("ok")}
								</Menu.Item>
							</Menu.Menu>
						</Menu>
						<Modal.Content>
							{this.props.agfData.length ? (
								<Message
									positive
									onClick={
										this.state.agfMessage
											? null
											: () => this.setState({ agfMessage: true })
									}
								>
									<Icon
										name={this.state.agfMessage ? "angle up" : "angle down"}
										className="messageTopRightButton"
										onClick={() =>
											this.setState({ agfMessage: !this.state.agfMessage })
										}
									/>
									<Message.Header>
										{this.props.t("agf.acceptedMaterials")}
									</Message.Header>
									{this.state.agfMessage ? (
										<Message.List>
											{this.props.agfData.map((x) => (
												<Message.Item>
													{x[0] + " (" + x[1] + "-" + x[2] + ")"}
													<Icon
														style={{
															cursor: "pointer",
															marginLeft: 5,
															backgroundColor: "#2f4c2f",
															color: "white",
														}}
														size="small"
														onClick={() =>
															this.setState({
																supplierFilter: x[1],
																materialFilter: x[2].length > 0 ? x[2] : x[1],
																activeIndex: 0,
																agfMessage: false,
															})
														}
														circular
														name="search"
													/>
												</Message.Item>
											))}
										</Message.List>
									) : null}
								</Message>
							) : null}
							<Accordion fluid>
								<Accordion.Title
									active={this.state.activeIndex === 0}
									index={0}
									onClick={this.handleClick}
								>
									<Icon name="dropdown" />
									<div className="matChooserType">
										{this.props.t("materialChooser.supplier")}:
									</div>
									<div className="matChooserName">{supplierName}</div>
								</Accordion.Title>
								<Accordion.Content active={this.state.activeIndex === 0}>
									<Menu attached="top">
										<Menu.Item>
											<Input
												className="icon"
												icon="search"
												placeholder={this.props.t("filter")}
												value={this.state.supplierFilter}
												onChange={(e, { value }) =>
													this.setState({
														supplierFilter: value,
														supplierActivePage: 1,
													})
												}
											/>
										</Menu.Item>
										<Menu.Menu position="right">
											<Pagination
												activePage={this.state.supplierActivePage}
												firstItem={null}
												secondary={true}
												onPageChange={(e, { activePage }) =>
													this.setState({ supplierActivePage: activePage })
												}
												lastItem={null}
												totalPages={supplierMax}
											/>
										</Menu.Menu>
									</Menu>
									<Segment attached="bottom" className="paddingLess">
										{suppliers.length ? (
											suppliers.map((x) => (
												<div key={x.id} className="matChooserLine">
													<div
														className={
															"mclName" +
															(this.props.sortUp.has(x.id)
																? " importantSupplier"
																: "")
														}
													>
														{x.name}
													</div>
													<div className="mclButton">
														<Button
															positive={x.id !== this.state.supplier}
															negative={x.id === this.state.supplier}
															onClick={(_) =>
																this.setState({
																	supplier:
																		x.id === this.state.supplier ? null : x.id,
																	activeIndex:
																		x.id === this.state.supplier ? 0 : 1,
																})
															}
														>
															{x.id === this.state.supplier
																? this.props.t("deselect")
																: this.props.t("select")}
														</Button>
													</div>
												</div>
											))
										) : (
											<div className="nothing">
												{this.props.t("materialChooser.nothingFound")}
											</div>
										)}
									</Segment>
								</Accordion.Content>

								<Accordion.Title
									active={this.state.activeIndex === 1}
									index={1}
									onClick={this.handleClick}
								>
									<Icon name="dropdown" />
									<div className="matChooserType">
										{this.props.t("materialChooser.material")}:
									</div>
									<div
										className={
											"matChooserName" + (incompatible ? " incompatible" : "")
										}
									>
										{materialName}
									</div>
									{incompatible ? (
										<div className="matChooserInc">
											<Icon name="warning sign" />
										</div>
									) : null}
								</Accordion.Title>
								<Accordion.Content active={this.state.activeIndex === 1}>
									<Menu attached="top">
										{hasMaterialEditor ? (
											<Menu.Item
												name="materialAdder"
												onClick={(e) => this.setState({ materialEditor: true })}
											>
												<Icon name="plus" />{" "}
												{this.props.t("materialChooser.addMaterial")}
											</Menu.Item>
										) : null}
										<Menu.Item>
											<Input
												className="icon"
												icon="search"
												placeholder={this.props.t("filter")}
												value={this.state.materialFilter}
												onChange={(e, { value }) =>
													this.setState({
														materialFilter: value,
														materialActivePage: 1,
													})
												}
											/>
										</Menu.Item>

										<Menu.Menu position="right">
											<Pagination
												activePage={this.state.materialActivePage}
												firstItem={null}
												secondary={true}
												onPageChange={(e, { activePage }) =>
													this.setState({ materialActivePage: activePage })
												}
												lastItem={null}
												totalPages={materialMax}
											/>
										</Menu.Menu>
									</Menu>
									<Segment attached="bottom" className="paddingLess">
										{materials.length ? (
											materials.map((x) => (
												<div key={x.id} className="matChooserLine">
													<div className="mclName">{x.name}</div>
													<div className="mclButton">
														<Button.Group>
															{hasMaterialEditor && x.autoAdded ? (
																<Button
																	icon="pencil"
																	onClick={(e) =>
																		this.setState({ materialEditor: x.id })
																	}
																/>
															) : null}
															<Button
																positive={
																	this.state.allowMultiple
																		? !this.state.multiMaterial.includes(x.id)
																		: x.id !== this.state.material
																}
																negative={
																	this.state.allowMultiple
																		? this.state.multiMaterial.includes(x.id)
																		: x.id === this.state.material
																}
																onClick={(_) =>
																	this.setState((state) => {
																		if (state.allowMultiple) {
																			let multiMaterial = [
																				...state.multiMaterial,
																			];
																			let index = multiMaterial.indexOf(x.id);
																			if (index === -1) {
																				multiMaterial.push(x.id);
																			} else {
																				multiMaterial.splice(index, 1);
																			}
																			return {
																				multiMaterial,
																				activeIndex: state.multiMaterial.includes(
																					x.id
																				)
																					? 0
																					: 1,
																			};
																		} else {
																			return {
																				material:
																					x.id === state.material ? null : x.id,
																				activeIndex:
																					x.id === state.material ? 0 : 1,
																			};
																		}
																	})
																}
															>
																{(this.state.allowMultiple
																? this.state.multiMaterial.includes(x.id)
																: x.id === this.state.material)
																	? this.props.t("deselect")
																	: this.props.t("select")}
															</Button>
														</Button.Group>
													</div>
												</div>
											))
										) : (
											<div className="nothing">
												{this.props.t("materialChooser.nothingFound")}
											</div>
										)}
									</Segment>
								</Accordion.Content>
							</Accordion>
						</Modal.Content>
					</React.Fragment>
				)}
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(MaterialChooser)));
