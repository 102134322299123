import React from "react";
import { observer, inject } from "mobx-react";
import MakebigImg from "../../images/icon_pu.png";
import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";
const Navigation = ({ t, app: { ui } }) => {
	const links = [
		{ to: "/", name: t("menu.resources"), icon: "building outline" },
		window.bpo
			? {
					outlink: ""+process.env.REACT_APP_BPO_URL+"",
					name: t("menu.planning"),
					icon: "building outline",
			  }
			: null,
		window.bpo
			? {
					outlink: ""+process.env.REACT_APP_BPO_URL+"/live/creator.php",
					name: t("menu.realtime"),
					icon: "building outline",
			  }
			: null,
		ui.modules.has("DAYREPORTS") && ui.rights.has("SHOW_DAYREPORT")
			? { to: "/reports", name: t("menu.questions"), icon: "building outline" }
			: null,
	].filter((x) => x);
	return !ui.fullscreen ? (
		<div
			id="nav"
			onMouseDown={ui.swipeStart}
			onTouchStart={ui.swipeStart}
			onMouseUp={ui.swipeEnd}
			onTouchEnd={ui.swipeEnd}
			className="no-print"
		>
			<div id="nav-linkwrapper">
				{links.map((x, i) => {
					if ("to" in x) {
						return (
							<Link className="navlink" to={x.to} key={i}>
								{x.name}
							</Link>
						);
					} else {
						return (
							<div
								onClick={(_) =>
									!ui.saveNecessary()
										? (window.location = x.outlink)
										: ui.openMessage("edit.saveFirst")
								}
								className="navlink"
								key={i}
							>
								{x.name}
							</div>
						);
					}
				})}
			</div>
			<div id="nav-togglebig-wrapper">
				<div id="makesmall" onClick={ui.toggleBig} className="imglink">
					<img alt="link" className="img img-mirrored" src={MakebigImg} />
				</div>
			</div>
		</div>
	) : (
		<footer className="no-print">
			<div id="makebig" onClick={ui.toggleBig} className="imglink">
				<img alt="link" className="img" src={MakebigImg} />
			</div>
			<React.Fragment>
				{links.map((x, i) => {
					if ("to" in x) {
						return (
							<Link className="footlink" to={x.to} key={i}>
								{x.name}
							</Link>
						);
					} else {
						return (
							<div
								onClick={(_) =>
									!ui.saveNecessary()
										? (window.location = x.outlink)
										: ui.openMessage("edit.saveFirst")
								}
								className="footlink"
								key={i}
							>
								{x.name}
							</div>
						);
					}
				})}
			</React.Fragment>
		</footer>
	);
};

export default inject("app")(withTranslation()(observer(Navigation)));
