import React from "react";
import { withTranslation } from "react-i18next";
import CollisionHandler from "./collisionHandler.js";
import { observer, inject } from "mobx-react";
import { Button, Menu, Modal, Input, Icon, Loader } from "semantic-ui-react";
import { arrayFilter } from "../../../helpers/calendar.js";
import moment from "moment";

class CollisionWarner extends React.Component {
	constructor(props) {
		super(props);
		this.state = { open: false, filter: "" };
		this.debouncer = false;
	}

	componentDidMount() {
		this.update();
	}

	componentDidUpdate(prevProps, prevState) {
		this.update();
	}

	open() {
		this.setState({ open: true });
	}

	close() {
		this.setState({ open: false, filter: "" });
	}

	changeFilter(filter) {
		this.setState({ filter });
	}

	update() {
		
		if (
			this.props.app.resources.lastCollisionUpdate >=
			this.props.lastChange
		)
			return null;
		if (this.debouncer) clearTimeout(this.debouncer);
		this.debouncer = setTimeout(
			_ =>
				this.props.app.resources.updateCollisions(
					this.props.lastChange
				),
			500
		);
	}

	render() {
		const dx = [];
		const filter = arrayFilter(this.state.filter);
		let lastDate = null;
		for (let [a, b] of this.props.app.resources.collisionData) {
			const startString = moment(a.start).format("dddd, Do MMMM YYYY");
			if (
				!filter([
					startString,
					a.ref._resource.name,
					a.ref._resource.resclass.name,
					a.type === "deployment"
						? a.ref.project.name
						: a.ref.type.name,
					b.type === "deployment"
						? b.ref.project.name
						: b.ref.type.name
				])
			)
				continue;
			if (lastDate !== startString) {
				dx.push(<div className="collisionDate" key={"date"+a.ref.id}>{startString}</div>);
			}
			lastDate = startString;
			dx.push(
				<CollisionHandler vacationRight={this.props.app.ui.rights.has("PLAN_VACATION")} key={a.ref.id + "-" + b.ref.id} a={a} b={b} />
			);
		}
		if (!dx.length)
			dx.push(
				<div className="collisionNothing" key="collisionNothing">
					{this.props.t("collisionModal.noCollisions")}
				</div>
			);

		return (
			<Modal
				centered={false}
				className="collisionModal"
				trigger={
					<Button
						icon="warning sign"
						onClick={e => this.open()}
						className={
							this.props.app.resources.collisionData.length > 0
								? "collision"
								: ""
						}
					/>
				}
				open={this.state.open}
				onClose={e => this.close()}
			>
				{this.props.app.resources.lastCollisionUpdate <
				this.props.lastChange ? (
					<Loader size="large" />
				) : (
					<React.Fragment>
						<Menu>
							<Menu.Item header>
								{this.props.t("collisionModal.heading")}
							</Menu.Item>
							<Menu.Item>
								<Input
									className="icon"
									icon="search"
									placeholder={this.props.t("filter")}
									value={this.state.filter}
									onChange={(e, { value }) =>
										this.changeFilter(value)
									}
								/>
							</Menu.Item>
							<Menu.Menu position="right">
								<Menu.Item
									name="close it"
									onClick={e => this.close()}
								>
									<Icon name="close" />
								</Menu.Item>
							</Menu.Menu>
						</Menu>
						<Modal.Content>
							<div className="collisionWrapper">{dx}</div>
						</Modal.Content>
					</React.Fragment>
				)}
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(CollisionWarner)));
