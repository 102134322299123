import React, { useState } from "react";
import { Icon, Message } from "semantic-ui-react";

export default function({ label, info, warning }) {
	const [visible, setVisible] = useState(false);
	return (
		<React.Fragment>
			<div className="infoLabel">
				<label>{label}</label>
				<Icon
					name={warning ? "warning sign" : "question circle"}
					style={{ cursor: "pointer", color: warning ? "red" : "black" }}
					onMouseEnter={() => setVisible(true)}
					onMouseLeave={() => setVisible(false)}
				/>
			</div>
			<div
				className={"infoLabelMessageWrapper " + (visible ? " visibleX" : "")}
			>
				<Message className={"infoLabelMessage"} info={!warning} negative={warning} size="tiny">
					{Array.isArray(info)
						? info.map((item, key) => {
								return (
									<React.Fragment key={key}>
										{item}
										<br />
									</React.Fragment>
								);
						  })
						: info}
				</Message>
			</div>
		</React.Fragment>
	);
}
