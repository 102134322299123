import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Tab, Form } from "semantic-ui-react";
import { TimeInput } from "semantic-ui-calendar-react";
import BlurChangeInput from "../controls/blurChangeInput";
import { SingleDatePicker } from "react-dates";
import MetacomSiteSelector from "../../addons/MetacomSiteSelector";

const EditBasedata = (props) => {
	const { t } = useTranslation();
	const [focused, setFocused] = useState(false);

	const companyName = props.data.props.app.basedata.company?.name ?? ""; 

	let costbaseVal = "";
	if(props.edit.state.data.costbase){
		costbaseVal = props.edit.state.data.costbase;
	} else if(companyName === 'eurovia'){
		costbaseVal = 'K000.0000000000';
	}

	return (
		<Tab.Pane>
			<Form>
				{props.global ? (
					<React.Fragment>
						{props.metacom ? (
							<MetacomSiteSelector
								onSelect={(selection) => {
									if ("id" in selection) {
										props.edit.changeProperty("main", {
											name: selection.name,
											bstn: selection.id,
											costbase: selection.id,
										});
									} else {
										let data = { name: selection.name };

										if ("bstn" in selection && selection.bstn !== "")
											data["bstn"] = selection.bstn;

										if ("costbase" in selection && selection.costbase !== "")
											data["costbase"] = selection.costbase;

										if ("client" in selection && selection.client !== "")
											data["client"] = selection.client;

										if (
											"location" in selection &&
											"lat" in selection.location &&
											selection.location.lat !== 0
										)
											data["position"] = {
												lat: selection.location.lat,
												lng: selection.location.lng,
												zoom: 10,
											};

										props.edit.changeProperty("main", data);
									}
									props.edit.saveHistory();
								}}
							/>
						) : null}

						<BlurChangeInput
							fluid
							label={t("edit.fields.name.label")}
							placeholder={t("edit.fields.name.placeholder")}
							value={props.edit.state.data.name || ""}
							onBlur={(_) => props.edit.saveHistory()}
							disabled={!props.edit.state.data.editable}
							onChange={(e) =>
								props.edit.changeProperty("main", {
									name: e.target.value,
								})
							}
						/>
						<Form.Group widths="equal">
							<BlurChangeInput
								fluid
								label={t("edit.fields.constructionNumber.label")}
								placeholder={t("edit.fields.constructionNumber.placeholder")}
								value={props.edit.state.data.bstn || ""}
								onBlur={(_) =>{ 
									props.edit.validateBstn();
									props.edit.saveHistory();
								}}
								disabled={!props.edit.state.data.editable}
								
								onChange={(e) =>
									props.edit.changeProperty("main", {
										bstn: e.target.value,
									})
				
								}
							/>
							<BlurChangeInput
								fluid
								label={t("edit.fields.costCentre.label")}
								placeholder={t("edit.fields.costCentre.placeholder")}
								value={costbaseVal}
								onBlur={(_) => props.edit.saveHistory()}
								disabled={!props.edit.state.data.editable}
								onChange={(e) =>
									props.edit.changeProperty("main", {
										costbase: e.target.value,
									})
								}
							/>
							<BlurChangeInput
								fluid
								label={t("edit.fields.client.label")}
								placeholder={t("edit.fields.client.placeholder")}
								value={props.edit.state.data.client || ""}
								onBlur={(_) => props.edit.saveHistory()}
								disabled={!props.edit.state.data.editable}
								onChange={(e) =>
									props.edit.changeProperty("main", {
										client: e.target.value,
									})
								}
							/>
						</Form.Group>
					</React.Fragment>
				) : (
					<React.Fragment>
						{!props.edit.isFullPlanning && props.data.state.formData.date !== false ? (
							<Form.Field>
								<label>{t("date")}</label>
								<SingleDatePicker
									date={moment(props.data.state.formData.date)} // momentPropTypes.momentObj or null,
									id="xdpicker_date" // PropTypes.string.isRequired,
									numberOfMonths={3}
									disabled={props.edit.state.data.fixed}
									isOutsideRange={(x) => props.edit.isDayFree(x)}
									onDateChange={(date) =>
										props.data.changeDate(date)
									} // PropTypes.func.isRequired,
									focused={focused} // PropTypes.bool
									onFocusChange={({ focused }) => setFocused(focused)}
									hideKeyboardShortcutsPanel
								/>
							</Form.Field>
						) : null}
						<Form.Group widths="equal">
							<Form.Field>
								<label>Beginn</label>
								<TimeInput
									name="time"
									animation="none"
									placeholder={
										props.data.state.formData.start === false
											? t("choose.same")
											: t("choose.initial")
									}
									value={
										props.data.state.formData.start === false
											? ""
											: props.data.state.formData.start
									}
									closable
									iconPosition="left"
									onChange={(e, { value }) =>
										props.data.changeTime("start", value)
									}
									disabled={
										!props.edit.state.data.editable ||
										(!!props.data.state.formData.bpo.length && !(companyName === 'cajot'))
									}
								/>
							</Form.Field>
							{
								companyName === 'cajot' ? (
									<Form.Field>
										<label>{t("pavingStart")}</label>
										<TimeInput
											name="time"
											animation="none"
											closable
											placeholder={
												props.data.state.formData.paving_start === false
													? t("choose.same")
													: t("choose.initial")
											}
											value={
												props.data.state.formData.paving_start === false
													? ""
													: props.data.state.formData.paving_start
											}
											iconPosition="left"
											onChange={(e, { value }) =>
												props.data.changeTime("paving_start", value)
											}
											disabled={
												true
											}
										/>
									</Form.Field>
								) : null
							}
							
							<Form.Field>
								<label>Ende</label>
								<TimeInput
									name="time"
									animation="none"
									closable
									placeholder={
										props.data.state.formData.end === false
											? t("choose.same")
											: t("choose.initial")
									}
									value={
										props.data.state.formData.end === false
											? ""
											: props.data.state.formData.end
									}
									iconPosition="left"
									onChange={(e, { value }) =>
										props.data.changeTime("end", value)
									}
									disabled={
										!props.edit.state.data.editable ||
										(!!props.data.state.formData.bpo.length && !(companyName === 'cajot'))
									}
								/>
							</Form.Field>
						</Form.Group>
					</React.Fragment>
				)}
				{!props.global ? (
					<BlurChangeInput
						textarea={true}
						label={t("edit.fields.comments.label")}
						value={
							props.data.state.formData.comment === false
								? ""
								: props.data.state.formData.comment
						}
						onBlur={(_) => props.edit.saveHistory()}
						onChange={(e) => props.data.changeValue("comment", e.target.value)}
						disabled={!props.edit.state.data.editable}
						placeholder={
							props.data.state.formData.comment === false
								? t("edit.fields.comments.same")
								: t("edit.fields.comments.placeholder")
						}
					/>
				) : (
					<BlurChangeInput
						textarea={true}
						label={t("edit.fields.globalcomments.label")}
						disabled={!props.edit.state.data.editable}
						value={
							props.edit.state.data.comment === false
								? ""
								: props.edit.state.data.comment
						}
						onBlur={(_) => props.edit.saveHistory()}
						onChange={(e) =>
							props.edit.changeProperty("main", {
								comment: e.target.value,
							})
						}
						placeholder={t("edit.fields.globalcomments.placeholder")}
					/>
				)}
			</Form>
		</Tab.Pane>
	);
};

export default EditBasedata;
