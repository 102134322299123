import React from "react";
import { inject, observer } from "mobx-react";
import { isValidReference } from "mobx-state-tree";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import arrayMove from "array-move";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import TimeButtons from "../components/report/timebuttons";
import axios from "axios";
import Color from "color";
import {
  Dropdown,
  Button,
  Loader,
  Message,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import QuestionaryEditModal from "../components/questions/QuestionaryEditModal";
import QuestionaryModal from "../components/questions/QuestionaryModal";
import Codes from "../components/questions/Codes";
import ConfirmButton from "../components/calendar/controls/confirmButton";
import ReportModal from "../components/questions/ReportModal";
import {
  SortableElement,
  sortableHandle,
  SortableContainer,
} from "react-sortable-hoc";

const DragHandle = sortableHandle(() => (
  <Icon
    name="bars"
    size="large"
    style={{ cursor: "row-resize", marginRight: 10 }}
  />
));

const Questionary = SortableElement(({ x, last }) => (
  <Segment
    className="questionarySegment"
    attached={last ? "bottom" : true}
    key={x.id}
  >
    <div className="namecell">
      <DragHandle />
      {x.name}
    </div>
    <div className="buttoncell">
      <QuestionaryModal
        questionaryId={x.id}
        classConstraints={
          new Set(
            Array.from(x._resClasses.values())
              .filter((y) => isValidReference(() => y) && !y.deleted)
              .map((y) => y.id)
          )
        }
        trigger={<Button icon="pencil" />}
      />
      <QuestionaryEditModal
        questionaryId={x.id}
        trigger={<Button icon="settings" />}
      />
      <ConfirmButton
        onClick={(e) => x.delete()}
        question={["questions.delete", { name: x.name }]}
        ok="yes"
        cancel="no"
        trigger={<Button icon="trash alternate" />}
      />
    </div>
  </Segment>
));

const Questionaries = SortableContainer(({ items }) => (
  <div>
    {items.map((x, i, items) => (
      <Questionary key={x.id} x={x} last={i === items.length - 1} index={i} />
    ))}
  </div>
));

class QuestionPage extends React.Component {

  cancelTokenSource = axios.CancelToken.source();
  
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      start: moment().startOf("week"),
      end: moment().startOf("day"),
      focusedInput: null,
      loading: true,
      error: false,
      results: [],
      filterValue: "",
      archiveStatus: false,
      haveUnReadReports: false,
      showUnReadStatusOnly: false,
      foremen_filter: [
        { key: "", value: 0, text: "Select Formen" }
      ],
      foremen_filter_selected: null,
      downloadAction: {
        isLoading: false,
        project: null,
        processId: null
      },
    };
    this.updateReadStatus = this.updateReadStatus.bind(this);
    this.processTemplateCommonColor = "#003594";
  }

  componentDidMount() {
    /*this.props.appCtx.setEditButtons(
      <Link className="topbutton" to="/">
        <img alt="Back" src={WahlImg} />
      </Link>,
      null
    ); */

    
    if (
        this.props.app.ui.modules.has("DAYREPORTS") &&
        this.props.app.ui.rights.has("SHOW_DAYREPORT")
    ) {
        this.props.app.ui.loadData("questions", false, false);
    } else {
        this.props.app.ui.loadCompleted(true);
    }
    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.start &&
      this.state.end &&
      (!prevState.start ||
        !prevState.end ||
        this.state.start !== prevState.start ||
        this.state.end !== prevState.end)
    ) {
      this.load();
    }
  }

  getOutsideRange(filterStartDate, filterEndDate, fetchedStartDate, fetchedEndDate) { 
    let filterStart = new Date(isNaN(filterStartDate) ? filterStartDate : parseInt(filterStartDate));
    let filterEnd = new Date(isNaN(filterEndDate) ? filterEndDate : parseInt(filterEndDate));
    let fetchedStart = new Date(isNaN(fetchedStartDate) ? fetchedStartDate : parseInt(fetchedStartDate));
    let fetchedEnd = new Date(isNaN(fetchedEndDate) ? fetchedEndDate : parseInt(fetchedEndDate));

    if (filterEnd <= fetchedStart || filterStart >= fetchedEnd) {
        return { start: filterStart, end: filterEnd };
    }

    let outsideStart = filterStart < fetchedStart ? filterStart : null;
    let outsideEnd = filterEnd >= fetchedStart ? new Date(fetchedStart.getTime() - 86400000) : filterEnd;  

    if (outsideStart && outsideEnd && outsideStart <= outsideEnd) {
        return { start: outsideStart, end: outsideEnd };
    } else {
        return null;  
    }
  }

  async load() {

    this.cancelTokenSource.cancel(); // cancel the existing token
    this.cancelTokenSource = axios.CancelToken.source();

    const start = this.state.start.valueOf();
    const end = this.state.end.endOf("day").valueOf();
    const jwttoken = localStorage.getItem("binfra_token");
    const foremen = this.state.foremen_filter_selected;
    let isRead = false; // everything is readed by default

    let syncedMonths = this.props.app.ui.lastUpdateMonths._data; 
    let syncedMonthsDates = Array.from(syncedMonths.keys()); 
    let dateObjects = syncedMonthsDates.map(dateStr => {
        let [year, month] = dateStr.split('-');
        let startDate = new Date(parseInt(year), parseInt(month) - 1, 1);  
        let endDate = new Date(parseInt(year), parseInt(month), 0);       
        return { startDate, endDate };
    }); 
    let syncedStartDate = new Date(Math.min(...dateObjects.map(d => d.startDate.getTime())));
    let syncedEndDate = new Date(Math.max(...dateObjects.map(d => d.endDate.getTime())));  
    let syncedResult = this.getOutsideRange(start, end, syncedStartDate.getTime(), syncedEndDate.getTime());
    
    this.setState({ error: false, loading: true }); 
    try {
      const res = await axios.get(
        process.env.REACT_APP_SERVER_URL +
        "/loadReport/" +
        start +
        "/" +
        end +
        (foremen ? '/' + foremen : '') +
        "?v=" + process.env.REACT_APP_SERVER_VERSION + "&" +
        Math.random(),
        {
          headers: {
            Authorization: "Bearer " + jwttoken,
          },
          cancelToken: this.cancelTokenSource.token,
        }
      ); 
      
      
      /*
      let specialCase = false;
      const startYearMonth = `${this.state.start.year()}-${this.state.start.month() + 1}`;
      let syncedMonths = this.props.app.ui.lastUpdateMonths;
      if(syncedMonths.has(startYearMonth)){
        let prevStart = this.state.start.clone().subtract(2, 'days');
        const prevMonthStart = `${prevStart.year()}-${prevStart.month() + 1}`;
        // if start date is 1st of month and previous month is not synced
        if((this.state.start.date() === 1) && !syncedMonths.has(prevMonthStart)){
            specialCase = true;
        }
      }
      */

      let projectsIDs = [];
      //if(res.data.length && (!syncedMonths.has(startYearMonth) || specialCase)){
      if(res.data.length && syncedResult ){
        res.data.map((x) => {
          return projectsIDs.push(x.project);
        });
        await axios.post(
          process.env.REACT_APP_SERVER_URL + "/loadReportFullData",
            JSON.stringify({
              projectsIDs: projectsIDs,
              start: syncedResult.start.getTime(),
              end: syncedResult.end.getTime()
            }),
          {
            headers: { 
              Authorization: "Bearer " + localStorage.getItem("binfra_token"),
            },
          }
        )
        .then((response) => {
          this.props.app.ui.handleProjectsForReports(response.data.projects);
          this.props.app.ui.handleResourcesForReports(response.data.resources);
        })
        .catch((error) => {
          console.log(error); 
        });
      }

      let temp_foremen = [];
      this.setState({
        error: false,
        loading: false,
        foremen_filter: [
          { key: "", value: 0, text: "Select Formen" }
        ],
        results: this.transformResultsData(res.data)
          .filter((x) => this.props.app.projects.projects.has(x.project))
          .map((x) => { 

            // check formen name is in the project
            let answeredBy = '';
            let propResources = this.props.app.resources.resources;
            x.answeredByFormen.map(function (each_formen) {
              if (propResources.has(each_formen)) {
                answeredBy = propResources.get(each_formen).name;
                temp_foremen[each_formen] = answeredBy;
              }
              return temp_foremen;
            });

            if (x.isRead === 0) { isRead = true; } // a project is not readed

            let projectNameWithBTSN = x.bstn ? x.bstn + ': ' + this.props.app.projects.projects.get(x.project).name : this.props.app.projects.projects.get(x.project)
              .fullName;

            let projectNameForDisplay = x.projectBstn ? x.projectBstn + ': ' + this.props.app.projects.projects.get(x.project).name : this.props.app.projects.projects.get(x.project)
            .fullName;

            return {
              ...x,
              // projectName: this.props.app.projects.projects.get(x.project).fullName,
              projectName: projectNameWithBTSN,
              projectNameWithbstn: projectNameForDisplay,
              dateFormatted: moment(x.date).format("DD.MM.YYYY"),
              // answeredBy: x.answeredBy,
              answeredByFormen: x.answeredByFormen,
              // answeredByName: answeredBy,
              hasImages: x.hasImages,
              hasAnalysis: x.has_analysis,
              imageList: x.imagesList,
            };
          })
          .sort((a, b) =>
            a.date < b.date
              ? -1
              : a.date > b.date
                ? 1
                : a.projectName.localeCompare(b.projectName)
          )
      });

      let new_formen_list = this.state.foremen_filter;
      for (let key in temp_foremen) {
        new_formen_list.push({
          key: key,
          value: key,
          text: temp_foremen[key]
        });
      }

      this.setState({
        haveUnReadReports: isRead,
        foremen_filter: new_formen_list,
        foremen_filter_selected: temp_foremen[this.state.foremen_filter_selected] ? this.state.foremen_filter_selected : 0
      });

    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      else if (error.response && error.response.status === 401) {
        this.props.app.ui.setLoginNecessary("callback", this.load.bind(this));
      }
      this.setState({ error: true, loading: false });
      console.log(error);
    } 
  }

  componentWillUnmount() {
    //this.props.appCtx.setEditButtons(false);
    this.cancelTokenSource.cancel();
    console.log("componentWillUnmount")
  }

  foremenFilterChange(value) {
    this.setState({ foremen_filter_selected: value });
    setTimeout(() => this.load(), 1000);
  }

  searchByName(event) {
    this.setState({ filterValue: event.target.value });
  }

  laodProjectBasedOnArchive(archiveStatus = false) {
    this.setState({ archiveStatus: archiveStatus });
  }

  transformResultsData(reports){
    let dict = {};
    for(const report of reports){
        let processObj = null;
        if(this.props.app.projects.processes.has(report.process)){
            processObj = this.props.app.projects.processes.get(report.process);
        }
        
        if((report.project + report.date) in dict){
            if(processObj !== null){
                dict[(report.project + report.date)].processes.push(processObj);
            }
            
        }else{
            if(processObj !== null){
                report.processes = [processObj];
            }else{
                report.processes = [];
            }
            
            dict[(report.project + report.date)] = report;

        }

        // split diary images based on process

        
        dict[(report.project + report.date)].oldReport = report.oldReport;

        if(report.oldReport === true){
            if(!('hasImages' in dict[(report.project + report.date)])){
                dict[(report.project + report.date)].hasImages = false;
            }
            dict[(report.project + report.date)].hasImages = dict[(report.project + report.date)].hasImages || report.has_images;
            if(!('imagesList' in dict[(report.project + report.date)])){
                dict[(report.project + report.date)].imagesList = [];
            }
            if(Object.keys(report.imageList).length > 0){
                dict[(report.project + report.date)].imagesList.push(report.imageList);
            }
        }else{
            if(!('hasImages' in dict[(report.project + report.date)])){
                dict[(report.project + report.date)].hasImages = {};
            }
            dict[(report.project + report.date)].hasImages[report.process] = report.has_images;
            if(!('imagesList' in dict[(report.project + report.date)])){
                dict[(report.project + report.date)].imagesList = {};
            }
            dict[(report.project + report.date)].imagesList[report.process] = report.imageList;
        }
    }
    return Object.values(dict);
  }

  async updateArchiveStatus(event, project, archiveStatus) {
    const jwttoken = localStorage.getItem("binfra_token");
    this.setState({ error: false, loading: true });
    let project_date = moment(project.date).utcOffset(0, true).valueOf();
    try {
      const res = await axios.get(
        process.env.REACT_APP_SERVER_URL +
        "/saveArchiveProject/" +
        project.project +
        "/" +
        project_date +
        "/" +
        archiveStatus +
        "?v=" + process.env.REACT_APP_SERVER_VERSION + "&" +
        Math.random(),
        {
          headers: {
            Authorization: "Bearer " + jwttoken,
          },
        }
      );

      if (res.data.status) {
        let existing_result = this.state.results;
        this.setState({
          error: false,
          loading: false,
          results: existing_result.map(
            function (value, key) {
              if (value.project === project.project && value.date === project.date) {
                if (archiveStatus === 'ARCHIVE') {
                  value.archiveStatus = 1;
                }
                else {
                  value.archiveStatus = 0;
                }
              }
              return value;
            }).sort((a, b) =>
              a.date < b.date ? -1 : a.date > b.date ? 1 : a.projectName.localeCompare(b.projectName)
            )
        });
      }


    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.props.app.ui.setLoginNecessary("callback", this.load.bind(this));
      }
      this.setState({ error: true, loading: false });
      console.log(error);
    }
  }

  async downloadImages(event, project, processId) {

    let image_list = {};

    try {
      this.setState({
        'downloadAction': {
          isLoading: true,
          project: project.project + '_' + project.date,
          processId: processId
        }
      });
      if(processId !== null){
        for (let image in project.imageList[processId]) {
            image_list[process.env.REACT_APP_SERVER_URL + "/getFile/" + image + "/x"] = project.projectName + '_' + project.imageList[processId][image];
        }
      }else{
        for (let imageSet in project.imageList) {
            for (let image in project.imageList[imageSet]) {
                image_list[process.env.REACT_APP_SERVER_URL + "/getFile/" + image + "/x"] = project.projectName + '_' + project.imageList[imageSet][image];
            }
        }
      }
      

      let data = {
        'key': 'ac5ccbd0-bd82-4f8f-a8be-3090f7d36ab1',
        'files': JSON.stringify(image_list)
      }

      let qs = require('qs');
      await axios.post(
        "" + process.env.REACT_APP_BPO_URL + "/common/interfaces/Zipper.php",
        qs.stringify(data), { responseType: 'blob' }
      ).then((response) => {
        // create file link in browser's memory
        let href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        let link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', project.projectName + '_' + project.date + '_file.zip'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({
          'downloadAction': {
            isLoading: false,
            project: null,
            processId: null
          }
        });
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.props.app.ui.setLoginNecessary("callback", this.load.bind(this));
      }
      this.setState({
        error: true, loading: false, downloadAction: {
          isLoading: false,
          project: null,
          processId: null
        }
      });
      console.log('question.js->downloadImages', error);
    }
  }

  openNewTab(event, analysisId) {
    window.open(process.env.REACT_APP_BPO_URL + '/live/creator.php?analysis=' + analysisId, '_blank');
  };

  updateReadStatus(reportData) {
    let checkUnReadStatus = false;
    this.setState((prevState) => {
      const updatedData = prevState.results.map(item => {
        if (item.project === reportData.project && item.date === reportData.date) {
          item.isRead = 1;
          return { ...item };
        }
        else if (item.isRead === 0) {
          checkUnReadStatus = true;
        }
        return item;
      });

      return { results: updatedData, haveUnReadReports: checkUnReadStatus };
    });
  }

  showReportBasedOnStatus(readStatus = false) {
    this.setState({ showUnReadStatusOnly: readStatus });
  }


  render() {
    const questionaries = this.state.edit
      ? Array.from(this.props.app.questions.questionaries.values())
        .filter((x) => !x.deleted)
        .sort((a, b) =>
          a.ordering < b.ordering
            ? -1
            : b.ordering < a.ordering
              ? 1
              : a.name.localeCompare(b.name)
        )
      : [];

    let filter_data = this.state.results.filter((x) => {
      if ((this.state.haveUnReadReports && this.state.showUnReadStatusOnly && x.isRead === 0)) {
        return x;
      }
      else if (!this.state.showUnReadStatusOnly && ((this.state.archiveStatus && x.archiveStatus) || (!this.state.archiveStatus && !x.archiveStatus)) && (x.projectName.toLowerCase().indexOf(this.state.filterValue.toLowerCase()) > -1)) {
        return x;
      }
      else {
        return false
      }
    });

    let header_text = this.props.t("questions.reports.header"); // header title text
    if (this.state.showUnReadStatusOnly) {
      header_text += ': ' + this.props.t("questions.unread");
    }
    else if (this.state.archiveStatus) {
      header_text += ': ' + this.props.t("questions.archive");
    }

    return (
      <div id="questionPage" className="questionPage groupPage">
        <div id="map_buttons" className="buttonLine">
          <div className="buttonBlock">
            {this.state.edit ? (
              <Button
                onClick={() => this.setState({ edit: false })}
                labelPosition="left"
                content={this.props.t("back")}
                icon="arrow left"
              />
            ) : (
              <div>
                {(this.state.archiveStatus || this.state.showUnReadStatusOnly) ? (
                  <Button
                    onClick={(x) => this.state.showUnReadStatusOnly ? this.showReportBasedOnStatus(false) : this.laodProjectBasedOnArchive(false)}
                    labelPosition="left"
                    content={this.props.t("back")}
                    icon="arrow left"
                  />
                ) : (
                  <Button
                    onClick={(x) => this.laodProjectBasedOnArchive(true)}
                    labelPosition="left"
                    content={this.props.t("questions.archive")}
                    icon="archive"
                  />
                )}

                <DateRangePicker
                  startDate={this.state.start ? this.state.start : null} // momentPropTypes.momentObj or null,
                  startDateId="dpicker_start" // PropTypes.string.isRequired,
                  calendarInfoPosition="bottom"
                  numberOfMonths={3}
                  endDate={this.state.end ? this.state.end : null} // momentPropTypes.momentObj or null,
                  endDateId="dpicker_end" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) =>
                    this.setState({
                      start: startDate,
                      end: endDate,
                    })
                  } // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) =>
                    this.setState({ focusedInput })
                  } // PropTypes.func.isRequired,
                  renderCalendarInfo={(x) => (
                    <TimeButtons
                      onClick={(start, end) =>
                        this.setState({ start: moment(start), end: moment(end) })
                      }
                    />
                  )}
                  startDatePlaceholderText={this.props.t("begin")}
                  endDatePlaceholderText={this.props.t("end")}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={() => false}
                  minimumNights={0}
                  withPortal={true}
                />
                <Dropdown
                  button
                  className='icon'
                  floating
                  labeled
                  icon='user'
                  options={this.state.foremen_filter}
                  search
                  placeholder='Select Foremen'
                  style={{ marginLeft: '5px' }}
                  lazyLoad={true}
                  onChange={(x, { value }) => this.foremenFilterChange(value)}
                  value={this.state.foremen_filter_selected}
                />
                <div className="ui input">
                  <input
                    type="text"
                    value={this.state.filterValue}
                    onChange={(e) => this.searchByName(e)}
                    name="text"
                    placeholder="search .."
                  />
                </div>
              </div>
            )}
          </div>
          <div className="buttonBlock">
            {this.state.edit ? (
              <QuestionaryEditModal
                questionaryId={null}
                trigger={
                  <Button
                    labelPosition="left"
                    content={this.props.t("questions.addQuestionary")}
                    icon="add"
                  />
                }
              />
            ) : (
              <React.Fragment>
                {this.state.haveUnReadReports && this.state.showUnReadStatusOnly === false ? (
                  <div className="buttoncell" style={{ marginRight: 5 }}>
                    <Button
                      onClick={(x) => this.showReportBasedOnStatus(true)}
                      className="collision" icon="warning sign"
                    />
                  </div>
                ) : null}
                <Codes />
                {this.props.app.ui.rights.has("MODIFY_QUESTIONS") ? (
                  <Button
                    onClick={() => this.setState({ edit: true })}
                    labelPosition="left"
                    content={this.props.t("questions.editQuestionaries")}
                    icon="pencil alternate"
                  />
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="groupBody">
          {this.state.edit ? (
            <div className="mockTable">
              <Segment attached="top" className="thead">
                {this.props.t("questions.questionaries.header")}
              </Segment>
              {questionaries.length ? (
                <Questionaries
                  useDragHandle
                  items={questionaries}
                  helperClass="sortableHelper"
                  onSortEnd={({ oldIndex, newIndex }) => {
                    const xq = arrayMove(questionaries, oldIndex, newIndex);
                    for (let i = 0; i < xq.length; i++) {
                      xq[i].setOrdering(i);
                    }
                    return;
                  }}
                />
              ) : (
                <Segment attached="bottom">
                  <Message
                    fluid
                    header={this.props.t("questions.noQuestions.header")}
                    content={this.props.t("questions.noQuestions.text", {
                      button: this.props.t("questions.addQuestionary"),
                    })}
                  />
                </Segment>
              )}
            </div>
          ) : (
            <div className="mockTable">
              {this.state.error ? (
                <Header
                  style={{ margin: 20, textAlign: "center", display: "block" }}
                  className="connectionError"
                  icon
                >
                  <Icon name="wifi" />
                  {this.props.t("loader.networkError")}
                  <Header.Subheader>
                    <Button onClick={(x) => this.load()}>
                      {this.props.t("loader.tryAgain")}
                    </Button>
                  </Header.Subheader>
                </Header>
              ) : this.state.loading ? (
                <Loader
                  style={{ margin: 20 }}
                  active
                  inline="centered"
                  size="large"
                >
                  {this.props.t("report.loading")}
                </Loader>
              ) : (
                <React.Fragment>
                  <Segment attached="top" className="thead">
                    {header_text}
                  </Segment>
                  {filter_data.length > 0 ? (
                    filter_data.map((x, i, o) => (
                      <Segment
                        className="questionarySegment"
                        attached={i + 1 === o.length ? "bottom" : true}
                        key={x.project + x.date}
                      >
                        <div className="namecell">
                          {x.dateFormatted}
                          {" - "}
                          {x.projectNameWithbstn}

                          {/* {x.answeredByName && false ? (
                            <div className="createdBy">
                            <i>{this.props.t("questions.created")}: {x.answeredByName}</i>
                            </div>
                          ) : null} */}
                        </div>
                        {/* {x.hasImages ? (
                          <div className="buttoncell" style={{ marginRight: 5 }}>
                            {this.state.downloadAction.isLoading && this.state.downloadAction.project === (x.project + '_' + x.date) ? (
                              <Button
                                className="ui loading button icon"
                                icon=""
                                data={this.state.downloadAction.project}
                                dataa={x.project}
                              />
                            ) : (
                              <Button
                                icon="cloud download"
                                onClick={(e) => this.downloadImages(e, x)}
                                data={this.state.downloadAction.project}
                                dataa={x.project}
                              />
                            )}
                          </div>
                        ) : null} */}

                        {x.hasAnalysis ? (
                          <div className="buttoncell" style={{ marginRight: 5 }}>
                            <Button
                              icon="line graph"
                              onClick={(e) => this.openNewTab(e, x.hasAnalysis)}
                            />
                          </div>
                        ) : null}

                        {x.isRead === 0 ? (
                          <div className="buttoncell" style={{ marginRight: 5 }}>
                            <Button className="collision"
                              icon="warning sign"
                            // onClick={(e)=> this.updateReadStatus(x)}
                            />
                          </div>
                        ) : null}

                        <div className="buttoncell" style={{ marginRight: 5 }}>
                          <Button
                            icon="archive"
                            labelPosition="left"
                            content={x.archiveStatus ? this.props.t("questions.unarchive") : this.props.t("questions.archive")}
                            onClick={(e) => this.updateArchiveStatus(e, x, x.archiveStatus ? 'UNARCHIVE' : 'ARCHIVE')}
                          />
                        </div>
                        
                        {/* <div className="buttoncell">
                          <ReportModal data={x} project={x.project} date={x.date} updateReadStatus={this.updateReadStatus} />
                        </div> */}
                        <div className="processNames">
                            
                            {x.oldReport === true ? (
                                <div className="processReportItem">
                                    <div
                                        className={
                                            "processSwatch picon picon-street"
                                        }
                                        style={{
                                            color:
                                                "rgba(" +
                                                (Color(this.processTemplateCommonColor).isLight()
                                                    ? "0,0,0"
                                                    : "255,255,255") +
                                                ",0.75)",
                                            backgroundColor: this.processTemplateCommonColor
                                        }}
                                    ></div>
                                    {x.processes.map((process) => process.name).join(", ")}
                                    <div
                                    className='spacer'> </div>
                                    {x.hasImages ? (
                                    <div className="buttoncell" style={{ marginRight: 0 }}>
                                        {this.state.downloadAction.isLoading && this.state.downloadAction.project === (x.project + '_' + x.date) ? (
                                        <Button
                                            className="ui loading button icon"
                                            icon=""
                                            data={this.state.downloadAction.project}
                                            dataa={x.project}
                                        />
                                        ) : (
                                        <Button
                                            icon="cloud download"
                                            onClick={(e) => this.downloadImages(e, x, null)}
                                            data={this.state.downloadAction.project}
                                            dataa={x.project}
                                        />
                                        )}
                                    </div>
                                    ) : null}
                                    <ReportModal data={x} project={x.project} allprocesses={x.processes} oldReport={x.oldReport} process={null} date={x.date} updateReadStatus={this.updateReadStatus} />
                                </div>)
                            : x.processes.map(process => {
                                return (<div className="processReportItem" key={process.id}>
                                    <div
                                        className={
                                            "processSwatch" +
                                            (process._template.icon ? " picon picon-" + process._template.icon : "")
                                        }
                                        style={{
                                            color:
                                                "rgba(" +
                                                (Color(process._template.color).isLight()
                                                    ? "0,0,0"
                                                    : "255,255,255") +
                                                ",0.75)",
                                            backgroundColor: process._template.color
                                        }}
                                    ></div>
                                        {process.name}
                                        <div
                                        className='spacer'> </div>
                                        {x.hasImages[process.id] ? (
                                        <div className="buttoncell" style={{ marginRight: 0 }}>
                                            {this.state.downloadAction.isLoading && this.state.downloadAction.processId === process.id && this.state.downloadAction.project === (x.project + '_' + x.date) ? (
                                            <Button
                                                className="ui loading button icon"
                                                icon=""
                                                data={this.state.downloadAction.project}
                                                dataa={x.project}
                                                dataprocess={process}
                                            />
                                            ) : (
                                            <Button
                                                icon="cloud download"
                                                onClick={(e) => this.downloadImages(e, x, process.id)}
                                                data={this.state.downloadAction.project}
                                                dataa={x.project}
                                                dataprocess={process}
                                            />
                                            )}
                                        </div>
                                        ) : null}
                                        <ReportModal data={x} project={x.project} allprocesses={x.processes} process={process} date={x.date} updateReadStatus={this.updateReadStatus} />
                                    </div>)
                            })}
                        </div>
                      </Segment>
                    ))
                  ) : (
                    <Segment attached="bottom">
                      <Message
                        fluid
                        icon="calendar times outline"
                        header={this.props.t("nothingFoundMessage.header")}
                        content={this.props.t("nothingFoundMessage.message")}
                      />
                    </Segment>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default inject("app")(
  withTranslation()(withRouter(observer(QuestionPage)))
);
