import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Segment } from "semantic-ui-react";

const QuestionImage = ({ data }) => {
	const { t } = useTranslation();

	return (
		<Segment.Group className="qImageWrapper">
			<Segment className="qMessageSegment">
				{data.message.length ? (
					<div className="messageSegment">
						{data.message.split("\n").map((item, key) => {
							return (
								<React.Fragment key={key}>
									{item}
									<br />
								</React.Fragment>
							);
						})}
					</div>
				) : null}
				{data.attachment.length ? (
					<div
						className="qImageSegment"
						style={{
							backgroundImage:
								"url(" +
								process.env.REACT_APP_SERVER_URL +
								"/getFile/" +
								data.id +
								"/x?)",
						}}
					/>
				) : null}
			</Segment>

			<Segment secondary className="qMetaSegment">
				{t("questions.reports.answeredByAt", {
					writer: data.creator,
					date: moment(data.creationTime).format("DD.MM.YYYY HH:mm"),
				})}
			</Segment>
		</Segment.Group>
	);
};

export default QuestionImage;
