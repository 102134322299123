import React from "react";
import ProjectResourceLine from "./projectResourceLine";

class ProjectGroupLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle(){
		this.setState(x => ({open: !x.open}));
	}

	render() {
		//console.log(this.state);
		return (
			<React.Fragment>
				<ProjectResourceLine
					xwidth={this.props.xwidth}
					pstate={this.props.pstate}
					data={this.props.data}
					group={this.toggle}
				/>
				{!this.state.open
					? null
					: this.props.data.members.map(x => (
							<ProjectResourceLine
								xwidth={this.props.xwidth}
								pstate={this.props.pstate}
								data={x}
								key={x.resource.id}
								group={true}
							/>
					  ))}
			</React.Fragment>
		);
	}
}

export default ProjectGroupLine;
