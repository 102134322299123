import React from "react";
import { Button } from "semantic-ui-react";
import ConfirmButton from "../controls/confirmButton";
import moment from "moment";

const CollisionHandler = ({ a, b, vacationRight }) => {
	return (
		<div className="collisionHandler">
			<div className="resourceName">
				{a.ref._resource.resclass.name + ": " + a.ref._resource.name}
			</div>
			<div className="project">
				<div className="accept">
					<ConfirmButton
						onClick={
							a.ref.id < b.ref.id
								? e => a.ref.addWhitelist(b.ref.id)
								: e => b.ref.addWhitelist(a.ref.id)
						}
						trigger={
							<Button
								icon="checkmark"
								compact
							/>
						}
					/>
				</div>
				<div className="delete">
					{((a.type === "absence" && vacationRight) || (a.type !== "absence" && a.ref.job.id !== false))  ? (<ConfirmButton
						onClick={e => a.ref.delete()}
						trigger={
							<Button
								icon="trash alternate"
								compact
							/>
						}
					/>):null}
				</div>
				<div className="time">
					{a.type !== "deployment" ? "" : (moment(a.ref.job.start).format("HH:mm") +
						"–" +
						moment(a.ref.job.end).format("HH:mm"))}
				</div>
				<div className="name">
					{a.type === "deployment"
						? a.ref.project.name + ": " + a.ref.job.process.name 
						: a.ref.type.name}
				</div>
			</div>
			<div className="project">
				<div className="accept" />
				<div className="delete">
					{((b.type === "absence" && vacationRight) || (b.type !== "absence" && b.ref.job.id !== false))  ? (<ConfirmButton
						onClick={e => b.ref.delete()}
						trigger={
							<Button
								icon="trash alternate"
						
								compact
							/>
						}
					/>):null}
				</div>
				<div className="time">
					{b.type !== "deployment" ? "" : (moment(b.ref.job.start).format("HH:mm") +
						"–" +
						moment(b.ref.job.end).format("HH:mm"))}
				</div>
				<div className="name">
					{b.type === "deployment"
						? b.ref.project.name + ": " + b.ref.job.process.name
						: b.ref.type.name}
				</div>
			</div>
		</div>
	);
};

export default CollisionHandler;
