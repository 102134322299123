import React from "react";
import isequal from "lodash.isequal";
import uuid from "uuid";
import LZString from "lz-string";
import { inject } from "mobx-react";
import omit from "lodash.omit";
import merge from "lodash.merge";
import clonedeep from "lodash.clonedeep";
import md5 from "md5";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Tab, Segment, Header, Icon, Menu, Button } from "semantic-ui-react";
import EditBasedata from "./basedata";
import EditOrders from "./orders";
import EditDispo from "./disposition";
import EditPosition from "./position";
import _ from "lodash";


// Import Common Stylesheets

class ProjectEditView extends React.Component {
  constructor(props) {
    super(props);
    
    this.myRef = React.createRef();

    this.state = {
      hidden_form_value:'',
      formData: {},
      jobs: new Set(),
      marked: new Set(),
      lastRemerge: 0,
      matChooserForceOpen: false,
      validationErrors: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!isequal(state.marked, props.marked)){
      return {
        ...ProjectEditView.mergeData(props, true),
        marked: props.marked,
        lastRemerge: new Date().getTime()
      };
    }
    if (props.forceRemerge > state.lastRemerge)
      return {
        ...ProjectEditView.mergeData(props),
        marked: props.marked,
        lastRemerge: new Date().getTime(),
      };
    return null;
  }

  changePosition(latlng, zoom) {
    this.applyChanges({
      position: {
        lat: latlng.lat,
        lng: latlng.lng,
        zoom: zoom,
      },
    });
    this.props.edit.saveHistory();
  }

  changeDate(value) {
    //this function only does something, if only one job is selected (eg. in the JobEditModal)

    let currentJobs = this.state.jobs;

    if (currentJobs.size !== 1) return;

    const targetDay = value.startOf("day");

    this.props.edit.changeJobProperty(function(state) {
      let data = clonedeep(state.data);
      for (let cjob of currentJobs) {
        let [process, job] = cjob.split("#");
        if (
          !(process in data.processes) ||
          !(job in data.processes[process].jobs)
        )
          continue;

        const start = moment(data.processes[process].jobs[job].start);
        const end = moment(data.processes[process].jobs[job].end);

        const daydiff = targetDay.diff(moment(start).startOf("day"), "DAY");

        let change = {
          start: start.add(daydiff, "DAY").valueOf(),
          end: end.add(daydiff, "DAY").valueOf(),
        };
        data.processes[process].jobs[job] = {
          ...state.data.processes[process].jobs[job],
          updatedAt: new Date(),
          ...change,
        };
      }
      return { forceRemerge: new Date().getTime(), data: data };
    });
    this.props.edit.saveHistory();
  }

  changeTime(name, value) {
    //console.log("changeTime in index.js name, value", name, value);
    //console.log("this.state", this.state);

    let currentJobs = this.state.jobs;
    const formData = this.state.formData;
    this.props.edit.changeJobProperty(function(state) {
      let data = clonedeep(state.data);
      for (let cjob of currentJobs) {
        let [process, job] = cjob.split("#");
        if (
          !(process in data.processes) ||
          !(job in data.processes[process].jobs)
        )
          continue;
        let chvl;
        if (name === "start") {
          // sometimes this.state.formData.end or this.state.formDate.state is false
          //if (!this.state.formData.end)
          chvl = [
            value.split(":").map((x) => parseInt(x)),
            (formData.end === false
              ? moment(state.data.processes[process].jobs[job].end).format(
                  "HH:mm"
                )
              : formData.end
            )
              .split(":")
              .map((x) => parseInt(x)),
          ];
        } else if (name === "end") {
          chvl = [
            (formData.start === false
              ? moment(state.data.processes[process].jobs[job].start).format(
                  "HH:mm"
                )
              : formData.start
            )
              .split(":")
              .map((x) => parseInt(x)),
            value.split(":").map((x) => parseInt(x)),
          ];
        }
        let overnight = false;
        if (chvl[0][0] + chvl[0][1] / 60 > chvl[1][0] + chvl[1][1] / 60)
          overnight = true;

        //console.log(chvl,overnight);

        let xdat = moment(state.data.processes[process].jobs[job].start);
        xdat.set({
          hour: chvl[0][0],
          minute: chvl[0][1],
        });
        let start = xdat.valueOf();
        xdat.set({
          hour: chvl[1][0],
          minute: chvl[1][1],
        });
        if (overnight) xdat.add(1, "day");
        let end = xdat.valueOf();

        let change = {
          start: start,
          end: end,
        };
        data.processes[process].jobs[job] = {
          ...state.data.processes[process].jobs[job],
          updatedAt: new Date(),
          ...change,
        };
      }
      return { forceRemerge: new Date().getTime(), data: data };
    });
    this.props.edit.saveHistory();
  }

  changeValue(key, val) {
    this.applyChanges({
      [key]: val,
    });
  }

  resetOrderDispo(domain) {
    let currentJobs = this.state.jobs;
    this.props.edit.changeJobProperty(function(state) {
      let data = state.data;
      let domaindata = clonedeep(state[domain]);
      for (let cjob of currentJobs) {
        let [process, job] = cjob.split("#");
        if (
          !(process in data.processes) ||
          !(job in data.processes[process].jobs)
        )
          continue;
        for (let refid of data.processes[process].jobs[job][domain]) {
          if (!domaindata.has(refid)) continue;
          let blob = domaindata.get(refid);
          blob.deleted = true;
          blob.updatedAt = new Date();
          domaindata.set(refid, blob);
        }
      }
      return { forceRemerge: new Date().getTime(), [domain]: domaindata };
    });
    this.props.edit.saveHistory();
  }

  addOrderDispo(domain, forceOpenId = false, restMaterials = [], restSupplier = null) {
    let currentJobs = this.state.jobs;
    this.props.edit.changeJobProperty(function(state) {
      let data = clonedeep(state.data);
      let domaindata = clonedeep(state[domain]);
      let standard = false;
      if (domain === "disposition") {
        for (let cjob of currentJobs) {
          let [process, job] = cjob.split("#");
          if (
            !(process in data.processes) ||
            !(job in data.processes[process].jobs)
          )
            continue;
          for (let orderid of data.processes[process].jobs[job].orders) {
            if (!state.orders.has(orderid)) continue;
            const order = state.orders.get(orderid);
            if (order.deleted) continue;
            standard =
              standard === false || standard === order.supplier
                ? order.supplier
                : null;
          }
        }
      }

      standard = standard === false ? null : standard;
      let materials = [null];
      if (restMaterials.length > 0) materials = restMaterials;

      for (let cjob of currentJobs) {
        let [process, job] = cjob.split("#");
        if (
          !(process in data.processes) ||
          !(job in data.processes[process].jobs)
        )
          continue;
        for (let matid of materials) {
          const xid = uuid.v4();
          data.processes[process].jobs[job][domain].push(xid);
          domaindata.set(
            xid,
            domain === "disposition"
              ? {
                  id: xid,
                  job: job,
                  deleted: false,
                  type: null,
                  updatedAt: new Date(),
                  supplier: standard,
                  amount: 0,
                }
              : {
                  id: xid,
                  job: job,
                  updatedAt: new Date(),
                  deleted: false,
                  material: matid,
                  supplier: restSupplier,
                  amount: 0,
                  area: 0,
                  thickness: 0
                }
          );
        }
      }
      return {
        forceRemerge: new Date().getTime(),
        data: data,
        [domain]: domaindata,
      };
    });
    this.setState({ matChooserForceOpen: forceOpenId });
    this.props.edit.saveHistory();
  }


  static getValidationErrors(dispositions, allSuppliers, props){
    let validationErrors = [];
    let disp = Object.entries(dispositions).map(([key, x]) => {
      x.id = key;
      return x;
    });
    let dispGrouped = _.groupBy(disp, 'supplier');
    Object.entries(dispGrouped).map(([key, group]) => {
      let totalGroupCount = group.reduce((acc, value) => {
        return acc + (Number(value.amount) ?? 0);
      }, 0);
      let supplier = allSuppliers.find((supplier) => {
        return key === supplier[1].id;
      });
      if(supplier !== undefined && supplier.maxTruckAmount !== undefined && supplier.maxTruckAmount !== null){
        let message = "";
        if(totalGroupCount > supplier.maxTruckAmount){
          message = props.t("truckConflictText", {
            mixingPlant: supplier[1].name,
            number: supplier.maxTruckAmount,
          });
          validationErrors.push(message);
        }else if(totalGroupCount < supplier.maxTruckAmount){
          message = props.t("truckConflictLesserText", {
            mixingPlant: supplier[1].name,
            number: supplier.maxTruckAmount,
          });
          validationErrors.push(message);
        }
      }
      return "";
    });
    return validationErrors;
  }

  validateTrucksCountForSuppliers(dispositions, allSuppliers, jobs){
    let jd = this.props.edit.retrieveJobData(Array.from(jobs)[0]);
    if(jd[0] !== null && jd[0] !== undefined && !!jd[0].data.bpo.length){
      let validationErrors = ProjectEditView.getValidationErrors(dispositions, allSuppliers, this.props);
      this.setState({validationErrors: validationErrors});
      let errors = this.props.app.resources.trucksCountValidationErrors;
      let jobIds = Array.from(jobs);
      for (var id of jobIds) {
        errors[id] = validationErrors;
      }
      this.props.app.resources.updatevalidationErrors(errors)
    }
    

  }

  changeOrderDispo(domain, key, val, reference, number = false) {
    //console.log(domain, key, val, reference);
    this.props.edit.changeJobProperty(function(state) {
      let data = clonedeep(state[domain]);
      for (let refpath in reference) {
        // eslint-disable-next-line
        let [process, job, refid] = refpath.split("#");
        if (!data.has(refid)) continue;
        let blob = data.get(refid);
        if (key === false) {
          blob = { ...blob, ...val };
        } else {
          blob[key] = val;
        }
        blob.updatedAt = new Date();
        data.set(refid, blob);
      }
      //console.log(data);
      return { forceRemerge: new Date().getTime(), [domain]: data };
    });
    if (key === "supplier") {
      this.props.edit.addSupplier(val);
    } else if (key === false && "supplier" in val) {
      this.props.edit.addSupplier(val.supplier);
    }
  }

  openTP() {
    //console.log(this.state.formData.bpo, this.state.jobs.values().next().value);
    if (this.state.formData.bpo.length) {
      let compressed = "";
      const bpdata = JSON.stringify({
        bpokey: this.state.formData.bpo,
        assertUser: localStorage.getItem("binfra_username"),
        project: this.props.edit.state.pid, //project uuid
      });
      try {
        compressed = LZString.compressToEncodedURIComponent(bpdata);
      } catch (e) {
        compressed = Buffer.from(bpdata).toString("base64");
      }

      this.setState({
        'hidden_form_value': compressed
      });
      
      // console.log(compressed,'ifstate',this.myRef);
      setTimeout(() => {
        this.myRef.current.click();
        this.myRef.current.disabled = true;
      }, 1000);

      // window.location = ""+process.env.REACT_APP_BPO_URL+"/?chosen&infra=" + compressed;
    } else {
      const [xprocess, job] = this.state.jobs
        .values()
        .next()
        .value.split("#");
      try {
        const jobdata = this.props.edit.state.data.processes[xprocess].jobs[job];
        const processes = [];
        for (let xprocess of Object.values(
          this.props.edit.state.data.processes
        )) {
          for (let job of Object.values(xprocess.jobs)) {
            if (!!job.bpo) continue;
            processes.push({
              id: job.id,
              name: xprocess.name,
              date: moment(job.start)
                .startOf("day")
                .format("YYYY-MM-DD"),
            });
          }
        }
        let compressed = "";

        let bstn = "";

        try {
          bstn = this.props.edit.state.data.processes[xprocess].bstn;
        } catch (e){
          bstn = "";
        }

        if (bstn === "") bstn = this.props.edit.state.data.bstn;

        const bpdata = JSON.stringify({
          bstn,
          kostenstelle: this.props.edit.state.data.costbase,
          materials: jobdata.orders
            .map((x) => {
              const order = this.props.edit.state.orders.has(x)
                ? this.props.edit.state.orders.get(x)
                : false;
              if (!order || order.deleted) return false;
              const material = this.props.app.basedata.materials.has(
                order.material
              )
                ? this.props.app.basedata.materials.get(order.material)
                : false;
              const supplier = this.props.app.basedata.suppliers.has(
                order.supplier
              )
                ? this.props.app.basedata.suppliers.get(order.supplier)
                : false;
              if (order.amount < 1 || (!material && !supplier)) return false;
              return {
                amount: order.amount,
                material: material.name,
                supplier: supplier.name,
              };
            })
            .filter((x) => x),
          contacts: Object.values(this.props.edit.state.data.contacts)
            .map((x) => {
              const person = this.props.app.basedata.persons.has(x.person)
                ? this.props.app.basedata.persons.get(x.person)
                : false;
              if (!person || person.deleted || x.deleted) return false;
              return {
                username: person.username,
                name: person.name,
                function: x.function,
                type: person.type,
              };
            })
            .filter((x) => x),
          name: this.props.edit.state.data.name,
          position:
            jobdata.position.lat !== 0
              ? jobdata.position
              : this.props.edit.state.data.position,
          uuid: job,
          processes,
          username: this.props.edit.state.data.username,
          start: moment(jobdata.start).format("YYYY-MM-DD HH:mm"), //date
          project: this.props.edit.state.pid, //project uuid
          assertUser: localStorage.getItem("binfra_username"),
        });
        try {
          compressed = LZString.compressToEncodedURIComponent(bpdata);
        } catch (e) {
          compressed = Buffer.from(bpdata).toString("base64");
        }

        this.setState({
          'hidden_form_value': compressed
        });
        // console.log(compressed,'elsestate',this.myRef);
        setTimeout(() => {
          this.myRef.current.click();
          this.myRef.current.disabled = true;
        }, 1000);

        // window.location = ""+process.env.REACT_APP_BPO_URL+"/?chosen&infra=" + compressed;
      } catch (e) {
        console.log(e);
      }
    }
  }

  applyChanges(change) {
    let currentJobs = this.state.jobs;
    this.props.edit.changeJobProperty(function(state) {
      let data = clonedeep(state.data);
      for (let cjob of currentJobs) {
        let [process, job] = cjob.split("#");
        if (
          !(process in data.processes) ||
          !(job in data.processes[process].jobs)
        )
          continue;
        data.processes[process].jobs[job] = {
          ...state.data.processes[process].jobs[job],
          updatedAt: new Date(),
          ...change,
        };
      }
      return { forceRemerge: new Date().getTime(), data: data };
    });
  }

  static mergeData(props, dateChanged = false) {
    //console.log("do merge");
    let result = false;
    const jobs = new Set();
    for (let c of props.marked) {
      let jd = props.edit.retrieveJobData(c);
      for (let { id: jobId, data: dayWithReferences } of jd) {
        let day = ProjectEditView.unpackOrderDispo(
          props,
          dayWithReferences,
          jobId
        );
        jobs.add(jobId);
        if (result === false) {
          result = day;
          continue;
        }
        result = ProjectEditView.mergeDay(result, day);
      }
    }
    if(dateChanged && Array.from(props.marked).length > 0){
      let jd = props.edit.retrieveJobData(Array.from(props.marked)[0]);
      let allSuppliers = ProjectEditView.calculateMaxTrucksCount(props, result);
	
      if(jd[0] !== null && jd[0] !== undefined && !!jd[0].data.bpo.length){
        return { formData: result, jobs: jobs, validationErrors: ProjectEditView.getValidationErrors(result.disposition.data, allSuppliers, props) };
      }else{
        return { formData: result, jobs: jobs, validationErrors: [] };
      }
    }else{
      return { formData: result, jobs: jobs };
    }
    
  }

  static mergeDay(a, b) {
    const strategies = {
      //all others are merged by value
      disposition: "OBJECT_KEY",
      orders: "OBJECT_KEY",
      bpo: "BOOL_ONE",
      position: "OBJECT_VALUE",
    };

    for (let key in a) {
      if (!(key in strategies)) {
        if (!a[key]) continue;
        a[key] = a[key] === b[key] ? a[key] : false;
      } else if (strategies[key] === "OBJECT_KEY") {
        if (
          a[key].mergeable &&
          !isequal(Object.keys(a[key].data), Object.keys(b[key].data))
        ) {
          // a[key].mergeable = false;
        }
        //merge a[key].data with b[key].data
        merge(a[key].data, b[key].data);
      } else if (strategies[key] === "OBJECT_VALUE") {
        if (!a[key]) continue;
        a[key] = isequal(Object.values(a[key]), Object.values(b[key]))
          ? a[key]
          : false;
      } else if (strategies[key] === "BOOL_ONE") {
        //value is true if one of the values is trueish
        a[key] = a[key] || b[key];
      } else if (strategies[key] === "BOOL_ALL") {
        //value is true if all of the values are trueish
        a[key] = a[key] && b[key];
      }
    }

    return a;
  }

  static calculateMaxTrucksCount(props, formData){
    const truckRequirements = formData.truck_requirements === false ? [] : formData.truck_requirements ?? [];
    let allSuppliers = Array.from(props.app.basedata['suppliers']);
    allSuppliers = allSuppliers.map(supplier => {
      if(truckRequirements.length > 0){
        let requirement = truckRequirements.find((value) => {
          return value.plant === supplier[1].name;
        });
        if(requirement !== null && requirement !== undefined){
          supplier.maxTruckAmount = requirement.count;
        }
      }
      return supplier;
    });

    return allSuppliers;

  }

  resetForceOpen() {
    this.setState({ matChooserForceOpen: false });
  }

  static unpackOrderDispo(props, d, globalId) {
    const settings = {
      orders: props.edit.state.orders,
      disposition: props.edit.state.disposition,
    };

    let out = omit({ ...d }, Object.keys(settings));

    for (let key in settings) {
      const source = settings[key];
      const nD = { mergeable: true, data: {} };
      let i = 0;
      let sorted_keys = [];
      if (!source) {
        sorted_keys = Object.keys(d[key]);
        //sorted_keys.sort(); we expect the object to retain its order, even though its not in the manual
      }
      for (let dId of source ? d[key] : sorted_keys) {
        let fx;
        if (source) {
          if (!source.has(dId)) continue;
          fx = source.get(dId);
        } else {
          fx = d[key][dId];
        }
        if (fx.deleted) continue;
        let xkey = md5(
          Object.values(omit(fx, ["deleted", "id", "job", "updatedAt"])).join(
            "#"
          )
        );
        while (xkey in nD.data) xkey += "0";
        nD.data[xkey] = {
          ...omit(fx, ["deleted", "job", "id", "updatedAt"]),
          key: key + i,
          reference: { [globalId + "#" + fx.id]: true },
        };
        i++;
      }
      out[key] = nD;
    }

    return out;
  }

  render() {
    if (!this.state.formData)
      return (
        <Segment placeholder>
          <Header icon>
            <Icon name="calendar plus outline" />
            {this.props.t("edit.choosedays")}
          </Header>
        </Segment>
      );
    //console.log(this.props.edit.state.activeIndex);
    const panes = [
      {
        menuItem: {
          key: "basedata",
          icon: "book",
          content: this.props.t("edit.panes.basedata"),
        },
        render: () => (
          <EditBasedata
            global={false}
            metacom={this.props.app.ui.modules.has("SITE_SELECTOR")}
            edit={this.props.edit}
            data={this}
            companyName={this.props.companyName}
          />
        ),
      },
      /*{
        menuItem: {
          key: "contacts",
          icon: "users",
          content: this.props.t("edit.panes.contacts")
        },
        render: () => <EditContacts edit={this.props.edit} data={this} />
      },*/
      {
        menuItem: {
          key: "orders",
          icon: "cubes",
          content: this.props.t("edit.panes.orders"),
        },
        render: () => <EditOrders extOrderData={this.props.app.ui.modules.has("EXT_ORDER_DATA")} edit={this.props.edit} data={this} />,
      },
      {
        menuItem: {
          key: "dispo",
          icon: "truck",
          content: this.props.t("edit.panes.dispo"),
        },
        render: () => <EditDispo edit={this.props.edit} data={this} />,
      },
      /*{
        menuItem: {
          key: "requests",
          icon: "picon picon-excavator",
          content: this.props.t("edit.panes.requests")
        },
        render: () => <EditRequests edit={this.props.edit} data={this} />
      }, //REQUESTS are now changed somewhere else */
      {
        menuItem: {
          key: "position",
          icon: "map marker alternate",
          content: this.props.t("edit.panes.position"),
        },
        render: () => (
          <EditPosition global={false} edit={this.props.edit} data={this} />
        ),
      },
      /* {
        menuItem: {
          key: "lite",
          icon: "road",
          content: this.props.t("edit.panes.lite")
        },
        render: () => <EditLite edit={this.props.edit} data={this} />
      }, */
      window.bpo
        ? {
            menuItem: (
              <Menu.Item
                key="add"
                position="right"
                onClick={(_) =>
                  !this.props.app.ui.saveNecessary() &&
                  this.props.edit.state.neverChanged
                    ? this.openTP()
                    : this.props.app.ui.openMessage("edit.saveFirst")
                }
              >
                <Button
                  content={
                    this.state.formData.bpo.length
                      ? this.props.t("edit.bpo.openTP")
                      : this.props.t("edit.bpo.createTP")
                  }
                />
                <form style={{display:'none'}} action={process.env.REACT_APP_BPO_URL+"/?chosen"} method="POST">
        				  <textarea name="infra" value={this.state.hidden_form_value} />
                  <input name="PHPSESSID" value={localStorage.getItem("binfra_phpsessid")} />
        				  <button type="submit" ref={this.myRef} >
        				    Submit
        				  </button>
        				</form>
              </Menu.Item>
            ),
          }
        : null,
    ];
    
    return (
      <Tab
        activeIndex={this.props.edit.state.activeIndex}
        onTabChange={this.props.edit.handleTabChange}
        className="editTabs"
        menu={
          "secondary" in this.props ? { secondary: true } : { attached: "top" }
        }
        panes={panes}
      />
    );
  }
}

export default inject("app")(withTranslation()(ProjectEditView));
