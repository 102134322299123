import React from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import { strToDate } from "../../../../helpers/calendar";
import { withTranslation } from "react-i18next";
import md5 from "md5";
import kmeans from "ml-kmeans";
import ClusterBox from "./clusterBox";
import Color from "color";

/* This component renders the detail view of one resource. 
It clusters the deployments into time buckets and displays them on the board.  */

class ResourceDetailLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			signature: "",
			deployments: null,
			cluster: null,
			clusterList: new Map(),
			deploymentsSignature: "",
			dragMode: false,
		};
	}

	setDragMode(dragMode, key) {
		this.setState({ dragMode: dragMode ? key : false });
	}

	static clusterCount = 3; //number of time buckets
	static minimumClusterHours = 4; //minimum size of a bucket in hours. If the clustering results in a smaller bucket the bucket is merged

	static getDerivedStateFromProps(props, state) {
		//the deployment data is retrieved with every update, as we cannot know what changed in the whole tree
		const deployments = props.resource.getDeploymentsByProject(
			props.app.ui.view, true
		);

		//we create a sorted list of all start and end times for the whole resource in the current view
		const [startList, deploymentsSignature] = ResourceDetailLine.getStartList(
			deployments
		);

		if (state.deploymentsSignature === deploymentsSignature) {
			return null;
		}

		//this start list is hashed to get a signature
		const signature = md5(startList.join(","));
		//if the signature changed compared to the last time we have to recluster
		let cluster;
		if (state.signature !== signature) {
			//kmeans cluster the times to get the best positions for the lines between rows
			if (!startList.length) startList.push(0, 24);
			cluster = kmeans(
				startList.map((x) => [x]),
				Math.min(startList.length, ResourceDetailLine.clusterCount + 1)
			);
			//sort the clusters by time
			cluster.centroids.sort((a, b) => a.centroid[0] - b.centroid[0]);
			//delete the training data
			delete cluster.clusters;

			//check if the bucket size is not smaller than the minimumClusterHours
			for (let i = 1; i < cluster.centroids.length; i++) {
				if (
					cluster.centroids[i].centroid[0] -
						cluster.centroids[i - 1].centroid[0] >
					ResourceDetailLine.minimumClusterHours || cluster.centroids.length === 2
				)
					continue;
				//if it is smaller remove the last cluster and set the new centroid to the average
				cluster.centroids[i].centroid[0] =
					(cluster.centroids[i].centroid[0] +
						cluster.centroids[i - 1].centroid[0]) /
					2;
				cluster.centroids.splice(i - 1, 1);
				//in case we deleted a centroid the array lost one element
				//so the incrementing variable has to go back one step to get to the next element
				i--;
			}
		} else {
			cluster = state.cluster;
		}

		//we export usedClusters as an object to know how many boxes there are for a certain cluster at a certain date
		const usedClusters = {};
		//we export clusterList containing the best cluster for a box, so we dont have to calculate it in render()
		const clusterList = new Map();

		const clusterCount = cluster.centroids.length;


		//iterating through all dates and projects

		for (let key in deployments) {
			for (let [p, data] of deployments[key]) {
				//get minimum start and maximum end of all jobs in the project at the day
				const xs = moment(
					data.reduce(
						(p, v) =>
							p < ("job" in v ? v.job.start : v.start)
								? p
								: "job" in v
								? v.job.start
								: v.start,
						Infinity
					)
				);
				const xe = moment(
					data.reduce(
						(p, v) =>
							p > ("job" in v ? v.job.end : v.end)
								? p
								: "job" in v
								? v.job.end
								: v.end,
						-Infinity
					)
				);

				//get the best centroid for start and end of the box
				const startHour = xs.hour() + xs.minute() / 60;
				const endHour = xe.hour() + xe.minute() / 60;
				let startCluster = cluster.nearest([[startHour]])[0];
				let endCluster = cluster.nearest([
					[endHour > startHour ? endHour : 24],
				])[0];

				

				//the last cluster is the end of the line, so a box cannot start there
				if (startCluster === clusterCount - 1) startCluster -= 1;
				//ensure a minimum box size of one
				if (endCluster - startCluster < 1) endCluster = startCluster + 1;

				//save in clusterList and usedClusters
				clusterList.set(key + "#" + p, [
					startCluster,
					endCluster - startCluster,
				]);
				if (!(startCluster in usedClusters)) usedClusters[startCluster] = {};
				if (!(key in usedClusters[startCluster]))
					usedClusters[startCluster][key] = 0;
				usedClusters[startCluster][key]++;
			}
		}

		return {
			clusterList,
			signature,
			cluster,
			deployments,
			usedClusters,
			deploymentsSignature,
		};
	}

	//function to retrieve the left position of a box in the timeline for a given date
	static getX(props, date) {
		return (
			((date.getTime() - props.app.ui.view.start.getTime()) /
				props.app.ui.calDuration) *
			props.xwidth
		);
	}

	//retrieves a sorted list of start and end times of projects to use for clustering
	static getStartList(deployments) {
		const startList = [];
		const ds = [];
		//iterate through all dates and projects
		for (let key in deployments) {
			for (let [p, data] of deployments[key]) {
				ds.push(p);
				if (p.startsWith("absence")) continue;
				//get minimum start and maximum end of all jobs in the project at the day
				const xs = moment(
					data.reduce(
						(p, v) =>
							p < ("job" in v ? v.job.start : v.start)
								? p
								: "job" in v
								? v.job.start
								: v.start,
						Infinity
					)
				);
				const xe = moment(
					data.reduce(
						(p, v) =>
							p > ("job" in v ? v.job.end : v.end)
								? p
								: "job" in v
								? v.job.end
								: v.end,
						-Infinity
					)
				);
				const startHour = xs.hour() + xs.minute() / 60;
				const endHour = xe.hour() + xe.minute() / 60;
				//if endHour is on next day set endHour to midnight
				startList.push(startHour, endHour > startHour ? endHour : 24);
			}
		}
		startList.sort((a, b) => a - b);
		ds.sort();
		return [startList, md5(ds)];
	}

	dateCheck(from, to, check) {

		var fDate,lDate,cDate;
		fDate = Date.parse(from);
		lDate = Date.parse(to);
		cDate = Date.parse(check);
	
		if((cDate <= lDate && cDate >= fDate)) {
			return true;
		}
		return false;
	}

	render() {
		//get resource data
		const r = this.props.resource;

		const calStart = moment(this.props.app.ui.calStart).format("YYYY-MM-DD");
		const calEnd = moment(this.props.app.ui.calEnd).format("YYYY-MM-DD");



		//set render constants
		const width =
			((this.props.app.ui.view.end - this.props.app.ui.view.start) /
				this.props.app.ui.calDuration) *
			this.props.xwidth;
		const lineHeight = 101;
		const padding = 0;
		const boxHeight = lineHeight - 2 * padding;

		const clusters = {};

		//get amount of needed subClusters per cluster
		//only one box can exist in one cell. if on a certain date there should be two boxes for one cell the cluster must be duplicated, creating subclusters.
		const subClusterCount = {};
		let lineCount = 0;
		for (let clusterId in this.state.usedClusters) {
			subClusterCount[clusterId] = Object.values(
				this.state.usedClusters[clusterId]
			).reduce((p, v) => (p > v ? p : v), -Infinity);
			lineCount += subClusterCount[clusterId];
		}

		/*
		Removed this on 25th of August 2021, maybe it is actually needed to force mobx to observe??

		const deployments = this.props.resource.getDeploymentsByProject(
			this.props.app.ui.view
		);
		
		*/
		
		//iterate through all dates
		for (let key in this.state.deployments) {
			//usedSubClusters tracks how many subcells are already used
			let usedSubClusters = {};
			let qrx = 1;
			//iterate through all projects of this day
			if (typeof this.state.deployments[key] === "undefined") continue;
			for (let [p, data] of this.state.deployments[key]) {
				try {
					//get cluster and size from clusterList
					const [start, size] = this.state.clusterList.get(key + "#" + p);

					if (!(start in usedSubClusters)) usedSubClusters[start] = 0;

					//get box position and size
					let left = ResourceDetailLine.getX(this.props, strToDate(key));
					let boxwidth =
						ResourceDetailLine.getX(this.props, strToDate(key, 1)) - left;

					//initially the box fills exactly one cell
					let height = 1;
					let xstart = start;
					let xcluster = usedSubClusters[start];

					
					//console.log(xcluster);
					//console.log(xstart, xcluster, height);
					//if the box is the last box in the cluster we can fill the rest of the subcluster if there are any
					if (
						this.state.usedClusters[start][key] <=
						usedSubClusters[start] + 1
					) {
						height = subClusterCount[start] - usedSubClusters[start];
						xstart = start;
						xcluster = subClusterCount[start] - 1;
						//console.log(xcluster);
						//console.log("fill to", height, xcluster);
					}
				

					//if the box should normally end in another cluster, we can check if this cluster is free and increase the box size accordingly

					if (
						start in this.state.usedClusters &&
						key in this.state.usedClusters[start] &&
						this.state.usedClusters[start][key] <= qrx
					) {
						for (let expand = 1; expand < size; expand++) {
							if (
								start + expand in this.state.usedClusters &&
								key in this.state.usedClusters[start + expand]
							)
								break;

							if (!(start + expand in subClusterCount)) break;

							height +=
								start + expand in subClusterCount
									? subClusterCount[start + expand]
									: 1;
							xstart = start + expand;
							xcluster = subClusterCount[start + expand] - 1;

							//console.log(subClusterCount, start, expand, size, xcluster);
							//console.log("expand to", height, xstart, xcluster);
						}
					}

					

					//clusters contains the box components
					if (!(xstart + "." + xcluster in clusters))
						clusters[xstart + "." + xcluster] = [];

					if (p === "UNKNOWN" || p.startsWith("absence")) {
						clusters[xstart + "." + xcluster].push(
							<div
								key={p + "-" + key}
								className={"cluster_box_wrapper " +
								(this.dateCheck(calStart, calEnd, key) ? " cluster_box_active" :" cluster_box_inactive")}
								style={{
									cursor: "pointer",
									position: "absolute",
									left: left + 1,
									width: boxwidth - 1,
									height: height * boxHeight - 1,
									bottom: 0,
								}}
							>
								<div
									className="cluster_box absence_cluster_box xls_box"
									data-start={key}
									data-size={height}
									style={{
										background: p === "UNKNOWN" ? "white" : data[0].type.color,
										color: Color(p === "UNKNOWN" ? "white" :data[0].type.color).isLight()
											? "black"
											: "white",
									}}
								>
									<div className="itemBoxCenter xls_title">{p === "UNKNOWN" ? data[0].project.name : data[0].type.name}</div>
								</div>
							</div>
						);
					} else {
						clusters[xstart + "." + xcluster].push(
							<ClusterBox
								key={p + "-" + key}
								deployments={data}
								size={height * boxHeight}
								boxHeight={boxHeight}
								resid={r.id}
								xsize={height}
								date={key}
								left={left}
								setDragMode={(x) =>
									this.setDragMode(x, xstart + "-" + xcluster)
								}
								width={boxwidth}
								startJobEditModal={this.props.app.ui.startJobEditModal}
								startResourceEditor={this.props.app.ui.startResourceEditor}
								startJobChooserModal={this.props.app.ui.startJobChooserModal}
							/>
						);
					}
					qrx++;
					//set the cell as used
					usedSubClusters[start]++;
				} catch (e) {}
			}
		}

		const rows = [];


	

		for (
			let clusterId = 0;
			clusterId < this.state.cluster.centroids.length - 1;
			clusterId++
		) {
			const lastLine =
				clusterId + 1 === this.state.cluster.centroids.length - 1;
			for (
				let subClusterId = 0;
				subClusterId < subClusterCount[clusterId];
				subClusterId++
			) {
				const lastSubLine = subClusterId + 1 === subClusterCount[clusterId];
				rows.push(
					<tr
						key={"tr" + r.id + "-" + clusterId + "-" + subClusterId}
						className={"resource_main clustered xls_row"}
					>
						{clusterId === 0 && subClusterId === 0 ? (
							<td
								rowSpan={lineCount}
								className="resource_head sticky"
								style={{
									cursor: "pointer",
									left: 0,
									zIndex: 30,
									verticalAlign: "top",
								}}
							>
								<div
									className="resource_dl_wrapper"
									style={{
										height: lineHeight * lineCount,
									}}
								>
									<div
										className="background"
										style={{
											backgroundImage: "url(" + r.imageUrl + ")",
										}}
									/>
									<div className="txt xls_resname">{r.name}</div>
								</div>
							</td>
						) : null}
						<td>
							<div
								data-resource={r.id}
								className={
									"resource_body xls_datarow detaildroptarget" +
									(!lastSubLine
										? " hiddenline"
										: !lastLine
										? " clusterborder"
										: "")
								}
								style={{
									zIndex:
										this.state.dragMode &&
										this.state.dragMode === clusterId + "-" + subClusterId
											? 20
											: 2,
									width: width,
									height: lineHeight,
									position: "relative",
								}}
							>
								{clusterId + "." + subClusterId in clusters
									? clusters[clusterId + "." + subClusterId]
									: null}
							</div>
						</td>
					</tr>
				);
			}
		}

		return <React.Fragment>{rows}</React.Fragment>;
	}
}

export default inject("app")(withTranslation()(observer(ResourceDetailLine)));
