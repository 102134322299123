import React from "react";
import { strToDate } from "../../../helpers/calendar";

function getX(start, calDuration, xwidth, date) {
	return ((date.getTime() - start.getTime()) / calDuration) * xwidth;
}

const TextInterval = props => {
	const start = getX(
		props.viewStart,
		props.calDuration,
		props.xwidth,
		strToDate(props.start)
	);

	const end = getX(
		props.viewStart,
		props.calDuration,
		props.xwidth,
		strToDate(props.end,1)
	);

	return (
		<div
			className={"restext_box"}
			style={{
				display: props.hide ? "none" : "block",
				pointerEvents: "none",
				position: "absolute",
				left: start + 1,
				width: end - start - 1,
				height: props.boxHeight - 1,
				top: 0
			}}
		>
			{props.text}
		</div>
	);
};

export default TextInterval;
