import React from "react";
import {Statistic} from "semantic-ui-react";

class Upcounter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        };
        this.timeOut = false;
    }


    componentDidMount() {
        this.startCount();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value)
            this.startCount();
    }

    startCount(){
        if (this.timeOut)
            clearTimeout(this.timeOut);
        let speed = ("speed" in this.props) ? this.props.speed : 4000;
        const diff = Math.abs(this.props.value-this.state.value);
        if (diff < 10) speed /= 2;
        if (diff === 0)
            return;
        const start = this.state.value;
        const baseInterval = Math.max(20,Math.floor(speed/diff));
        let step = Math.floor((this.props.value-this.state.value)/(speed/baseInterval));
        if (diff < 0 && step === 0) step = -1;
        else if (step === 0) step = 1;

        const fn = ease => {
          this.timeOut = setTimeout(x => {
              this.setState(state => {
                  const value = step < 0 ? Math.max(this.props.value, state.value+step) : Math.min(this.props.value, state.value+step);
                  if (value !== this.props.value)
                      fn(this.easing(Math.abs(value-start)/diff));
                  return {value};
              })
          },Math.floor(ease * baseInterval));
        };
        fn(this.easing(0));
    }

    easing(percentage){
        const x = 0.7;
        const y = 0.8;
        const fx = percentage*y+((1-y)/2);
        return x*Math.cos(fx*2*Math.PI)+1;
    }

    render() {
        return (<Statistic>
            <Statistic.Value>{this.state.value+(("suffix" in this.props) ? (""+this.props.suffix) : "")}</Statistic.Value>
            <Statistic.Label>{this.props.description}</Statistic.Label>
        </Statistic>);
    }
}

export default Upcounter;
