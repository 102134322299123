import React from "react";
import Color from "color";
import { Modal, Menu, Icon } from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";

class JobChooser extends React.Component {
	constructor(props) {
		super(props);
		this.close = this.close.bind(this);
		this.choose = this.choose.bind(this);
	}

	choose() {}

	close() {
		this.props.app.ui.closeJobChooserModal();
	}

	toggle(selection) {
		this.setState(prevState => {
			return {
				selected: prevState.selected === selection ? "" : selection
			};
		});
	}

	render() {
		if (!this.props.app.ui.jobChooserModal) return null;
		const { jobs, callback } = this.props.app.ui.jobChooserModal;
		return (
			<Modal
				centered={false}
				className="jobChooserModal"
				open={this.props.app.ui.jobChooserModal !== false}
				onClose={this.close}
			>
				<Menu>
					<Menu.Item header>
						{this.props.t("jobChooser.heading")}
					</Menu.Item>
					<Menu.Menu position="right">
						<Menu.Item name="close it" onClick={this.close}>
							<Icon name="close" />
						</Menu.Item>
					</Menu.Menu>
				</Menu>

				<Modal.Content>
					<div className="jobChooserWrapper">
						{jobs.map(x => {
							const template = x.process.template;
							const color = Color(template.color);
							return (
								<div
									key={x.id}
									className="processItem"
									onClick={() => callback(x)}
								>
									<div
										className={
											"processItemBox" +
											(template.icon
												? " picon picon-" +
												  template.icon
												: "")
										}
										style={{
											color:
												"rgba(" +
												(color.isLight()
													? "0,0,0"
													: "255,255,255") +
												",0.75)",
											backgroundColor: template.color
										}}
									>
										{template.icon
											? ""
											: x.process.name
													.substr(0, 1)
													.toUpperCase()}
									</div>
									<div className="processItemName">
										{template.name !== x.process.name
											? template.name +
											  ": " +
											  x.process.name
											: x.process.name}
									</div>
								</div>
							);
						})}
					</div>
				</Modal.Content>
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(JobChooser)));
