import React, { Suspense } from "react";
import { render } from "react-dom";
import moment from "moment";
import { BrowserRouter } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { Provider } from "mobx-react";
import "react-dates/initialize";
import { types } from "mobx-state-tree";

// Import our Stores Here

/*
import {ProjectStore, OrderStore, ResourcesStore, BaseDataStore} from "./stores/all";
import UIStore from "./stores/ui";
*/

import ProjectStore from "./stores/projects";
import OrderStore from "./stores/orders";
import UIStore from "./stores/ui";
import ResourcesStore from "./stores/resources";
import BaseDataStore from "./stores/basedata";
import QuestionStore from "./stores/questions";

// Import Components
import App from "./containers/app";

import "./i18n";

import "./stylesheets/reset.css";
import "semantic-ui-css/semantic.min.css";
import "leaflet/dist/leaflet.css";
import "react-dates/lib/css/_datepicker.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "./stylesheets/fontello.css";
import "./stylesheets/leaflet.awesome-markers.css";
import "./stylesheets/style.css";

import "./helpers/leaflet.js";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
   dsn:
      "https://221aea18db4745859c67575148d2b9a9@o573458.ingest.sentry.io/5725995",
   integrations: [new Integrations.BrowserTracing()],

   // Set tracesSampleRate to 1.0 to capture 100%
   // of transactions for performance monitoring.
   // We recommend adjusting this value in production
   tracesSampleRate: 0.2,
});

window.bpo = false;
try {
   const infraParam = JSON.parse(
      atob(new URLSearchParams(window.location.search).get("infra"))
   );
   
   if (infraParam.token.length) {
      window.bpo = true;
      localStorage.setItem("binfra_username", infraParam.username);
      localStorage.setItem("binfra_token", infraParam.token);
      if(infraParam.phpsessid){
         localStorage.setItem("binfra_phpsessid", infraParam.phpsessid);
      }
      try {
         Sentry.configureScope(function(scope) {
            scope.setUser({ username: infraParam.username });
         });
      } catch (e) {
         console.log("SENTRY ERROR");
      }
   }
} catch (e) {
   console.log("NO BPO SSO KEY");
}

// Because they're classes, we have to instantiate them here :)

const store = types.model("app", {
   ui: UIStore,
   orders: OrderStore,
   projects: ProjectStore,
   basedata: BaseDataStore,
   resources: ResourcesStore,
   questions: QuestionStore,
});

const rootStore = store.create({
   ui: {
      calStart: moment()
         .startOf("day")
         .valueOf(),
      calDuration: 21 * 24 * 60 * 60 * 1000,
      calScrollNecessary: true,
      calSnap: "day",
      fullscreen:
         true ||
         (window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth) < 1130,
   },
   orders: {},
   projects: {},
   resources: {},
   basedata: {},
   questions: {},
});

const router = (
   <Provider app={rootStore}>
      <BrowserRouter>
         <Suspense
            fallback={
               <Dimmer active inverted>
                  <Loader size="large" />
               </Dimmer>
            }
         >
            <App />
         </Suspense>
      </BrowserRouter>
   </Provider>
);

render(router, document.getElementById("root"));
