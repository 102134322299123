import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import moment from "moment";
import "moment/locale/de";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/ro";
import "moment/locale/da";

const whitelist = ["de", "en", "nl","fr","ro","da"];

// Locale path differs between production and dev
const origin = window.location.origin || "https://bpo-infra.de";
let backend = { loadPath: `${origin}/locales/{{lng}}/{{ns}}.json` };
if (process.env.NODE_ENV === "development")
  backend = { loadPath: "/locales/{{lng}}/{{ns}}.json" };

let language = undefined;

try {
  const params = new URLSearchParams(window.location.search);
  let getlang = params.get("lang").split("-")[0];
  if (getlang.length) {
    if (getlang === "dk") getlang = "da";
    if (whitelist.includes(getlang)) {
      language = getlang;
    } else {
      language = "en";
    }
  }
} catch (e) {}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: backend,
    lng: language,
    whitelist: whitelist, // all whitelisted languages must also be imported for moment!
    supportedLngs: whitelist,
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

i18n.on("languageChanged", function(lng) {
  moment.locale(lng);
});

export default i18n;
