import React from "react";
import {
	Modal,
	Button,
	Menu,
	Icon,
	Segment,
	Message,
	Checkbox
} from "semantic-ui-react";
import arrayMove from "array-move";
import { SortableContainer } from "react-sortable-hoc";
import Question from "./Question";
import { isValidReference, getSnapshot } from "mobx-state-tree";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import uuid from "uuid";

const QuestionContainer = SortableContainer(
	({
		items,
		activeQuestion,
		setActive,
		onChange,
		selectOptionHandler,
		resclasses,
		classConstraints,
	}) => (
		<div className="questionContainer">
			{items.map((x, index) =>
				x.deleted ? null : (
					<Question
						key={`item-${x.id}`}
						active={activeQuestion === x.id}
						setActive={setActive}
						selectOptionHandler={selectOptionHandler}
						onChange={onChange}
						index={index}
						index2={index}
						data={x}
						resclasses={resclasses}
						classConstraints={classConstraints}
					/>
				)
			)}
		</div>
	)
);

class QuestionaryModal extends React.Component {
	constructor(props) {
		super(props);
		this.close = this.close.bind(this);
		this.open = this.open.bind(this);
		this.save = this.save.bind(this);
		this.onChange = this.onChange.bind(this);
		this.selectOptionHandler = this.selectOptionHandler.bind(this);
		this.state = {
			open: false,
			questions: [],
			activeQuestion: "",
			signatureEnabled: false
		};
	}

	onChange(index, attribute, value, property = false) {
		this.setState((x) => {
			const questions = x.questions.slice();
			if (property) {
				questions[index][attribute][property] = value;
			} else {
				questions[index][attribute] = value;
			}
			questions[index].updatedAt = new Date();
			return { questions };
		});
	}

	selectOptionHandler(index, procedure, option, value = "") {
		this.setState((x) => {
			const questions = x.questions.slice();
			questions[index].updatedAt = new Date();
			if (!Array.isArray(questions[index].properties.selectOptions))
				questions[index].properties.selectOptions = [];
			switch (procedure) {
				case "add":
					questions[index].properties.selectOptions.push("");
					break;
				case "change":
					questions[index].properties.selectOptions[option] = value;
					break;
				case "remove":
					questions[index].properties.selectOptions.splice(option, 1);
					break;
				case "addSupervisor":
					questions[index]._supervisors.push("");
					break;
				case "changeSupervisor":
					questions[index]._supervisors[option] = value;
					break;
				case "removeSupervisor":
					questions[index]._supervisors.splice(option, 1);
					break;
				case "addResClass":
					questions[index]._resclasses.push("");
					break;
				case "changeResClass":
					questions[index]._resclasses[option] = value;
					break;
				case "removeResClass":
					questions[index]._resclasses.splice(option, 1);
					break;
				default:
					break;
			}

			return { questions };
		});
	}

	enableSignatureField(enable) {
		const questions = this.state.questions.slice();
		const signatureFieldIndex = questions.findIndex(question => question.type === "SIGNATURE");
		if(signatureFieldIndex === -1){
			this.setState((x) => {
				const questions = x.questions.slice();
				const id = uuid.v4();
				questions.push({
					id,
					type: "SIGNATURE",
					title: "Signature",
					properties: {
						v: 1,
						enabled: enable
					},
					interfaceType: "NONE",
					focus: "PROJECT",
					_resclasses: [],
					visibility: "PUBLIC",
					_supervisors: [],
					reference: "ONCE",
					deleted: false,
					updatedAt: new Date(),
				});
				return { questions};
			});
		}else{
			this.setState((x) => {
				const questions = x.questions.slice();
				questions[signatureFieldIndex].properties.enabled = enable;
				questions[signatureFieldIndex].updatedAt = new Date();
				return { questions};
			});
			
		}
	}

	addQuestion() {
		this.setState((x) => {
			const questions = x.questions.slice();
			const id = uuid.v4();
			questions.push({
				id,
				type: "TEXT",
				title: "",
				properties: {
					v: 1
				},
				interfaceType: "NONE",
				focus: "PROJECT",
				_resclasses: [],
				visibility: "PUBLIC",
				_supervisors: [],
				reference: "ONCE",
				deleted: false,
				updatedAt: new Date(),
			});
			return { questions, activeQuestion: id };
		});
	}

	close() {
		this.setState({ open: false });
	}

	open() {
		this.setState({ open: true, activeQuestion: "" });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.open && !prevState.open) {
			this.getData();
		}
	}

	getData() {
		if (
			this.props.questionaryId &&
			this.props.app.questions.questionaries.has(this.props.questionaryId)
		) {
			const questionary = this.props.app.questions.questionaries.get(
				this.props.questionaryId
			);
			const questions = [];
			for (let questionX of questionary.$questions.values()) {
				if (!isValidReference(() => questionX) || questionX.deleted) continue;
				const question = JSON.parse(JSON.stringify(getSnapshot(questionX)));
				questions.push(question);
			}
			questions.sort((a,b) => a.ordering < b.ordering ? -1 : b.ordering < a.ordering ? 1 : 0);
			const signatureFieldIndex = questions.findIndex(question => question.type === "SIGNATURE");
			let signatureFieldEnabled = false;
			if(signatureFieldIndex !== -1){
				signatureFieldEnabled = questions[signatureFieldIndex].properties.enabled;
			}
			this.setState({ questions, signatureEnabled: signatureFieldEnabled });
		} else {
			this.setState({
				open: false,
				questions: [],
			});
		}
	}

	save() {
		this.props.app.questions.updateQuestions(this.state.questions.map((x,i) => {
			if (x.ordering !== i){
				x.ordering = i;
				x.updatedAt = new Date();
			}
			x.title = x.title.trim();
			x._questionary = this.props.questionaryId;
			x._supervisors = x._supervisors.filter(x => x.length);
			x._resclasses = x._resclasses.filter(x => x.length);
			if (!x._resclasses.length){
				x.reference = "ONCE";
			}
			if (x.reference === "ONCE"){
				x.focus = "PROJECT";
			}
			if (x.type === "SELECT"){
				if (!Array.isArray(x.properties.selectOptions)){
					x.type = "TEXT";
				} else {
					x.properties.selectOptions = Array.from(new Set(x.properties.selectOptions.map(x => x.trim())));
					if (x.properties.selectOptions.length < 1){
						x.type = "TEXT";
					}
				}
			} else if (x.type === "TIMESPAN"){
				if (!x.properties.timespanStart)
					x.properties.timespanStart = "";
				x.properties.timespanStart = x.properties.timespanStart.trim();
				if (x.properties.timespanStart.length < 1)
					x.properties.timespanStart = this.props.t("questions.questEdit.timespan.startPlaceholder");
				if (!x.properties.timespanEnd)
					x.properties.timespanEnd = "";
				x.properties.timespanEnd = x.properties.timespanEnd.trim();
				if (x.properties.timespanEnd.length < 1)
					x.properties.timespanEnd = this.props.t("questions.questEdit.timespan.endPlaceholder");
			}
			return x;
		}));
		this.close();
	}

	render() {
		return (
			<Modal
				centered={false}
				className="questionaryModal xModal"
				trigger={React.cloneElement(this.props.trigger, {
					onClick: (e) => this.open(),
				})}
				open={this.state.open}
				onClose={this.close}
			>
				<Menu>
					<Menu.Item header>
						{this.props.t("questions.questEdit.heading")}
					</Menu.Item>
					<Menu.Menu position="right">
						<Menu.Item name="save it" onClick={this.save}>
							<Icon name="check" /> {this.props.t("ok")}
						</Menu.Item>
						<Menu.Item name="close it" onClick={this.close}>
							<Icon name="close" /> {this.props.t("cancel")}
						</Menu.Item>
					</Menu.Menu>
				</Menu>

				<Modal.Content>
					<Checkbox label={this.props.t("addSignatureFieldLabel")} 
						className="signature-checkbox"
						checked={this.state.signatureEnabled}
						onChange={(e, data) => {
								this.setState({ signatureEnabled: data.checked });
								this.enableSignatureField(data.checked);
							}
						}
					/>
					<br />
					{this.state.questions.length ? (
						<QuestionContainer
							useDragHandle
							setActive={(id) =>
								this.setState((ps) => {
									return {
										activeQuestion: ps.activeQuestion === id ? "" : id,
									};
								})
							}
							classConstraints={this.props.classConstraints}
							resclasses={Array.from(this.props.app.resources.classes.values())
								.filter((x) => !x.deleted)
								.sort((a, b) =>
									a.human && !b.human
										? -1
										: !a.human && b.human
										? 1
										: a.name.localeCompare(b.name)
								)
								.map((x) => ({
									text: x.name,
									value: x.id,
									key: x.id,
								}))}
							onChange={this.onChange}
							activeQuestion={this.state.activeQuestion}
							selectOptionHandler={this.selectOptionHandler}
							helperClass="sortableHelper"
							items={this.state.questions.filter((x) => x.type !== "SIGNATURE")}
							onSortEnd={({ oldIndex, newIndex }) => {
								this.setState(({ questions }) => ({
									questions: arrayMove(questions, oldIndex, newIndex),
								}));
							}}
						/>
					) : (
						<Message
							attached
							info
							header={this.props.t("questions.questEdit.messages.heading")}
							content={this.props.t("questions.questEdit.messages.alt", {
								button: this.props.t("questions.questEdit.messages.add"),
							})}
						/>
					)}
					<Segment attached="bottom" textAlign="right" secondary>
						<Button
							labelPosition="left"
							content={this.props.t("questions.questEdit.messages.add")}
							icon="add"
							onClick={() => this.addQuestion()}
						/>
					</Segment>
				</Modal.Content>
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(QuestionaryModal)));
