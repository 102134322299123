import React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import WahlImg from "../images/wahl.png";
import BaseDataSelect from "../components/basedata/selects";
import { isValidReference } from "mobx-state-tree";
import Upcounter from "../components/report/upcounter";
import Logbuch from "../components/report/logbuch";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import Chart from "react-apexcharts";
import TimeButtons from "../components/report/timebuttons";
import axios from "axios";
import { Loader, Header, Icon, Button } from "semantic-ui-react";

class ReportPage extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      resclass: null,
      resource: null,
      ref: null,
      start: moment()
        .startOf("month")
        .valueOf(),
      end: moment()
        .startOf("day")
        .valueOf(),
      focusedInput: null,
      statistics: null,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.props.appCtx.setEditButtons(
      <Link className="topbutton" to="/">
        <img alt="Back" src={WahlImg} />
      </Link>,
      null
    );
    this.setResource(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id)
      return this.setResource(this.props.match.params.id);

    if (
      (prevState.resource !== this.state.resource && this.state.resource) ||
      (this.state.start && this.state.start !== prevState.start) ||
      (this.state.end && this.state.end !== prevState.end)
    ) {
      this.update();
    }
  }

  async update() {
    const start = moment(this.state.start);
    const end = moment(this.state.end).endOf("day");
    if (!this.state.ref || !start.isValid() || !end.isValid()) return;
    const jwttoken = localStorage.getItem("binfra_token");
    this.setState({ error: false, loading: true, statistics: null });
    try {
      const res = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          "/loadStatistics/" +
          this.state.resource +
          "/" +
          start.format("YYYY-MM-DD") +
          "/" +
          start.valueOf() +
          "/" +
          end.format("YYYY-MM-DD") +
          "/" +
          end.valueOf() +
          "?v=" +
          process.env.REACT_APP_SERVER_VERSION +
          "&" +
          Math.random(),
        {
          headers: {
            Authorization: "Bearer " + jwttoken,
          },
        }
      );
      this.setState({
        error: false,
        loading: false,
        statistics: res.data,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.props.app.ui.setLoginNecessary("callback", this.update.bind(this));
      }
      this.setState({ error: true, loading: false });
      console.log(error);
    }
  }

  componentWillUnmount() {
    this.props.appCtx.setEditButtons(false);
  }

  setResource(id) {
    if (!this.props.app.resources.resources.has(id)) return;
    const resource = this.props.app.resources.resources.get(id);
    if (
      resource.deleted ||
      !isValidReference((x) => resource._resclass) ||
      resource._resclass.deleted
    )
      return;
    this.setState({
      resclass: resource._resclass.id,
      resource: id,
      ref: resource,
    });
  }

  render() {
    const upcounters = [];
    const series = [];
    if (this.state.statistics) {
      upcounters.push(
        <Upcounter
          value={this.state.statistics.calDays}
          key="calDays"
          description={this.props.t("report.counters.caldays")}
        />
      );

      upcounters.push(
        <Upcounter
          value={this.state.statistics.workDays}
          key="workDays"
          description={this.props.t("report.counters.workdays")}
        />
      );

      upcounters.push(
        <Upcounter
          value={this.state.statistics.useDays}
          key="useDays"
          description={this.props.t("report.counters.usedays")}
        />
      );
      series.push(this.state.statistics.useDays);

      let wartungSum = 0;
      for (let name in this.state.statistics.absences) {
        wartungSum += this.state.statistics.absences[name];
        upcounters.push(
          <Upcounter
            value={this.state.statistics.absences[name]}
            key={name}
            description={name}
          />
        );
      }
      series.push(wartungSum);

      const unused =
        this.state.statistics.workDays -
        (wartungSum + this.state.statistics.useDays);
      series.push(unused);

      upcounters.push(
        <Upcounter
          value={unused}
          key="unused"
          description={this.props.t("report.counters.unused")}
        />
      );
    }

    return (
      <div id="reportPage" className="reportPage">
        <div className="reportWrapper">
          <div className="chooserButtons">
            <div className="buttonBlock mergeDropdowns">
              <BaseDataSelect
                domain="resources"
                value={this.state.resclass}
                type="classes"
                onChange={(e, { value }) =>
                  this.setState({
                    resource: null,
                    resclass: value,
                  })
                }
              />
              <BaseDataSelect
                domain="resources"
                value={this.state.resource}
                type="resources"
                constraints={[
                  {
                    key: "_resclass",
                    value: this.state.resclass,
                  },
                ]}
                onChange={(e, { value }) => this.setResource(value)}
              />
            </div>
            <div className="buttonBlock">
              <DateRangePicker
                startDate={this.state.start ? moment(this.state.start) : null} // momentPropTypes.momentObj or null,
                startDateId="dpicker_start" // PropTypes.string.isRequired,
                calendarInfoPosition="bottom"
                numberOfMonths={3}
                endDate={this.state.end ? moment(this.state.end) : null} // momentPropTypes.momentObj or null,
                endDateId="dpicker_end" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) =>
                  this.setState({
                    start: startDate ? startDate.valueOf() : startDate,
                    end: endDate ? endDate.valueOf() : endDate,
                  })
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                } // PropTypes.func.isRequired,
                renderCalendarInfo={(x) => (
                  <TimeButtons
                    onClick={(start, end) => this.setState({ start, end })}
                  />
                )}
                startDatePlaceholderText={this.props.t("begin")}
                endDatePlaceholderText={this.props.t("end")}
                hideKeyboardShortcutsPanel
                isOutsideRange={() => false}
                minimumNights={0}
                withPortal={true}
              />
            </div>
          </div>
          {!this.state.ref ? (
            <div className="nothingChosen">{this.props.t("report.choose")}</div>
          ) : (
            <div className="reportWrapperInner">
              <div className="imageDetails">
                <div
                  className="image"
                  style={{
                    backgroundImage: "url(" + this.state.ref.imageUrl + ")",
                  }}
                />
                <div className="details">
                  <div className="title">{this.state.ref.name}</div>
                  <div className="detailFields">
                    {Array.from(this.state.ref.information).map(
                      ([question, answer]) => (
                        <div key={question} className="xpopupLine">
                          <div className="xpopupQuestion">{question}</div>
                          <div className="xpopupAnswer">{answer}</div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              {this.state.error ? (
                <Header
                  style={{ margin: 0, padding: 20, borderTop: "2px solid #aaa", textAlign: "center", display: "block" }}
                  className="connectionError"
                  icon
                >
                  <Icon name="wifi" />
                  {this.props.t("loader.networkError")}
                  <Header.Subheader>
                    <Button onClick={(x) => this.update()}>
                      {this.props.t("loader.tryAgain")}
                    </Button>
                  </Header.Subheader>
                </Header>
              ) : this.state.loading ? (
                <div className="upcounter_collection"><Loader
                  style={{ margin: 20 }}
                  active
                  inverted
                  inline="centered"
                  size="large"
                >
                  {this.props.t("report.loading")}
                </Loader></div>
              ) : (
                <React.Fragment>
                  <div className="upcounter_collection">{upcounters}</div>
                  <div className="chart_wrapper">
                    <div className="chart">
                      <Chart
                        type="donut"
                        options={{
                          legend: { show: false },
                          tooltip: {
                            enabled: false,
                          },
                          plotOptions: {
                            pie: {
                              expandOnClick: false,
                              dataLabels: false,
                              donut: {
                                size: "80%",
                                labels: {
                                  show: true,
                                  name: {
                                    show: true,
                                    fontSize: "14px",
                                    fontFamily: "system-ui",
                                    color: "black",
                                  },
                                  value: {
                                    show: true,
                                    fontSize: "14px",
                                    fontFamily: "system-ui",
                                    formatter: (x, y) => {
                                      return (
                                        Math.round(
                                          (x /
                                            y.globals.series.reduce(
                                              (pv, cv) => pv + cv,
                                              0
                                            )) *
                                            100
                                        ) + "%"
                                      );
                                    },
                                    color: "black",
                                  },
                                  total: {
                                    show: true,
                                    fontSize: "14px",
                                    label: this.props
                                      .t("report.usage")
                                      .toUpperCase(),
                                    fontFamily: "system-ui",
                                    formatter: (x) =>
                                      Math.round(
                                        ((x.globals.series[0] +
                                          x.globals.series[1]) /
                                          x.globals.series.reduce(
                                            (pv, cv) => pv + cv,
                                            0
                                          )) *
                                          100
                                      ) + "%",
                                    color: "black",
                                  },
                                },
                              },
                            },
                          },
                          labels: [
                            this.props
                              .t("report.counters.usedays")
                              .toUpperCase(),
                            this.props
                              .t("report.counters.absences")
                              .toUpperCase(),
                            this.props
                              .t("report.counters.unused")
                              .toUpperCase(),
                          ],
                          chart: {
                            animations: {
                              speed: 4000,
                              dynamicAnimation: {
                                speed: 4000,
                              },
                            },
                            fontFamily: "system-ui",
                            selection: { enabled: false },
                          },
                          colors: ["#00FF00", "#FFFF00", "#FF0000"],
                          dataLabels: {
                            enabled: false,
                          },
                        }}
                        series={series}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              <Logbuch
                save={(response) =>
                  this.props.app.orders.updateResourceMessages(response)
                }
                resource={this.state.ref}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default inject("app")(
  withTranslation()(withRouter(observer(ReportPage)))
);
