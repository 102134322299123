import React from "react";
import Color from "color";
import { Modal, Menu, Icon, Input } from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { processTemplateFilter } from "../../../helpers/calendar.js";

class ProcessTypeChooser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			filter: ""
		};
	}

	open() {
		if (!this.props.disabled) this.setState({ open: true });
	}

	changeFilter(filter) {
		this.setState({ filter });
	}

	resolve(x) {
		this.props.onSelect(x);
		this.close();
	}

	close() {
		this.setState({ open: false });
	}

	render() {
		const filter = processTemplateFilter(this.state.filter);

		let trigger;
		if (this.props.trigger) {
			trigger = React.cloneElement(this.props.trigger, {
				onClick: e => this.open()
			});
		} else if (this.props.value) {
			const templateData = this.props.app.basedata.processTemplates.get(
				this.props.app.basedata.processTemplates.has(this.props.value)
					? this.props.value
					: "12345678-1234-5678-1234-1234567890ab"
			);
			
			if(templateData){
				trigger = (
					<div
						className={
							"processSwatch" +
							(templateData.icon ? " picon picon-" + templateData.icon : "")
						}
						onClick={e => this.open()}
						style={{
							color:
								"rgba(" +
								(Color(templateData.color).isLight()
									? "0,0,0"
									: "255,255,255") +
								",0.75)",
							backgroundColor: templateData.color
						}}
					>
						{templateData.icon ? "" : templateData.name.substr(0, 1).toUpperCase()}
					</div>
				);
			}
		}

		return (
			<Modal
				centered={false}
				className="processChooserModal"
				trigger={trigger}
				open={this.state.open}
				onClose={e => this.close()}
			>
				<Menu>
					<Menu.Item header>
						{this.props.t("processChooser.heading")}
					</Menu.Item>
					<Menu.Item>
						<Input
							className="icon"
							icon="search"
							placeholder={this.props.t("filter")}
							value={this.state.filter}
							onChange={(e, { value }) =>
								this.changeFilter(value)
							}
						/>
					</Menu.Item>
					<Menu.Menu position="right">
						<Menu.Item name="close it" onClick={e => this.close()}>
							<Icon name="close" />
						</Menu.Item>
					</Menu.Menu>
				</Menu>

				<Modal.Content>
					<div className="processChooserWrapper">
						{Array.from(
							this.props.app.basedata.processTemplates
						).map(([templateId, x]) => {
							if (x.deleted || !filter(x)) return null;

							const color = Color(x.color);
							return (
								<div
									key={x.id}
									className={
										"processItem" +
										(x.id === this.props.value
											? " chosen"
											: "")
									}
									onClick={() => this.resolve(x)}
								>
									<div
										className={"processItemBox"+(x.icon ? " picon picon-" + x.icon : "")}
										style={{
											color:
												"rgba(" +
												(color.isLight()
													? "0,0,0"
													: "255,255,255") +
												",0.75)",
											backgroundColor: x.color
										}}
									>
										{x.icon ? "" : x.name.substr(0, 1).toUpperCase()}
									</div>
									<div className="processItemName">
										{x.name}
										<div className="processItemOwner">({x.owner_name})</div>
									</div>
								</div>
							);
						})}
					</div>
				</Modal.Content>
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(ProcessTypeChooser)));
