import React from "react";
import { Form, Input } from "semantic-ui-react";

class BlurChangeInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      originalValue: props.value,
    };
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
  }

  focus() {
    //console.log("FOCUS");
    this.setState({ originalValue: this.props.value });
  }

  blur() {
    //console.log(this.state.originalValue, this.props.value);
    if (this.state.originalValue !== this.props.value) this.props.onBlur();
  }

  render() {
    if ("textarea" in this.props) {
      return (
        <Form.TextArea
          label={this.props.label}
          placeholder={
            "placeholder" in this.props ? this.props.placeholder : null
          }
          value={"value" in this.props ? this.props.value : null}
          fluid={"fluid" in this.props ? this.props.fluid : null}
          disabled={"disabled" in this.props ? this.props.disabled : null}
          onChange={"onChange" in this.props ? this.props.onChange : null}
          onFocus={this.focus}
          onBlur={this.blur}
        />
      );
    } else if ("label" in this.props) {
      return (
        <Form.Field>
          <label>{this.props.label}</label>
          <Input
            placeholder={
              "placeholder" in this.props ? this.props.placeholder : null
            }
            type={"type" in this.props ? this.props.type : "text"}
            value={"value" in this.props ? this.props.value : null}
            fluid={"fluid" in this.props ? this.props.fluid : null}
            disabled={"disabled" in this.props ? this.props.disabled : null}
            onChange={"onChange" in this.props ? this.props.onChange : null}
            onFocus={this.focus}
            onBlur={this.blur}
          />
        </Form.Field>
      );
    } else {
      return (
        <Input
          placeholder={
            "placeholder" in this.props ? this.props.placeholder : null
          }
          type={"type" in this.props ? this.props.type : "text"}
          size={"size" in this.props ? this.props.size : null}
          autoComplete={"autoComplete" in this.props ? this.props.autoComplete : ""}
          value={"value" in this.props ? this.props.value : null}
          fluid={"fluid" in this.props ? this.props.fluid : null}
          disabled={"disabled" in this.props ? this.props.disabled : null}
          onChange={"onChange" in this.props ? this.props.onChange : null}
          onFocus={this.focus}
          onBlur={this.blur}
        />
      );
    }
  }
}

export default BlurChangeInput;
