export const projectFilter = filterString => project => {
	if (!filterString) return true;
	return project.name.toLowerCase().indexOf(filterString.toLowerCase()) > -1;
};

export const arrayFilter = filterString => {
	if (!filterString) return _ => true;
	const searchTerms = filterString.split(" ").map(x => x.toLowerCase().trim()).filter(x => x);
	if (!searchTerms.length) return _ => true;
	return tags => {
		const ftags = tags.map(x => x.toLowerCase().trim()).filter(x => x);
		for (let term of searchTerms){
			let termFound = false;
			for (let tag of ftags){
				if (tag.indexOf(term) > -1){
					termFound = true;
					break;
				}
			}
			if (!termFound)
				return false;
		}
		return true;
	};
};

export const processTemplateFilter = filterString => process => {
	if (!filterString) return true;
	return process.name.toLowerCase().indexOf(filterString.toLowerCase()) > -1;
};

export const strToDate = (date, add = 0) => {
	return new Date(
		parseInt(date[0] + date[1] + date[2] + date[3]),
		parseInt(date[5] + date[6]) - 1,
		parseInt(date[8] + date[9]) + add
	);
};
