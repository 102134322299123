import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
	Tab,
	Table,
	Button,
	Segment,
	Icon,
	Header,
	Checkbox
} from "semantic-ui-react";
import BlurChangeInput from "../controls/blurChangeInput";
import BaseDataSelect from "../../basedata/selects";
import ConfirmButton from "../controls/confirmButton";

const EditContacts = props => {
	const { t } = useTranslation();
	const stateCts = props.edit.state.data.contacts;

	let noContact = (
		<Fragment>
			<Table.Body>
				<Table.Row>
					<Table.Cell>
						<Segment placeholder>
							<Header icon>
								<Icon name="user times"/>
								{t("edit.nodata.contacts.message")}
							</Header>
						</Segment>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Fragment>
	);

	let contact = (
	<Fragment>
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell>
					{t("edit.fields.contactPerson.label")}
				</Table.HeaderCell>
				<Table.HeaderCell>
					{t("edit.fields.contactFunction.label")}
				</Table.HeaderCell>
				<Table.HeaderCell collapsing>
					{t("edit.fields.contactWrite.label")}
				</Table.HeaderCell>
				{props.edit.state.data.editable ? <Table.HeaderCell collapsing /> : null}
			</Table.Row>
		</Table.Header>

		<Table.Body>
			{Object.values(stateCts).filter(x => !x.deleted).map(x => (
				<Table.Row key={x.id}>
					<Table.Cell>
						<BaseDataSelect
							value={x.person}
							onChange={(e, { value }) =>{
								props.data.changeContact(
									"person",
									value,
									x.id
								); props.edit.saveHistory();}
							}
							type="persons"
							disabled={!props.edit.state.data.editable}
						/>
					</Table.Cell>
					<Table.Cell>
						<BlurChangeInput
							placeholder={t(
								"edit.fields.contactFunction.placeholder"
							)}
							value={x.function}
							fluid
							disabled={!props.edit.state.data.editable}
							onBlur={_ => props.edit.saveHistory()}
							onChange={(e, { value }) =>
								props.data.changeContact(
									"function",
									value,
									x.id
								)
							}
						/>
					</Table.Cell>
					<Table.Cell>
						<Checkbox checked={!x.readOnly} disabled={!props.edit.state.data.editable} toggle onChange={(e, { value }) =>
								{props.data.changeContact(
									"readOnly",
									!x.readOnly,
									x.id
								); props.edit.saveHistory(); }
							} />
					</Table.Cell>
					{props.edit.state.data.editable ? <Table.Cell>
						<ConfirmButton
							ok="yes"
							cancel="no"
							trigger={
								<Button
									floated="right"
									icon="trash alternate"
									size="small"
								/>
							}
							disabled={!props.edit.state.data.editable}
							onClick={e =>
								{props.data.changeContact(
									"deleted",
									true,
									x.id
								); props.edit.saveHistory();}
							}
						/>
					</Table.Cell> : null }
				</Table.Row>
			))}
		</Table.Body>
	</Fragment>
	);


	return (
		<Tab.Pane>
			<Table singleLine>
				{
					(Object.values(stateCts).filter(x => !x.deleted).length === 0 && stateCts.constructor === Object)
						? noContact
						: contact
				}
				{props.edit.state.data.editable ? <Table.Footer fullWidth>
					<Table.Row>
						<Table.HeaderCell colSpan="4">
							<Button
								floated="right"
								icon
								labelPosition="left"
								onClick={e => props.data.addContact()}
								primary
								size="small"
							>
								<Icon name="user" />{" "}
								{t("edit.buttons.newContact")}
							</Button>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer> : null }
			</Table>
		</Tab.Pane>
	);
};

export default EditContacts;
