import React from "react";
import { isValidReference } from "mobx-state-tree";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ConfirmButton from "../components/calendar/controls/confirmButton";
import { Input, Table, Button } from "semantic-ui-react";
import WahlImg from "../images/wahl.png";
import { Link } from "react-router-dom";
import { arrayFilter } from "../helpers/calendar.js";
import uuid from "uuid";

class GroupPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			name: "",
			content: [],
			currentGroup: false,
			filter: ""
		};
	}

	componentDidMount() {
		this.props.appCtx.setEditButtons(
			<Link className="topbutton" to="/">
				<img alt="Back" src={WahlImg} />
			</Link>,
			null
		);
		this.setup();
	}

	save(){
		this.props.app.resources.updateResGroups([{
			updatedAt: new Date(),
			id: this.state.id,
			name: this.state.name,
			_members: this.state.content
		}]);
		this.props.history.push("/groups");
	}

	toggle(id){
		this.setState(state => {
			const content = state.content.slice();
			const index = content.indexOf(id);
				if (index > -1){
					content.splice(index,1);
				} else {
					content.push(id);
				}
				return {content};
		});
	}

	setup() {
		const currentGroup =
			this.props.match.params.id === "add"
				? true
				: this.props.match.params.id !== "undefined" &&
				  this.props.app.resources.groups.has(this.props.match.params.id)
				? this.props.match.params.id
				: false;
		let name = "";
		let id = uuid.v4();
		let content = [];
		if (currentGroup !== true && currentGroup !== false) {
			const group = this.props.app.resources.groups.get(currentGroup);
			name = group.name;
			id = group.id;
			for (let part of group._members) {
				if (!isValidReference(() => part)) continue;
				content.push(part.id);
			}
		}
		this.setState({ name, id, content, currentGroup, filter: "" });
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.setup();
		}
	}

	changeFilter(filter) {
		this.setState({ filter });
	}

	changeName(name) {
		this.setState({ name });
	}

	componentWillUnmount() {
		this.props.appCtx.setEditButtons(false);
	}

	render() {
		//console.log(this.state);

		const groups = this.state.currentGroup
			? []
			: Array.from(this.props.app.resources.groups.values()).filter(
					x => !x.deleted
			  );

		groups.sort((a, b) => a.name.localeCompare(b.name));

		const left = [];
		const right = [];
		let lastLeftClass = "";
		let lastRightClass = "";
		if (this.state.currentGroup) {
			const filter = arrayFilter(this.state.filter);
			for (let res of this.props.app.resources.getResourceLines("ALL")) {
				if (this.state.content.includes(res.id)) {
					//right
					if (lastRightClass !== res.resclass.name) {
						right.push(
							<div className="group_classHeader" key={res.resclass.id}>
								{res.resclass.name}
							</div>
						);
						lastRightClass = res.resclass.name;
					}
					right.push(
						<div className="groupchooser_resource" key={res.id}>
							<Button onClick={_ => this.toggle(res.id)} icon="chevron left" />
							<div
								className="gc_image"
								style={{ backgroundImage: "url(" + res.image + ")" }}
							/>
							<div className="gc_name">{res.name}</div>
						</div>
					);
				} else {
					if (!filter([res.name, res.resclass.name, res.inventaryNumber]))
						continue;
					if (lastLeftClass !== res.resclass.name) {
						left.push(
							<div className="group_classHeader">{res.resclass.name}</div>
						);
						lastLeftClass = res.resclass.name;
					}
					left.push(
						<div className="groupchooser_resource" key={res.id}>
							<div
								className="gc_image"
								style={{ backgroundImage: "url(" + res.image + ")" }}
							/>
							<div className="gc_name">{res.name}</div>
							<Button icon="chevron right" onClick={_ => this.toggle(res.id)} />
						</div>
					);
				}
			}
			if (!right.length)
				right.push(
					<div className="emptygroup">{this.props.t("groups.empty")}</div>
				);
			if (!left.length)
				left.push(
					<div className="emptygroup">{this.props.t("groups.nores")}</div>
				);
		}

		return (
			<div id="groupPage" className="groupPage">
				<div id="group_buttons" className="buttonLine">
					{this.state.currentGroup ? (
						<React.Fragment>
							<Button content={this.props.t("ok")} positive onClick={_ => this.save()} />
							<Button as={Link} to="/groups" content={this.props.t("cancel")} />
						</React.Fragment>
					) : (
					<React.Fragment><div className="buttonBlock" />
          <div className="buttonBlock">
						<Button
							as={Link}
							to="/groups/add"
							content={this.props.t("groups.addGroup")}
							icon={"plus"}
							labelPosition="left"
						/></div></React.Fragment>
					)}
				</div>

				{this.state.currentGroup ? (
					<div className="groupselect_wrapper">
						<div className="groupselect_left">
							<div className="groupselect_title">
								{this.props.t("groups.allres")}
							</div>
							<div className="groupselect_filter">
								<Input
									className="icon"
									icon="search"
									placeholder={this.props.t("filter")}
									value={this.state.filter}
									fluid
									onChange={(e, { value }) => this.changeFilter(value)}
								/>
							</div>
							<div className="groupselect_resources">{left}</div>
						</div>
						<div className="groupselect_right">
							<div className="groupselect_title">
								{this.props.t("groups.groupres")}
							</div>
							<div className="groupselect_filter">
								<Input
									value={this.state.name}
									fluid
									onChange={(e, { value }) => this.changeName(value)}
									placeholder={this.props.t("groups.groupname")}
								/>
							</div>
							<div className="groupselect_resources">{right}</div>
						</div>
					</div>
				) : (
					<div className="groupBody">
						<Table singleLine>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>
										{this.props.t("groups.name")}
									</Table.HeaderCell>
									<Table.HeaderCell />
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{groups.length
									? groups.map(x => (
											<Table.Row key={x.id}>
												<Table.Cell>{x.name}</Table.Cell>
												<Table.Cell className="buttoncell">
													<Button
														as={Link}
														to={"/groups/" + x.id}
														icon="pencil"
													/>
													<ConfirmButton
														onClick={e => x.delete()}
														question={["questions.delete", { name: x.name }]}
														ok="yes"
														cancel="no"
														trigger={<Button icon="trash alternate" />}
													/>
												</Table.Cell>
											</Table.Row>
									  ))
									: (<Table.Cell>{this.props.t("groups.nogroups")}</Table.Cell>)}
							</Table.Body>
						</Table>
					</div>
				)}
			</div>
		);
	}
}

export default inject("app")(
	withTranslation()(withRouter(observer(GroupPage)))
);
