import { types, getRoot, getSnapshot } from "mobx-state-tree";
import axios from "axios";
import * as Sentry from "@sentry/react";

const UIStore = types
	.model("UIStore", {
		calStart: types.Date,
		calDuration: types.integer,
		lastUpdate: types.optional(types.Date, 0),
		lastUpdateMonths: types.map(types.Date),
		lastSave: types.optional(types.Date, () => new Date()),
		calScrollNecessary: types.boolean,
		calSnap: types.string,
		filterString: types.optional(types.string, ""),
		calPosition: types.maybe(types.Date),
		eventsafe: types.optional(types.string, ""),
		fullscreen: types.optional(types.boolean, false),
		fillHoles: types.optional(types.boolean, false),
		currentClass: types.array(types.string),
		mode: types.optional(
			types.enumeration(["CALENDAR", "RESOURCES", "BOARD"]),
			"CALENDAR"
		),
		hide: types.optional(
			types.array(types.enumeration(["projects", "resources", "mixingplants"])),
			[]
		),
		reset: types.optional(types.Date, new Date()),
	})
	.volatile((self) => ({
		fullyLoaded: false,
		backgroundLoading: false,
		isLoading: true,
		settings: {
			isBPO: false,
			duraExport: false,
		},
		profileApplied: false,
		profile: {},
		modules: new Set(),
		showDayViewMap: true,
		rights: new Set(),
		wasLoggedIn: false,
		loginNecessaryCallback: () => {},
		oldCalDuration: false,
		loadkey: "",
		networkError: false,
		currentLoader: "none",
		loaderTries: 1,
		loginNecessary: false,
		luCopy: new Map(),
		loadStart: 0,
		sortMethod: "dateAscending",
		groupSortMethod: "dateAscending",
		loaderProcessing: false,
		message: "",
		dayView: false,
		jobChooserModal: false,
		requirementMode: true,
		resourceEditor: false,
		truckDeploymentSelected: null,
		selectOnlyDrivers: false,
		jobEditModal: false,
		mouseOver: {},
		moTimeout: false,
		reverseDispo: false,
		whiteClickData: {
			resource: "",
			selected: new Set(),
			lastClick: 0,
			lastClickDate: "",
		},
		resourceDeploymentSelected: [],
		resourceDeploymentHistory: {
			history: [],
			forwardHistory: [],
		},
		resourceMultiselect: false,
	}))
	.views((self) => ({
		get calEnd() {
			return new Date(self.calStart.getTime() + self.calDuration);
		},
		get view() {
			let padding = parseInt(self.calDuration * 1);
			return {
				start: new Date(self.calStart.getTime() - padding),
				end: new Date(self.calEnd.getTime() + padding),
			};
		},
		get paddedView() {
			let padding = parseInt(self.calDuration * 0.3);
			return {
				start: self.calStart.getTime() - padding,
				end: self.calEnd.getTime() + padding,
			};
		},
	}))
	.actions((self) => ({
		setMaxMonth() {
			const { start, end } = self.view;
			const minYear = start.getFullYear();
			const minMonth = start.getMonth();
			const maxYear = end.getFullYear();
			const maxMonth = end.getMonth();
			let reload = false;
			for (let year = minYear; year <= maxYear; year++) {
				for (
					let month = year === minYear ? minMonth : 0;
					month <= (year === maxYear ? maxMonth : 11);
					month++
				) {
					const key = year + "-" + month;
					if (self.lastUpdateMonths.has(key)) continue;
					self.lastUpdateMonths.set(key, new Date(0));
					reload = true;
				}
			}
			if (reload) {
				if (self.fullyLoaded) {
					self.backgroundLoading = true;
				}
				//console.log("ALLO!");
				self.loadData("basedata");
			}
		},
		applyProfile() {
			try {
				const profile = self.profile;
				if ("groupSortMethod" in profile) self.groupSortMethod = profile.groupSortMethod;
				if ("currentClass" in profile) self.currentClass = profile.currentClass;
				if ("calDuration" in profile) self.calDuration = profile.calDuration;
				if ("sortMethod" in profile) self.sortMethod = profile.sortMethod;
				if ("fullscreen" in profile) self.fullscreen = profile.fullscreen;
				if ("showDayViewMap" in profile) self.showDayViewMap = profile.showDayViewMap;
				if ("mode" in profile) self.setMode(profile.mode);
				if ("hide" in profile) self.hide = profile.hide;
			} catch (e) {
				console.log(e);
			}
			self.profileApplied = true;
		},
		getProfile() {
			return {
				calDuration: self.calDuration,
				sortMethod: self.sortMethod,
				fullscreen: self.fullscreen,
				hide: self.hide,
				showDayViewMap: self.showDayViewMap,
				mode: self.mode,
				currentClass:self.currentClass,
				groupSortMethod:self.groupSortMethod
			};
		},
		setDayView(value) {
			self.dayView = value;
		},
		setDayViewMap(value) {
			self.showDayViewMap = value;
		},
		setRequirementMode(value) {
			self.requirementMode = value;
		},
		setTruckDeployment(value) {
			self.truckDeploymentSelected = value;
		},
		setLogin(username, token) {
			localStorage.setItem("binfra_username", username);
			localStorage.setItem("binfra_token", token);

			try {
				Sentry.configureScope(function(scope) {
					scope.setUser({ username: username });
				});
			} catch (e) {
				console.log("SENTRY ERROR");
			}

			if (self.loginNecessary === "save") {
				self.save();
			} else if (self.loginNecessary === "callback") {
				self.loginNecessaryCallback();
			} else {
				self.loadData("basedata");
			}
			self.wasLoggedIn = true;
			self.loginNecessary = false;
		},
		loadData(loader, retry = false, loadkeyx = false) {
			if (retry) self.loaderTries++;

			self.networkError = false;

			let loadkey = loadkeyx;

			if (loadkeyx === false) {
				loadkey = (Math.random() + "").substr(0, 6);
				self.loadkey = loadkey;
			} else if (self.loadkey !== loadkey) {
				console.log("BLOCK BY LOADKEY");
				return;
			}

			const jwttoken = localStorage.getItem("binfra_token");
			if (!jwttoken) {
				self.loginNecessary = "load";
				return;
			}

			if (self.loaderTries > 10) {
				self.currentLoader = "none";
				self.loaderTries = 1;
				self.loaderProcessing = false;
				self.networkError = "loading";
				self.isLoading = false;
				self.backgroundLoading = false;
				return;
			}

			self.loaderProcessing = false;
			switch (loader) {
				case "basedata":
					return self.loadBaseData(jwttoken, loadkey);
				case "projects":
					return self.loadProjects(jwttoken, loadkey);
				case "resources":
					return self.loadResources(jwttoken, loadkey);
				case "questions":
					return self.loadQuestions(jwttoken, loadkey);
				default:
					return;
			}
		},
		async loadBaseData(jwttoken, loadkey) {
			if (self.loadkey !== loadkey) {
				console.log("BLOCK BY LOADKEY");
				return;
			}
			self.currentLoader = "basedata";
			self.isLoading = true;
			self.loadStart = new Date();
			self.luCopy = new Map(self.lastUpdateMonths);
			try {
				if (
					self.backgroundLoading &&
					self.loadStart.getTime() - self.lastUpdate.getTime() < 300000
				) {
					self.handleBaseData(false, loadkey);
				} else {
					const response = await axios.get(
						process.env.REACT_APP_SERVER_URL +
							"/load/basedata/" +
							self.lastUpdate.getTime() +
							"?v="+process.env.REACT_APP_SERVER_VERSION+"&" +
							Math.random(),
						{
							headers: {
								Authorization: "Bearer " + jwttoken,
							},
						}
					);
					self.handleBaseData(response.data, loadkey);
				}
			} catch (error) {
				// handle error
				console.log(error);
				if (error.response && error.response.status === 401) {
					return self.setLoginNecessary("load");
				}

				self.loadData("basedata", true, loadkey);
			}
		},
		setSettings(s) {
			self.settings = Object.assign({}, self.settings, s.settings);
			self.modules = new Set(s.modules);
			self.rights = new Set(s.rights);
			self.profile = s.profile;
		},
		handleBaseData(data, loadkey) {
			if (self.loadkey !== loadkey) {
				console.log("BLOCK BY LOADKEY");
				return;
			}
			self.loaderProcessing = true;
			//handle base data loading!

			if (data !== false) {
				const r = getRoot(self);

				self.setSettings(data.settings);

				r.basedata.updateWorkDays(data.workDays);

				r.basedata.updateTruckTypes(data.truckTypes);
				r.basedata.updateSuppliers(data.suppliers);
				r.basedata.updateCompany(data.company);
				r.basedata.updateProcessTemplates(data.processTemplates);
				r.basedata.updatePersons(data.persons);
				r.basedata.updateAbsenceTypes(data.absenceTypes);
				r.basedata.updateMaterials(data.materials);
				r.resources.updateResClasses(data.resclass);
				r.resources.updateResources(data.resources);
				r.resources.updateRequirements(data.requirements);
				r.resources.updateResGroups(data.resgroups);
				r.orders.updateResourceMessages(data.resourceMessages);
			}

			self.setLoaderTries(1);

			self.loadData("projects", false, loadkey);
		},
		async loadProjects(jwttoken, loadkey) {
			if (self.loadkey !== loadkey) {
				console.log("BLOCK BY LOADKEY");
				return;
			}
			self.currentLoader = "projects";
			try {
				for (let [month, lu] of self.luCopy.entries()) {
					if (
						self.backgroundLoading &&
						self.loadStart.getTime() - lu.getTime() < 300000
					) {
						continue;
					}
					const response = await axios.get(
						process.env.REACT_APP_SERVER_URL +
							"/load/projects/" +
							lu.getTime() +
							"/" +
							month +
							"?v="+process.env.REACT_APP_SERVER_VERSION+"&" +
							Math.random(),
						{
							headers: {
								Authorization: "Bearer " + jwttoken,
							},
						}
					);
					if (self.loadkey !== loadkey) {
						console.log("BLOCK BY LOADKEY");
						return;
					}
					self.handleProjects(response.data, loadkey);
				}
				self.setLoaderTries(1);
				self.loadData("resources", false, loadkey);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					return self.setLoginNecessary("load");
				}
				console.log(error);
				self.loadData("projects", true, loadkey);
			}
		},
		handleProjects(data, loadkey) {
			if (self.loadkey !== loadkey) {
				console.log("BLOCK BY LOADKEY");
				return;
			}
			self.loaderProcessing = true;

			const r = getRoot(self);

			r.projects.updateProjects(data.projects);
			r.projects.updateProcesses(data.processes);
			r.projects.updateJobs(data.jobs);
			r.projects.updateContacts(data.contacts);
			r.projects.updateProjectAttachments(data.projectAttachments);
			r.orders.updateOrders(data.orders);
			r.orders.updateDisposition(data.dispositions);
			r.projects.updateRentalResources(data.rentalresources);
			r.projects.updateRentalDeployments(data.rentaldeployments);
			r.projects.updateRequests(data.requests);
		},
		handleProjectsForReports(data) {
			const r = getRoot(self);

			r.projects.updateProjects(data.projects);
			r.projects.updateProcesses(data.processes);
			r.projects.updateJobs(data.jobs);
			r.projects.updateContacts(data.contacts);
			r.projects.updateProjectAttachments(data.projectAttachments);
			r.orders.updateOrders(data.orders);
			r.orders.updateDisposition(data.dispositions);
			r.projects.updateRentalResources(data.rentalresources);
			r.projects.updateRentalDeployments(data.rentaldeployments);
			r.projects.updateRequests(data.requests);
		},
		async loadResources(jwttoken, loadkey) {
			if (self.loadkey !== loadkey) {
				console.log("BLOCK BY LOADKEY");
				return;
			}
			self.currentLoader = "resources";
			try {
				for (let [month, lu] of self.luCopy.entries()) {
					if (
						self.backgroundLoading &&
						self.loadStart.getTime() - lu.getTime() < 300000
					) {
						continue;
					}
					const response = await axios.get(
						process.env.REACT_APP_SERVER_URL +
							"/load/resources/" +
							lu.getTime() +
							"/" +
							month +
							"?v="+process.env.REACT_APP_SERVER_VERSION+"&" +
							Math.random(),
						{
							headers: {
								Authorization: "Bearer " + jwttoken,
							},
						}
					);
					if (self.loadkey !== loadkey) {
						console.log("BLOCK BY LOADKEY");
						return;
					}
					self.handleResources(response.data, loadkey);
				}
				self.setLoaderTries(1);
				if (
					self.modules.has("DAYREPORTS") &&
					self.rights.has("SHOW_DAYREPORT")
				) {
					self.loadData("questions", false, loadkey);
				} else {
					self.loadCompleted(loadkey);
				}
			} catch (error) {
				if (error.response && error.response.status === 401) {
					return self.setLoginNecessary("load");
				}
				console.log(error);
				self.loadData("resources", true, loadkey);
			}
		},
		setLoaderTries(x) {
			self.loaderTries = x;
		},
		handleResources(data, loadkey) {
			if (self.loadkey !== loadkey) {
				console.log("BLOCK BY LOADKEY");
				return;
			}
			self.loaderProcessing = true;

			const r = getRoot(self);

			r.basedata.updateExceptions(data.exceptions);
			r.basedata.updateHolidays(data.holidays);
			r.orders.updatePositions(data.positions);
			r.orders.updateAbsences(data.absences);
			r.orders.updateDeployments(data.deployments);
		},
		handleResourcesForReports(data) {
			const r = getRoot(self);

			r.basedata.updateExceptions(data.exceptions);
			r.basedata.updateHolidays(data.holidays);
			r.orders.updatePositions(data.positions);
			r.orders.updateAbsences(data.absences);
			r.orders.updateDeployments(data.deployments);
		},
		async loadQuestions(jwttoken, loadkey) {
			if (self.loadkey !== loadkey) {
				console.log("BLOCK BY LOADKEY");
				return;
			}
			self.currentLoader = "questions";
			try {
				if (
					self.backgroundLoading &&
					self.loadStart.getTime() - self.lastUpdate.getTime() < 300000
				) {
					self.handleQuestions(false, loadkey);
				} else {
					const response = await axios.get(
						process.env.REACT_APP_SERVER_URL +
							"/load/questions/" +
							self.lastUpdate.getTime() +
							"?v="+process.env.REACT_APP_SERVER_VERSION+"&" +
							Math.random(),
						{
							headers: {
								Authorization: "Bearer " + jwttoken,
							},
						}
					);
					self.handleQuestions(response.data, loadkey);
				}
			} catch (error) {
				// handle error
				console.log(error);
				if (error.response && error.response.status === 401) {
					return self.setLoginNecessary("load");
				}

				self.loadData("questions", true, loadkey);
			}
		},
		handleQuestions(data, loadkey) {
			if (self.loadkey !== loadkey) {
				console.log("BLOCK BY LOADKEY");
				return;
			}
			self.loaderProcessing = true;

			if (data !== false) {
				const r = getRoot(self);

				r.questions.updateUsergroups(data.usergroups);
				r.questions.updateQuestionaries(data.questionaries);
				r.questions.updateQuestions(data.questions);
			}

			self.setLoaderTries(1);
			self.loadCompleted(loadkey);
		},
		loadCompleted(loadkey) {
			if (self.loadkey !== loadkey) {
				console.log("BLOCK BY LOADKEY");
				return;
			}
			if (!self.profileApplied) {
				self.applyProfile();
			}
			self.currentLoader = "none";
			self.lastUpdate = self.loadStart;
			for (let month of self.luCopy.keys()) {
				self.lastUpdateMonths.set(month, self.loadStart);
			}
			self.loaderProcessing = false;
			self.fullyLoaded = true;
			self.wasLoggedIn = true;
			self.isLoading = false;
			self.backgroundLoading = false;
		},
		cancelSave() {
			self.isLoading = false;
			self.backgroundLoading = false;
			self.networkError = false;
		},
		setLoginNecessary(x, callback = false) {
			self.loginNecessary = callback ? "callback" : x;
			self.loginNecessaryCallback = callback;
		},
		logout() {
			try {
				Sentry.configureScope((scope) => scope.setUser(null));
			} catch (e) {
				console.log("Sentry error");
			}
			localStorage.setItem("binfra_token", "");
			localStorage.setItem("binfra_username", "");
			if (window.bpo) {
				window.location = ""+process.env.REACT_APP_BPO_URL+"/?logout";
			} else {
				window.location = window.location.href.split("?")[0];
			}
		},
		saveNecessary() {
			console.log(Object.values(self.getSaveData()));
			for (const x of Object.values(self.getSaveData()))
				if (x.length > 0) return true;
			return false;
		},
		getSaveData() {
			console.log("retrieved save data for ", self.lastSave);
			const r = getRoot(self);
			const bd = r.basedata.collectChanges(self.lastSave);
			const pd = r.projects.collectChanges(self.lastSave);
			const rd = r.resources.collectChanges(self.lastSave);
			const od = r.orders.collectChanges(self.lastSave);
			const qd = r.questions.collectChanges(self.lastSave);
			return {
				...bd,
				...pd,
				...rd,
				...od,
				...qd,
				profile: self.getProfile(),
			};
		},
		save(retry = false) {
			const jwttoken = localStorage.getItem("binfra_token");

			if (!jwttoken) {
				self.loginNecessary = "save";
				return;
			}

			self.isLoading = true;
			self.currentLoader = "saving";
			if (retry) self.loaderTries++;
			else self.loaderTries = 1;

			self.networkError = false;

			if (self.loaderTries > 10) {
				self.currentLoader = "none";
				self.loaderTries = 1;
				self.loaderProcessing = false;
				self.networkError = "saving";
				self.isLoading = false;
				return;
			}
			// const savetime = new Date();
			const saveData = self.getSaveData();
			
			axios
				.post(process.env.REACT_APP_SERVER_URL + "/save?v="+process.env.REACT_APP_SERVER_VERSION, saveData, {
					headers: {
						Authorization: "Bearer " + jwttoken,
					},
				})
				.then(function(response) {
					// handle success
					self.setLastSave(new Date());
					self.loadData("basedata");
				})
				.catch(function(error) {
					// handle error
					if (error.response && error.response.status === 401) {
						return self.setLoginNecessary("save");
					}
					self.save(true);
				});
		},
		setLastSave(x) {
			console.log("Last save set to ", x);
			self.lastSave = x;
		},
		setMouseOver(m) {
			if (self.moTimeout) clearTimeout(self.moTimeout);
			self.mouseOver = m;
		},
		resetMouseOver() {
			if (self.moTimeout) clearTimeout(self.moTimeout);
			self.moTimeout = setTimeout((x) => {
				self.setMouseOver({});
			}, 100);
		},
		getFullSnapshot() {
			console.log(JSON.stringify(getSnapshot(getRoot(self).projects)));
			console.log(JSON.stringify(getSnapshot(getRoot(self).resources)));
			console.log(JSON.stringify(getSnapshot(getRoot(self).orders)));
			console.log(JSON.stringify(getSnapshot(getRoot(self).basedata)));
		},
		setClassDropdown(e, data) {
			let newval = data.value;

			let addition = newval.filter((x) => !self.currentClass.includes(x));

			if (addition.length === 1 && (!e || !e.target.classList.contains("addbutton"))) {
				newval = addition;
			} else {
				if (self.currentClass.includes("ALL")) {
					newval = newval.filter((x) => x !== "ALL");
				}

				if (newval.includes("ALL")) newval = ["ALL"];
			}

			if (newval.length === 0) return;

			self.currentClass = newval;
		},
		toggleHide(module) {
			if (self.hide.includes(module))
				self.hide = self.hide.filter((x) => x !== module);
			else self.hide.push(module);
		},
		setMode(x) {
			const oldMode = self.mode;
			self.mode = x;

			if (self.currentClass.length === 0) {
				self.currentClass = [
					getRoot(self).resources.getClassDropdownOptions((x) => x, true)[0].id,
				];
			}

			if (self.mode === "BOARD") {
				self.oldCalDuration = self.calDuration;
				self.calDuration = Math.min(self.calDuration, 1000 * 60 * 60 * 24 * 7);
				if (
					self.currentClass.includes("ALL") ||
					self.currentClass.includes("GROUPS")
				) {
					self.currentClass = [
						getRoot(self).resources.getClassDropdownOptions(null, false)[0].id,
					];
				}
			} else if (oldMode === "BOARD" && self.oldCalDuration) {
				self.calDuration = self.oldCalDuration;
			}
		},
		startJobEditModal(job) {
			if (self.jobChooserModal !== false) self.jobChooserModal = false;
			self.jobEditModal = job;
		},
		openMessage(m) {
			self.message = m;
		},

		closeMessage() {
			self.message = "";
		},
		startResourceEditor(job, selectOnlyDrivers = false, truckDeploymentSelected = null) {
			self.resourceEditor = job;
			self.selectOnlyDrivers = selectOnlyDrivers;
			if(selectOnlyDrivers === true){
				self.requirementMode = false;
				self.truckDeploymentSelected = truckDeploymentSelected;
			}
		},
		closeJobChooserModal() {
			self.jobChooserModal = false;
		},
		closeResourceEditor() {
			self.resourceEditor = false;
		},
		closeJobEditModal() {
			self.jobEditModal = false;
		},
		setSortMethod(x) {
			self.sortMethod = x;
		},
		setGroupSortMethod(x) {
			self.groupSortMethod = x;
		},
		startJobChooserModal(jobs, callback) {
			self.jobChooserModal = { jobs, callback };
		},
		setCalPosition(x) {
			self.calPosition = x;
		},
		setCalStart(start) {
			self.calStart = start;
			self.calPosition = start;
			self.calScrollNecessary = true;
			self.setMaxMonth();
		},
		setFilterString(str) {
			self.filterString = str;
		},
		addTime(time) {
			self.setCalStart(new Date(self.calPosition.getTime() + time));
		},
		zoom(percentage) {
			self.calDuration = parseInt(self.calDuration * percentage);
			self.setMaxMonth();
		},
		calScrollDone() {
			self.calScrollNecessary = false;
		},
		calScrollNeeded() {
			self.calScrollNecessary = true;
		},
		swipeStart(event) {
			var extractedEvent = [];
			if (!("type" in event)) return false;
			extractedEvent.push(event.type);
			if (event.type === "mousedown") {
				extractedEvent.push(event.pageX);
				extractedEvent.push(event.pageY);
			} else {
				extractedEvent.push(event.changedTouches[0].pageX);
				extractedEvent.push(event.changedTouches[0].pageY);
			}
			self.eventsafe = extractedEvent.join(",");
		},
		swipeEnd(event) {
			if (self.eventsafe === "") return false;

			let Xa, Xb, Ya, Yb;
			let eventsafe = self.eventsafe.split(",");

			Xb = parseInt(eventsafe[1]);
			Yb = parseInt(eventsafe[2]);

			if (eventsafe[0] === "mousedown") {
				Xa = event.pageX;
				Ya = event.pageY;
			} else {
				Xa = event.changedTouches[0].pageX;
				Ya = event.changedTouches[0].pageY;
			}

			if (Xb - Xa > 150 && Math.abs(Yb - Ya) < 100) self.toggleBig();
			self.eventsafe = "";
		},
		toggleBig() {
			self.fullscreen = !self.fullscreen;
		},
		closeReverseChooser() {
			self.reverseDispo = false;
		},
		resetWhiteClickData() {
			self.whiteClickData = {
				resource: "",
				selected: new Set(),
				lastClick: 0,
				lastClickDate: "",
			};
		},
		whiteClick(time, date, res) {
			if (res.id !== self.whiteClickData.resource.id) {
				self.whiteClickData = {
					lastClick: time,
					lastClickDate: date,
					selected: new Set([date]),
					resource: res,
				};
			} else if (
				date === self.whiteClickData.lastClickDate &&
				time - self.whiteClickData.lastClick < 500
			) {
				self.whiteClickData.selected.add(date);

				self.reverseDispo = {
					resource: self.whiteClickData.resource,
					dates: [...self.whiteClickData.selected],
				};
				self.whiteClickData = {
					resource: "",
					selected: new Set(),
					lastClick: 0,
					lastClickDate: "",
				};
			} else {
				if (self.whiteClickData.selected.has(date))
					self.whiteClickData.selected.delete(date);
				else self.whiteClickData.selected.add(date);
				self.whiteClickData = {
					lastClick: time,
					lastClickDate: date,
					selected: self.whiteClickData.selected,
					resource: res,
				};
			}
		},
		setResourceMultiselect() {
			self.resourceMultiselect = !self.resourceMultiselect;
		},
		setResourceSeleceted(jobs, resource) {
			let tempResData = [];
			if(self.resourceMultiselect){
				tempResData = self.resourceDeploymentSelected; 
			} 
			tempResData.push({jobs: jobs, resource: resource});
			self.resourceDeploymentSelected = tempResData;
		},
		deleteResources() {
			let resourceDeployment = self.resourceDeploymentSelected;
			if(!resourceDeployment.length) return;
			resourceDeployment.forEach((e) => {
				e.jobs.forEach(job => job.dispose(e.resource, "REMOVE")); 
			});
			let history = self.resourceDeploymentHistory.history.slice(-4);			
			history.push(resourceDeployment);
			self.resourceDeploymentHistory.history = history;
			self.resourceDeploymentSelected = [];
			self.resourceMultiselect = false;
		},
		undoResourceDelete() {
			const updateJobs = self.resourceDeploymentHistory.history[self.resourceDeploymentHistory.history.length - 1];
			self.resourceDeploymentHistory.forwardHistory.unshift(self.resourceDeploymentHistory.history.pop()); 
			updateJobs.forEach((e) => { 
				e.jobs.forEach(job => job.dispose(e.resource, "ADD")); 
			});
		},
		redoResourceDelete() {
			const updateJobs = self.resourceDeploymentHistory.forwardHistory.shift();
			self.resourceDeploymentHistory.history.push(updateJobs);
			updateJobs.forEach((e) => { 
				e.jobs.forEach(job => job.dispose(e.resource, "REMOVE")); 
			});
		},

	}));


export default UIStore;
