import React from "react";
import { withTranslation } from "react-i18next";
import { Tab, Segment, Header, Icon, Button } from "semantic-ui-react";
import ConfirmButton from "../controls/confirmButton";
import { Map, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";
// eslint-disable-next-line 
import LCG from "leaflet-control-geocoder";

class EditPosition extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (!this.props.edit.state.data.editable) return;
		setTimeout(() => {
			try {
			const map = this.leafletMap.leafletElement;
			const geocoder = L.Control.geocoder({
				defaultMarkGeocode: false,
				collapsed: false,
				placeholder: this.props.t("geocoder.placeholder"),
				errorMessage: this.props.t("geocoder.error"),
				iconLabel: this.props.t("geocoder.iconLabel"),
				showResultIcon: true,
			})
				.on("markgeocode", (e) => {
					geocoder.setQuery("");
					this.props.data.changePosition(
						{ lat: e.geocode.center.lat, lng: e.geocode.center.lng },
						Math.min(19, map.getBoundsZoom(e.geocode.bbox))
					);
				})
				.addTo(map);
			} catch(e){}
		}, 100);
	}



	render() {
		const statePos = this.props.global
			? this.props.edit.state.data.position
			: this.props.data.state.formData.position;

		if (!this.props.global && !statePos) {
			return (
				<Tab.Pane>
					<Segment placeholder>
						<Header icon>
							<Icon name="calendar times outline" />
							{this.props.t("edit.difference.header", {
								type: this.props.t("edit.difference.types.positions"),
							})}
							<Header.Subheader>
								{this.props.t("edit.difference.message", {
									type: this.props.t("edit.difference.types.positions"),
								})}
							</Header.Subheader>
						</Header>
						<ConfirmButton
							trigger={
								<Button negative>
									{this.props.t("edit.difference.action", {
										type: this.props.t("edit.difference.types.positions"),
									})}
								</Button>
							}
							onClick={() =>
								this.props.data.changePosition({ lat: 0, lng: 0 }, 0)
							}
						/>
					</Segment>
				</Tab.Pane>
			);
		}

		let position;
		let zoom;

		if (
			statePos &&
			statePos.lat !== 0 &&
			statePos.lng !== 0 &&
			statePos.zoom !== 0
		) {
			position = [statePos.lat, statePos.lng];
			zoom = statePos.zoom;
		} else if (
			!this.props.global &&
			this.props.edit.state.data.position &&
			this.props.edit.state.data.position.lat !== 0 &&
			this.props.edit.state.data.position.lng !== 0 &&
			this.props.edit.state.data.position.zoom !== 0
		) {
			position = [
				this.props.edit.state.data.position.lat,
				this.props.edit.state.data.position.lng,
			];
			zoom = this.props.edit.state.data.position.zoom;
		} else {
			position = [
				this.props.t("stdPos.lat", 50.8),
				this.props.t("stdPos.lng", 10.5),
			];
			zoom = this.props.t("stdPos.zoom", 6);
		}

		return (
			<Tab.Pane className="mapTab">
				<Map
					center={position}
					zoom={zoom}
					ref={(m) => {
						this.leafletMap = m;
					}}
					onClick={
						(!this.props.global &&
							!!this.props.data.state.formData.bpo.length) ||
						!this.props.edit.state.data.editable
							? null
							: (e) =>
									this.props.data.changePosition(e.latlng, e.target.getZoom())
					}
				>
					<TileLayer url="https://maps.bpo-asphalt.de/tile.php?z={z}&x={x}&y={y}&key=dboth&type=standard" />

					<Marker
						position={position}
						draggable={
							this.props.edit.state.data.editable &&
							(this.props.global || !this.props.data.state.formData.bpo.length)
						}
						onDragEnd={(e) =>
							this.props.data.changePosition(
								e.target.getLatLng(),
								e.target._map.getZoom()
							)
						}
					/>
				</Map>
			</Tab.Pane>
		);
	}
}

export default withTranslation()(EditPosition);
