import React from "react";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";
import { Table, Icon, Ref } from "semantic-ui-react";

export default function DayViewHeader(props) {
	const { t } = useTranslation();

	const draggerSettings = {
		axis: "x",
		position: { x: 0, y: 0 },
	};

	function dragger(e, element, key) {
		const dragRight = e.target.getBoundingClientRect().right;
		const th = element.current.getBoundingClientRect();
		const newWidth = th.width + (dragRight - th.right);
		e.target.style.transform = "";
		props.setDrag(key, Math.max(50, newWidth));
	}

	const processEl = React.useRef(null);
	const projectEl = React.useRef(null);
	const contactEl = React.useRef(null);
	const lockEl = React.useRef(null);
	const timeEl = React.useRef(null);
	const materialEl = React.useRef(null);
	const humansEl = React.useRef(null);
	const trucksEl = React.useRef(null);
	const machinesEl = React.useRef(null);

	return (
		<React.Fragment>
			<Table.Header className="relativeTh">
				<Table.Row>
					<Ref innerRef={processEl}>
						<Table.HeaderCell
							sorted={
								props.sortColumn === "process" ? props.sortDirection : null
							}
							onClick={(e) => props.columnClick("process", e)}
						>
							<Draggable
								{...draggerSettings}
								onStop={(e) => dragger(e, processEl, "process")}
							>
								<div className="dragger" />
							</Draggable>
						</Table.HeaderCell>
					</Ref>
					<Ref innerRef={lockEl}>
						<Table.HeaderCell
							textAlign="center"
							sorted={
								props.sortColumn === "lock" ? props.sortDirection : null
							}
							onClick={(e) => props.columnClick("lock", e)}
						>
							<Icon size="large" name="lock" />

							<Draggable
								{...draggerSettings}
								onStop={(e) => dragger(e, lockEl, "lock")}
							>
								<div className="dragger" />
							</Draggable>
						</Table.HeaderCell>
					</Ref>
					<Ref innerRef={projectEl}>
						<Table.HeaderCell
							textAlign="center"
							sorted={
								props.sortColumn === "project" ? props.sortDirection : null
							}
							onClick={(e) => props.columnClick("project", e)}
						>
							<Icon size="large" name="tag" />
							{props.sortColumn === "project" ? (
								<div className="sortName">
									{props.sortDirection.includes("green")
										? t("sorting.name")
										: t("sorting.number")}
								</div>
							) : null}

							<Draggable
								{...draggerSettings}
								onStop={(e) => dragger(e, projectEl, "project")}
							>
								<div className="dragger" />
							</Draggable>
						</Table.HeaderCell>
					</Ref>
					<Ref innerRef={contactEl}>
						<Table.HeaderCell
							textAlign="center"
							sorted={
								props.sortColumn === "contact" ? props.sortDirection : null
							}
							onClick={(e) => props.columnClick("contact", e)}
						>
							<Icon size="large" name="user" />

							<Draggable
								{...draggerSettings}
								onStop={(e) => dragger(e, contactEl, "contact")}
							>
								<div className="dragger" />
							</Draggable>
						</Table.HeaderCell>
					</Ref>
					<Ref innerRef={timeEl}>
						<Table.HeaderCell
							textAlign="center"
							sorted={props.sortColumn === "time" ? props.sortDirection : null}
							onClick={(e) => props.columnClick("time", e)}
						>
							<Icon size="large" name="time" />
							{props.sortColumn === "time" ? (
								<div className="sortName">
									{props.sortDirection.includes("green")
										? t("sorting.start")
										: t("sorting.duration")}
								</div>
							) : null}

							<Draggable
								{...draggerSettings}
								onStop={(e) => dragger(e, timeEl, "time")}
							>
								<div className="dragger" />
							</Draggable>
						</Table.HeaderCell>
					</Ref>
					<Ref innerRef={materialEl}>
						<Table.HeaderCell
							textAlign="center"
							sorted={
								props.sortColumn === "material" ? props.sortDirection : null
							}
							onClick={(e) => props.columnClick("material", e)}
						>
							<Icon size="large" name="cubes" />
							{props.sortColumn === "material" ? (
								<div className="sortName">
									{props.sortDirection.includes("green")
										? t("sorting.name")
										: t("sorting.number")}
								</div>
							) : null}

							<Draggable
								{...draggerSettings}
								onStop={(e) => dragger(e, materialEl, "material")}
							>
								<div className="dragger" />
							</Draggable>
						</Table.HeaderCell>
					</Ref>
					{!props.hasTrucks ? (
						<Ref innerRef={trucksEl}>
							<Table.HeaderCell
								textAlign="center"
								sorted={
									props.sortColumn === "trucks" ? props.sortDirection : null
								}
								onClick={(e) => props.columnClick("trucks", e)}
							>
								<Icon size="large" name="truck" />
								{props.sortColumn === "trucks" ? (
									<div className="sortName">
										{props.sortDirection.includes("green")
											? t("sorting.name")
											: t("sorting.number")}
									</div>
								) : null}

								<Draggable
									{...draggerSettings}
									onStop={(e) => dragger(e, trucksEl, "trucks")}
								>
									<div className="dragger" />
								</Draggable>
							</Table.HeaderCell>
						</Ref>
					) : null}
					<Ref innerRef={humansEl}>
						<Table.HeaderCell
							textAlign="center"
							sorted={
								props.sortColumn === "humans" ? props.sortDirection : null
							}
							onClick={(e) => props.columnClick("humans", e)}
						>
							<Icon size="large" name="users" />

							<Draggable
								{...draggerSettings}
								onStop={(e) => dragger(e, humansEl, "humans")}
							>
								<div className="dragger" />
							</Draggable>
						</Table.HeaderCell>
					</Ref>
					{props.hasTrucks ? (
						<Ref innerRef={trucksEl}>
							<Table.HeaderCell
								textAlign="center"
								sorted={
									props.sortColumn === "trucks" ? props.sortDirection : null
								}
								onClick={(e) => props.columnClick("trucks", e)}
							>
								<Icon size="large" name="truck" />

								<Draggable
									{...draggerSettings}
									onStop={(e) => dragger(e, trucksEl, "trucks")}
								>
									<div className="dragger" />
								</Draggable>
							</Table.HeaderCell>
						</Ref>
					) : null}
					<Ref innerRef={machinesEl}>
						<Table.HeaderCell
							textAlign="center"
							sorted={
								props.sortColumn === "machines" ? props.sortDirection : null
							}
							onClick={(e) => props.columnClick("machines", e)}
						>
							<Icon size="large" name="picon-excavator" />

							<Draggable
								{...draggerSettings}
								onStop={(e) => dragger(e, machinesEl, "machines")}
							>
								<div className="dragger" />
							</Draggable>
						</Table.HeaderCell>
					</Ref>
					<Table.HeaderCell />
				</Table.Row>
			</Table.Header>
		</React.Fragment>
	);
}
