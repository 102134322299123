import React from "react";
import { useTranslation } from "react-i18next";

const LineHeader = ({ width, onToggle, module, active }) => 
{const { t } = useTranslation();
return (<tr
					className="lineHeader"
				>
					<td
						className="lineHeader_head sticky"
						style={{
							cursor: "pointer",
							left: 0,
							zIndex: 3,
							verticalAlign: "top"
						}}
					>
						<div
							className="lineHeader_name"
							style={{
								whiteSpace: "nowrap",
								height: 25,
								lineHeight: 25+"px"
							}}
							data-module={module}
							onClick={onToggle}
						>
							{(active ? "▼" : "▶")+" "+t("lineheaders."+module)}
						</div>
					</td>
					<td>
						<div
							className="lineHeader_body"
							style={{
								zIndex: 2,
								width: width,
								height: 25,
								position: "relative"
							}}
						>
							
						</div>
					</td>
				</tr>
	)};

export default LineHeader;
