import React from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import { Dropdown } from "semantic-ui-react";
import isequal from "lodash.isequal";
// Import Common Stylesheets

class BaseDataSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			options: [],
			type: "",
			domain: "",
			constraints: [],
		};
	}

	static getDerivedStateFromProps(props, state) {
		const domain = "domain" in props ? props.domain : "";
		const sortUp = "sortUp" in props ? props.sortUp : new Set();
		const constraints =
			"constraints" in props && props.constraints ? props.constraints : [];
		if (
			props.type === state.type &&
			domain === state.domain &&
			isequal(constraints, state.constraints)
		)
			return null;

		let stateOptions;
		if (props.type === "requirements") {
			stateOptions = props.app.resources.getAllRequirements();
		} else if ("domain" in props && props.domain === "resources") {
			if (!(props.type in props.app.resources)) {
				return <div>Error</div>;
			}

			stateOptions = Array.from(props.app.resources[props.type]);
		} else {
			if (!(props.type in props.app.basedata)) {
				return <div>Error</div>;
			}

			stateOptions = Array.from(props.app.basedata[props.type]);
		}

		stateOptions = stateOptions.filter(([key, x]) => {
			if (x.deleted) return false;
			if (Array.isArray(props.omit) && props.omit.includes(x.name))
				return false;
			if (!constraints.length) return true;

			return constraints.every((y) => {
				return y.key in x && (!x[y.key] || x[y.key].id === y.value);
			});
		});
		//WIP
		stateOptions.sort((a, b) => {
			const upA = sortUp.has(a[1].id);
			const upB = sortUp.has(b[1].id);
			if (upA === upB) return a[1].name.localeCompare(b[1].name);
			return upA && !upB ? -1 : 1;
		});

		stateOptions = stateOptions.map(([key, x]) => ({
			key: x.id,
			className: sortUp.has(x.id) ? "importantSupplier" : "",
			text:
				x.name +
				(props.type === "materials" && x.number.length
					? " (" + x.number + ")"
					: ""),
			value: x.id,
		}));

		if (props.allowNull) {
			stateOptions.unshift({
				key: "null",
				className: "",
				text: props.placeholder,
				value: null,
			});
		}

		if(props.type === "suppliers" && props.allowedSuppliers !== null){
			stateOptions = stateOptions.filter((value) => 
				props.allowedSuppliers.includes(value.text)
			);
		}

		return {
			options: stateOptions,
			type: props.type,
			domain,
			constraints,
		};
	}

	render() {
		return (
			<Dropdown
				fluid
				lazyLoad={true}
				placeholder={
					"placeholder" in this.props
						? this.props.placeholder
						: this.props.t("choose.initial")
				}
				search
				value={this.props.value}
				onChange={this.props.onChange}
				selection
				options={this.state.options}
				disabled={"disabled" in this.props && this.props.disabled}
			/>
		);
	}
}

export default inject("app")(withTranslation()(observer(BaseDataSelect)));
