import React from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import Header from "../structure/header";
import {
	Segment,
	Icon,
	Form,
	Button,
	Transition,
	Message,
} from "semantic-ui-react";
import axios from "axios";
import de from "../../images/de.png";
import en from "../../images/en.png";
import nl from "../../images/nl.png";
import fr from "../../images/fr.png";
import ro from "../../images/ro.png";
import dk from "../../images/dk.png";

class LoginWindow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			error: "error" in props ? props.error : "",
			isChecking: false,
		};
	}

	setLoginError(error) {
		this.setState({ password: "", error, isChecking: false });
	}

	componentDidMount() {
		this.setState({ error: this.props.error });
	}

	login() {
		//console.log("login");
		let self = this;
		this.setState({ isChecking: true, error: "" });
		axios
			.post(process.env.REACT_APP_SERVER_URL + "/login_check?v="+process.env.REACT_APP_SERVER_VERSION, {
				username: this.state.username.trim().toLowerCase(),
				password: this.state.password,
			})
			.then(function(response) {
				if ("token" in response.data)
					return self.props.app.ui.setLogin(
						self.state.username.trim().toLowerCase(),
						response.data.token
					);
				self.setLoginError("unknown");
			})
			.catch(function(error) {
				// handle error
				console.log(error);
				if (error.response && error.response.status === 401)
					return self.setLoginError("wrong");
				else if (error.response) return self.setLoginError("unknown");
				else return self.setLoginError("network");
			});
	}

	setValue(key, val) {
		this.setState({ [key]: val });
	}

	render() {
		//console.log(this.props.app.ui.wasLoggedIn);
		return (
			<React.Fragment>
				<Header
					fullscreen={true}
					buttons={[
						<a
							href="https://volzconsulting.de"
							className="topbutton"
							key="nothing"
						>
							?
						</a>,
					]}
				/>
				<main className="fullscreen loginPa noFooter">
					<Segment padded className="loginview">
						<Transition.Group>
							{this.state.error ? (
								<Message
									negative
									icon={
										{
											network: "wifi",
											wrong: "times",
											unknown: "question circle",
											lost: "unlink",
										}[this.state.error]
									}
									header={this.props.t(
										"login.errors." + this.state.error + ".header"
									)}
									content={this.props.t(
										"login.errors." + this.state.error + ".content"
									)}
								/>
							) : null}
						</Transition.Group>

						<Form>
							<Form.Input
								icon="user circle"
								value={this.state.username}
								onChange={(e, { value }) => this.setValue("username", value)}
								placeholder={this.props.t("login.username")}
								type="text"
							/>
							<Form.Input
								icon="key"
								value={this.state.password}
								onChange={(e, { value }) => this.setValue("password", value)}
								placeholder={this.props.t("login.password")}
								type="password"
							/>
							<Button
								fluid
								color="green"
								loading={this.state.isChecking}
								onClick={() => (this.state.isChecking ? "" : this.login())}
							>
								<Icon name="check" />
								{this.props.t("login.login")}
							</Button>
						</Form>
					</Segment>
					<Segment padded className="flagcollection">
						<div className="flagWrapper" onClick={() => this.props.i18n.changeLanguage("de")}>
							<img
								src={de}
								className="flag"
								alt="DE"
							/><div className="flagname">DE</div>
							
						</div>
						<div className="flagWrapper" onClick={() => this.props.i18n.changeLanguage("en")}>
							<img
								src={en}
								className="flag"
								alt="EN"
							/><div className="flagname">EN</div>
						</div>
						<div className="flagWrapper" onClick={() => this.props.i18n.changeLanguage("fr")}>
							<img
								src={fr}
								className="flag"
								alt="FR"
							/><div className="flagname">FR</div>
						</div>
						<div className="flagWrapper" onClick={() => this.props.i18n.changeLanguage("nl")} >
							<img
								src={nl}
								className="flag"
								alt="NL"
							/><div className="flagname">NL</div>
						</div>
						<div className="flagWrapper" onClick={() => this.props.i18n.changeLanguage("da")} >
							<img
								src={dk}
								className="flag"
								alt="DK"
							/><div className="flagname">DA</div>
						</div>
						<div className="flagWrapper" onClick={() => this.props.i18n.changeLanguage("ro")} >
							<img
								src={ro}
								className="flag"
								alt="RO"
							/><div className="flagname">RO</div>
						</div>
					</Segment>
				</main>
			</React.Fragment>
		);
	}
}

export default inject("app")(withTranslation()(observer(LoginWindow)));
