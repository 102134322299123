import React from "react";
import { withTranslation } from "react-i18next";
import {
	Popup,
	Button,
	Input
} from "semantic-ui-react";
import BaseDataSelect from "../../basedata/selects";

class AddRentalResource extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			supplier: null,
			name: "",
			isOpen: false,
			id: false
		};
		this.handleClose = this.handleClose.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
	}

	save() {
		try {
			this.props.onSave(
				this.state.id,
				this.state.name,
				this.state.supplier,
				"resClass" in this.props ? this.props.resClass : null
			);
			this.setState({
				isOpen: false,
				supplier: null,
				name: ""
			});
			if ("scrollRef" in this.props) {
				//console.log(this.props.scrollRef);
				const container = this.props.scrollRef.current;
				setTimeout(function() {
					container.scrollTo({
						top: 0,
						left: container.scrollWidth,
						behavior: "smooth"
					});
				}, 500);
			}
		} catch (e) {
			console.log(e);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.isOpen && this.state.isOpen) {
			this.setState({
				supplier: "supplier" in this.props ? this.props.supplier : null,
				name: "name" in this.props ? this.props.name : "",
				id: "rentId" in this.props ? this.props.rentId : false
			});
		}
	}

	updateValue(key, value) {
		this.setState({ [key]: value });
	}

	handleOpen() {
		this.setState({ isOpen: true });
	}

	handleClose() {
		this.setState({ isOpen: false });
	}

	render() {
		console.log(this.state);
		return (
			<Popup
				className="rentalresource"
				wide
				trigger={this.props.trigger}
				
				on="click"
				context={"ctxref" in this.props ? this.props.ctxref : null}
				open={this.state.isOpen}
				onClose={this.handleClose}
				onOpen={this.handleOpen}
				position={
					"ctxref" in this.props ? "right center" : "bottom right"
				}
			>
				<Popup.Content>
					<Input
						fluid
						value={this.state.name}
						placeholder={this.props.t(
							"resourceChooser.rentModal.name"
						)}
						onChange={(e, { value }) =>
							this.updateValue("name", value)
						}
					/>
					<BaseDataSelect
						type="suppliers"
						placeholder={this.props.t(
							"resourceChooser.rentModal.supplier"
						)}
						value={this.state.supplier}
						onChange={(e, { value }) =>
							this.updateValue("supplier", value)
						}
					/>
					<Button
						fluid
						positive
						icon="check"
						onClick={() => this.save()}
						content={this.props.t("ok")}
					/>
				</Popup.Content>
			</Popup>
		);
	}
}

export default withTranslation()(AddRentalResource);
